import React from 'react'
import {
  DashboardOutlined,
  LogoutOutlined,
  UserOutlined,
  DatabaseOutlined,
  FileOutlined,
  BookOutlined,
  BarChartOutlined,
} from '@ant-design/icons'

import { routes } from '../../routes'
import { AccountBean, SavedQueryBean } from '../../api/base/api'

export interface NavItemDef {
  isTitle?: boolean
  name: string
  url?: string
  icon?: React.ForwardRefExoticComponent<any>
  excludeForRoles?: (AccountBean.RoleEnum | undefined)[]
}

const navsDefault: NavItemDef[] = [
  {
    isTitle: true,
    name: 'Basic',
  },
  {
    name: 'Dashboard',
    icon: DashboardOutlined,
    url: routes.dashboard,
  },
  {
    name: 'SSP list',
    icon: DatabaseOutlined,
    url: routes.ssp,
  },
  {
    name: 'DSP list',
    icon: DatabaseOutlined,
    url: routes.dsp,
  },
  {
    name: 'Account list',
    icon: UserOutlined,
    url: routes.account,
    excludeForRoles: [AccountBean.RoleEnum.AccountManager],
  },
  {
    isTitle: true,
    name: 'Reports',
  },
  {
    name: 'Statistics',
    icon: BarChartOutlined,
    url: routes.statistics,
  },
  {
    name: 'Saved query list',
    icon: BookOutlined,
    url: routes.savedQuery,
  },
  {
    isTitle: true,
    name: 'Actions',
  },
  {
    name: 'Test Request',
    icon: DatabaseOutlined,
    url: routes.testRequest,
  },
  {
    name: 'Sign Out',
    icon: LogoutOutlined,
    url: routes.signOut,
  },
]

export const navs = (savedQueries: SavedQueryBean[]) => {
  if (savedQueries) {
    const index = navsDefault.findIndex((item) => item.name === 'Saved query list')
    if (index) {
      const navs_return = Object.assign([], navsDefault)
      navs_return.splice(
        index + 1,
        0,
        ...savedQueries.map((saved) => ({
          name: saved.name,
          icon: FileOutlined,
          url: routes.favorite.concat(`/${saved.id}`),
        }))
      )
      return navs_return
    }
  }
  return navsDefault
}
