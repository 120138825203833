import React, { useState } from 'react'
import { Button, Col, Input, InputNumber, Row, Select, Space, Switch, Typography } from 'antd'
import useForceUpdate from 'antd/es/_util/hooks/useForceUpdate'
import { FormFilterSelect } from '../../../components/form/inputs/FormFilterSelect'
import { StatsFilter } from '../../../api/base/api'
import { dspApi } from '../../../api/dsp'
import { useGetList, useListFilters } from '../../../components/list'
import TypeEnum = StatsFilter.TypeEnum
import { FilterElem, DictionaryElem } from './utils/utils'
import { FilterType } from './utils/FilterType'

const { Option } = Select
const { Text } = Typography

interface Props {
  reportFilters: FilterElem[]
  setFilters: (filters) => void
  keysAvailable: DictionaryElem[]
}

export const FiltersPanelComponent: React.FC<Props> = ({ reportFilters, setFilters, keysAvailable }) => {
  const { filters } = useListFilters('filtersPanel_dsp')
  const { list } = useGetList(() => dspApi.listAll(filters), filters)
  const [filterIndex, setFilterIndex] = useState(1)
  const forceUpdate = useForceUpdate()

  const deleteButton = (filter: FilterElem) => {
    reportFilters.splice(reportFilters.indexOf(filter), 1)

    if (reportFilters.length === 0) {
      reportFilters.push({
        index: filterIndex,
        key: 'Not selected',
        type: TypeEnum.Equal,
        params: [],
      })
    }
    setFilterIndex(filterIndex + 1)
    forceUpdate()
  }

  const addButton = () => {
    reportFilters.push({
      index: filterIndex,
      key: 'Not selected',
      type: TypeEnum.Equal,
      params: [],
    })
    setFilterIndex(filterIndex + 1)
    setFilters(reportFilters)
    forceUpdate()
  }

  const renderFilterValues = (filter: FilterElem, keyElem: DictionaryElem | undefined) => {
    if (filter.key && keyElem) {
      switch (keyElem.filterType) {
        case FilterType.CUSTOM_VALUES:
          return (
            <Input
              type="text"
              size="small"
              placeholder="Input comma separated list of the values"
              value={filter.params ? filter.params.join(',') : ''}
              onChange={(e) => {
                filter.params = e.target.value.split(',')
                filter.type = StatsFilter.TypeEnum.InList
                setFilters(reportFilters)
                forceUpdate()
              }}
            />
          )
        case FilterType.CUSTOM_BOOL:
          return (
            <Switch
              checkedChildren="Only"
              unCheckedChildren="Exclude"
              defaultChecked
              onChange={(item) => {
                filter.params = [String(item)]
                filter.type = StatsFilter.TypeEnum.Equal
                setFilters(reportFilters)
                forceUpdate()
              }}
            />
          )
        case FilterType.CUSTOM_RANGE:
        case FilterType.CUSTOM_RANGE_INT:
        case FilterType.CUSTOM_HOURS:
          return (
            <Space size="large">
              <InputNumber
                max={24}
                min={0}
                prefix="min:"
                style={{ width: 110 }}
                type="number"
                value={filter.params[0] ? filter.params[0] : ''}
                onChange={(hour) => {
                  filter.params[0] = hour
                  setFilters(reportFilters)
                  forceUpdate()
                }}
              />
              <InputNumber
                max={24}
                min={0}
                prefix="max:"
                style={{ width: 110 }}
                type="number"
                value={filter.params[1] ? filter.params[1] : ''}
                onChange={(hour) => {
                  filter.params[1] = hour
                  setFilters(reportFilters)
                  forceUpdate()
                }}
              />
            </Space>
          )
        case FilterType.DSP:
          return (
            <Select
              mode="multiple"
              allowClear
              size="middle"
              style={{ width: '100%' }}
              value={filter.params}
              onChange={(item) => {
                filter.params = item
                setFilters(reportFilters)
                forceUpdate()
              }}
            >
              {list.map((val) => (
                <Option key={val.id} value={val.id}>
                  {val.name}
                </Option>
              ))}
            </Select>
          )
      }
    }
    return null
  }

  return (
    <>
      <Text>Filters</Text>
      {reportFilters !== undefined &&
        reportFilters.map((filter) => {
          const keyElem = filter.key ? keysAvailable.filter((key) => filter.key === key.value)[0] : undefined

          return (
            <Row justify="space-between" align="middle" gutter={20}>
              <FormFilterSelect
                xs={5}
                placeholder="Please select"
                value={[keyElem?.label ? keyElem?.label : filter.key]}
                options={keysAvailable}
                onChange={(value, data) => {
                  filter.key = data.key
                  filter.params = []
                  setFilters(reportFilters)
                  forceUpdate()
                }}
              />

              <Col xs={7} offset={1}>
                {renderFilterValues(filter, keyElem)}
              </Col>

              <Col xs={5} offset={4}>
                <Row justify="end">
                  <Button danger type="default" htmlType="submit" onClick={() => deleteButton(filter)}>
                    Delete
                  </Button>
                  {filter === reportFilters[reportFilters.length - 1] && (
                    <Button
                      style={{ margin: '0 0 0 3%', width: '70px' }}
                      type="primary"
                      htmlType="submit"
                      onClick={addButton}
                    >
                      Add
                    </Button>
                  )}
                </Row>
              </Col>
            </Row>
          )
        })}
    </>
  )
}
