import { DspBean, DspControllerApi } from './base/api'
import { configuration } from './utils/apiConfiguration'
import { customFetch } from './utils/customFetch'
import { ListFilters, LIST_PAGE_SIZE } from '../components/list'

class DspApi {
  private api = new DspControllerApi(configuration, configuration.basePath, customFetch)

  view(id: number) {
    return this.api.dsp(id)
  }

  list(filters: ListFilters) {
    return this.api.dspList(filters.id, filters.name, filters.endpoint, LIST_PAGE_SIZE, filters.page - 1)
  }

  listAll(filters: ListFilters) {
    return this.api.dspList(filters.id, filters.name, filters.endpoint, undefined, 0)
  }

  create(bean: DspBean) {
    return this.api.create2(bean)
  }

  edit(bean: DspBean) {
    return this.api.update2(bean, bean.id as number)
  }

  delete(id: number) {
    return this.api.delete2(id)
  }
}

export const dspApi = new DspApi()
