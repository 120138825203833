import { useEffect, useState } from 'react'

export function useGetListItem<Item>(fetchItem: (id: number) => Promise<{ data?: Item }>, id?: number) {
  const [item, setItem] = useState<Item | undefined>()

  const refreshItem = () => {
    fetchItem(id as number).then((response) => setItem(response.data))
  }

  useEffect(() => {
    if (id) {
      refreshItem()
    } else {
      setItem(undefined)
    }
  }, [id])

  return item
}
