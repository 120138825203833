import { SavedQueryBean } from '../api/base/api'
import { savedQueryApi } from '../api/savedQuery'

class SavedQueriesStore {
  private storageKey = 'saved-queries'

  public list: SavedQueryBean[] = []

  private subscribers: (() => void)[] = []

  constructor() {
    this.list = JSON.parse(localStorage.getItem(this.storageKey) ?? 'null')
  }

  saveSavedQueries = (savedQueries) => {
    this.list = savedQueries
    this.storeInfo()
    this.notifySubscribers()
  }

  refresh = () => {
    savedQueryApi.favoriteList(10).then((response) => {
      if (response.data) {
        this.list = response.data.map((item) => item.data as SavedQueryBean)
        this.storeInfo()
        this.notifySubscribers()
      }
    })
  }

  private storeInfo() {
    localStorage.setItem(this.storageKey, JSON.stringify(this.list))
  }

  subscribe = (cb: () => void) => {
    this.subscribers.push(cb)
  }

  unsubscribe = (cb: () => void) => {
    this.subscribers = this.subscribers.filter((subscriber) => subscriber !== cb)
  }

  private notifySubscribers = () => {
    this.subscribers.forEach((cb) => cb())
  }
}

export const savedQueriesStore = new SavedQueriesStore()
