import { useState } from 'react'

export interface ListFilters {
  page: number

  id: string
  name: string
  email: string
  endpoint: string
}

export function useListFilters(entity: string) {
  const storageKey = `${entity}-list-filters`

  const [filters, setFilters] = useState<ListFilters>(() => {
    const stored = localStorage.getItem(storageKey)
    return stored ? JSON.parse(stored) : { page: 1 }
  })

  const setFilter = (filter: keyof ListFilters, value: any) => {
    setFilters((filters) => {
      const newFilters = { ...filters, [filter]: value }
      localStorage.setItem(storageKey, JSON.stringify(newFilters))
      return newFilters
    })
  }

  return { filters, setFilter }
}
