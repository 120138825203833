import React, { useEffect, useState } from 'react'
import { Column } from '@ant-design/charts'
import { Card, Select } from 'antd'
import { ChartData, SizeChartBean } from '../../../api/base/api'

interface Props {
  title: string
  data: SizeChartBean | undefined
}

export const ChartWithSelect: React.FC<Props> = ({ title, data }) => {
  const [config, setConfig] = useState({
    data: data ? (data.bidRequests as ChartData[]) : [],
    isGroup: true,
    xField: 'xvalue',
    yField: 'yvalue',
    seriesField: 'name',
  })
  const [currentValue, setCurrentValue] = useState('bidRequests')

  useEffect(() => {
    setConfig({ ...config, data: data && data[currentValue] ? data[currentValue] : [] })
  }, [data])

  const handleChange = (value: string) => {
    setCurrentValue(value)
    setConfig({ ...config, data: data && data[value] ? data[value] : [] })
  }

  return (
    <Card
      headStyle={{ fontWeight: '500', height: '2em', verticalAlign: 'top', border: 'none' }}
      title={title}
      extra={
        <Select
          style={{ width: 130 }}
          defaultValue="bidRequests"
          onChange={handleChange}
          options={[
            {
              value: 'bidRequests',
              label: 'Bid Requests',
            },
            {
              value: 'impressions',
              label: 'Impressions',
            },
            {
              value: 'revenue',
              label: 'Revenue',
            },
          ]}
        />
      }
    >
      <Column {...config} />
    </Card>
  )
}
