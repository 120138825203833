import React, { useState } from 'react'
import { Drawer, Layout } from 'antd'

import { Sidenav } from './Sidenav'
import { Header } from './Header'
import { Footer } from './Footer'

interface Props {
  children: React.ReactNode
}

export const Main: React.FC<Props> = ({ children }) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  return (
    <Layout className="layout-dashboard">
      <Layout.Sider
        width={250}
        theme="light"
        className="sider-primary ant-layout-sider-primary sider-main-sidenav"
        style={{ background: 'transparent' }}
      >
        <Sidenav />
      </Layout.Sider>

      <Drawer
        title={false}
        placement="left"
        closable={false}
        onClose={() => setIsDrawerVisible(false)}
        visible={isDrawerVisible}
        width={250}
        className="drawer-sidebar"
      >
        <Layout className="layout-dashboard">
          <Layout.Sider
            width={250}
            theme="light"
            className="sider-primary ant-layout-sider-primary"
            style={{ background: 'transparent' }}
          >
            <Sidenav />
          </Layout.Sider>
        </Layout>
      </Drawer>

      <Layout>
        <Layout.Header>
          <Header toggleSidebar={() => setIsDrawerVisible(!isDrawerVisible)} />
        </Layout.Header>
        <Layout.Content className="content-ant">{children}</Layout.Content>
        <Footer />
      </Layout>
    </Layout>
  )
}
