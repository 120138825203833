import { AccountBean, StatRequestBean } from '../../../../api/base/api'
import { DictionaryElem } from './utils'

export const valuesAvailable: Array<DictionaryElem> = [
  {
    value: StatRequestBean.ValuesEnum.DsptimeCount.toString(),
    label: 'DSP Requests',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.DspUser],
  },
  {
    value: StatRequestBean.ValuesEnum.DspbidpriceCount.toString(),
    label: 'DSP Bids',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.DspUser],
  },
  {
    value: StatRequestBean.ValuesEnum.DspwinpriceCount.toString(),
    label: 'DSP Wins',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.DspimprpriceCount.toString(),
    label: 'DSP Imprs',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.DspUser],
  },
  {
    value: StatRequestBean.ValuesEnum.Dspbidflooravg.toString(),
    label: 'DSP Bid Floor Avg',
    format: 'money',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.Dspbidpriceavg.toString(),
    label: 'DSP Bid Price Avg',
    format: 'money',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.Dspimprpriceavg.toString(),
    label: 'DSP Impr Price Avg',
    format: 'money',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.DspimprpriceSum.toString(),
    label: 'DSP Impr Price',
    format: 'money',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.Dsptimeavg.toString(),
    label: 'DSP Time Avg (ms)',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.DspUser],
  },
  {
    value: StatRequestBean.ValuesEnum.Dspwinpriceavg.toString(),
    label: 'DSP Win Price Avg',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.DspwinpriceSum.toString(),
    label: 'DSP Win Price',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.DspWinError.toString(),
    label: 'DSP Win Errors',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.DspError.toString(),
    label: 'DSP Errors',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.DspUser],
  },
  {
    value: StatRequestBean.ValuesEnum.DspTimeout.toString(),
    label: 'DSP Timeouts',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.DspUser],
  },

  {
    value: StatRequestBean.ValuesEnum.SsptimeCount.toString(),
    label: 'SSP Requests',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.DspUser],
  },
  {
    value: StatRequestBean.ValuesEnum.SspbidpriceCount.toString(),
    label: 'SSP Bids',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.DspUser, AccountBean.RoleEnum.SspUser],
  },
  {
    value: StatRequestBean.ValuesEnum.SspwinpriceCount.toString(),
    label: 'SSP Wins',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.SspIgnoredWin.toString(),
    label: 'SSP Expired Wins',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.SspimprpriceCount.toString(),
    label: 'SSP Imprs',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.SspUser],
  },
  {
    value: StatRequestBean.ValuesEnum.SspIgnoredImpr.toString(),
    label: 'SSP Expired Imprs',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.Sspbidflooravg.toString(),
    label: 'SSP Bid Floor Avg',
    format: 'money',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.Sspbidpriceavg.toString(),
    label: 'SSP Bid Price Avg',
    format: 'money',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.Sspimprpriceavg.toString(),
    label: 'SSP Impr Price Avg',
    format: 'money',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.SspimprpriceSum.toString(),
    label: 'SSP Impr Price',
    format: 'money',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.Ssptimeavg.toString(),
    label: 'SSP Time Avg (ms)',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.Sspwinpriceavg.toString(),
    label: 'SSP Win Price Avg',
    format: 'money',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.SspwinpriceSum.toString(),
    label: 'SSP Win Price',
    format: 'money',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.SspWinRate.toString(),
    label: 'SSP Win Rate',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin],
  },

  {
    value: StatRequestBean.ValuesEnum.Revenue.toString(),
    label: 'DSP Spent',
    format: 'money6',
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.DspUser],
  },
  {
    value: StatRequestBean.ValuesEnum.Cost.toString(),
    label: 'SSP Revenue',
    format: 'money6',
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.SspUser],
  },
  {
    value: StatRequestBean.ValuesEnum.Profit.toString(),
    label: 'Profit',
    format: 'money6',
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: StatRequestBean.ValuesEnum.DspWinRate.toString(),
    label: 'DSP Win Rate',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.DspUser],
  },
  {
    value: StatRequestBean.ValuesEnum.ExternalImpressions.toString(),
    label: 'Confirmed Impressions',
    format: 'number',
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.DspUser],
  },
  {
    value: StatRequestBean.ValuesEnum.ExternalRevenue.toString(),
    label: 'Confirmed Revenue',
    format: 'money6',
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.DspUser],
  },
]
