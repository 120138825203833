import { AccountBean, FilterEntry } from '../../../../api/base/api'
import { DictionaryElem } from './utils'
import { FilterType } from './FilterType'

export const keysAvailable: Array<DictionaryElem> = [
  {
    value: FilterEntry.KeysEnum.Hour.toString(),
    label: 'Hour',
    format: 'string',
    filterType: FilterType.CUSTOM_HOURS,
  },
  {
    value: FilterEntry.KeysEnum.Dsp.toString(),
    label: 'DSP ID',
    format: 'string',
    filterType: FilterType.CUSTOM_VALUES,
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: FilterEntry.KeysEnum.DspName.toString(),
    label: 'DSP Name',
    format: 'string',
    filterType: FilterType.DSP,
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.DspUser],
  },
  {
    value: FilterEntry.KeysEnum.Ssp.toString(),
    label: 'SSP ID',
    format: 'string',
    filterType: FilterType.CUSTOM_VALUES,
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: FilterEntry.KeysEnum.SspName.toString(),
    label: 'SSP Name',
    format: 'string',
    filterType: FilterType.SSP,
    roles: [AccountBean.RoleEnum.Admin, AccountBean.RoleEnum.SspUser, AccountBean.RoleEnum.DspUser],
  },
  {
    value: FilterEntry.KeysEnum.Audio.toString(),
    label: 'Audio Traffic',
    format: 'string',
    filterType: FilterType.CUSTOM_BOOL,
  },
  {
    value: FilterEntry.KeysEnum.Banner.toString(),
    label: 'Banner Traffic',
    format: 'string',
    filterType: FilterType.CUSTOM_BOOL,
  },
  {
    value: FilterEntry.KeysEnum.Video.toString(),
    label: 'Video Traffic',
    format: 'string',
    filterType: FilterType.CUSTOM_BOOL,
  },
  {
    value: FilterEntry.KeysEnum.Nnative.toString(),
    label: 'Native Traffic',
    format: 'string',
    filterType: FilterType.CUSTOM_BOOL,
  },
  {
    value: FilterEntry.KeysEnum.Country.toString(),
    label: 'Country',
    format: 'string',
    filterType: FilterType.CUSTOM_VALUES,
    roles: [AccountBean.RoleEnum.Admin],
  },
  {
    value: FilterEntry.KeysEnum.Domain.toString(),
    label: 'Domain',
    format: 'string',
    filterType: FilterType.CUSTOM_VALUES,
    roles: [AccountBean.RoleEnum.Admin],
  },
]
