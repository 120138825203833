import React, { useEffect, useState } from 'react'
import { Card, Table } from 'antd'

interface Props<Item> {
  title: string
  list?: Item[]
  headers: string[]
  renderRowCells: (row: Item) => (string | number | JSX.Element | Element | undefined)[]
}

export const TopList = <Item extends { name?: string; date?: string }>({
  title,
  list,
  headers,
  renderRowCells,
}: Props<Item>) => {
  const [columns, setColums] = useState([{}])

  useEffect(() => {
    setColums(headers.map((header) => ({ title: header, dataIndex: header })))
  })

  const dataSource = list?.map((item) => {
    const cells = renderRowCells(item)
    return cells.reduce((acc, cell, index) => ({ ...acc, [headers[index]]: cell }), {
      key: item?.name ? item?.name : item?.date,
    })
  })

  return (
    <Card headStyle={{ fontWeight: '500', height: '2em', verticalAlign: 'top', border: 'none' }} title={title}>
      <Table
        className="ant-border-space"
        style={{ overflow: 'auto' }}
        pagination={false}
        dataSource={dataSource}
        columns={columns}
      />
    </Card>
  )
}
