import { globalMessageStore } from '../../utils/globalMessageStore'

export function processGlobalError(err?: any) {
    const resp = err as Response
    let parsedError: string | undefined

    if (typeof resp.json === 'function') {
        return resp
            .json()
            .catch(() => {
                globalMessageStore.addError("ERROR: can't connect to the server")
                return Promise.reject(new Error())
            })
            .then((json) => {
                parsedError = (json.error || json) as string
                globalMessageStore.addError(parsedError)
                return Promise.reject(parsedError)
            })
    }
    if ((resp as any).response && (resp as any).response.data) {
        const { data } = (resp as any).response
        parsedError = (data.error || data) as string
        globalMessageStore.addError(parsedError)
    } else {
        globalMessageStore.addError("ERROR: can't connect to the server")
    }

    return Promise.reject(parsedError)
}
