import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Select, Space, Typography } from 'antd'
import moment from 'moment'
import { QueryState } from '../StatisticsFormContainer'
import { savedQueryApi } from '../../../api/savedQuery'
import { StatsFilter } from '../../../api/base/api'
import { useSavedQueries } from '../../../utils/useSavedQueries'
import { getStatisticsPath, routes } from '../../../routes'
import { initialKeys, initialValues, RequestPeriods } from './utils/utils'

const { Text } = Typography
const { Option } = Select

interface Props {
  queryState: Partial<QueryState>
  updateQueryState: (QueryState) => void
}

export const SelectFavoriteSavedQuery: React.FC<Props> = ({ queryState, updateQueryState }) => {
  const history = useHistory()

  const [selectedName, setSelectedName] = useState(queryState.favorite ? queryState.name : '')
  const { savedQueries } = useSavedQueries()

  useEffect(() => {
    const isSelectedFavorites = savedQueries.find((item) => item.name === queryState.name)
    setSelectedName(isSelectedFavorites ? (queryState.name as string) : '')
  }, [queryState])

  const select = (item: string, data: any) => {
    if (data.value === 'Not Selected' || data.key === -1) {
      setSelectedName('Not Selected')
      updateQueryState({
        query: {
          keys: initialKeys,
          values: initialValues,
          startDate: moment(),
          endDate: moment(),
          filters: [{ index: 0, type: StatsFilter.TypeEnum.Equal, key: 'Not selected', params: [] }],
          activeRequestPeriods: RequestPeriods.Today,
        },
        name: '',
        favorite: false,
      })
      history.push(getStatisticsPath(routes.statistics))
      return
    }
    if (data.key) {
      setSelectedName(data.value)
      savedQueryApi.view(data.key).then((response) => {
        const neww = response.data
          ? updateQueryState({
              id: response.data?.id,
              name: response.data?.name,
              favorite: response.data?.favorite,
              query: JSON.parse(response.data?.body as string),
            })
          : updateQueryState({
              query: {
                keys: initialKeys,
                values: initialValues,
                startDate: moment(),
                endDate: moment(),
                filters: [{ index: 0, type: StatsFilter.TypeEnum.Equal, key: 'Not selected', params: [] }],
                activeRequestPeriods: RequestPeriods.Today,
              },
              name: '',
              favorite: false,
            })

        history.push('/favorite/'.concat(String(response.data?.id)))
        return neww
      })
    }
  }

  return (
    <Space direction="vertical" style={{ width: '39%' }}>
      <Text>Saved Favorite Queries</Text>
      <Select style={{ width: '100%' }} value={selectedName || 'Not selected'} onChange={select}>
        <Option key={-1} value="Not Selected">
          Not Selected
        </Option>
        {savedQueries?.map((item) => (
          <Option key={item.id} value={item.name}>
            {item.name}
          </Option>
        ))}
      </Select>
    </Space>
  )
}
