import { message } from 'antd'

class GlobalMessageStore {
  addInfo = (text: string) => {
    message.info(text)
  }

  addError = (text: string) => {
    // Dirty hack, need to think about how to change the authorization
    if (text === 'Unauthorized') {
      return
    }

    message.error(text)
  }

  addSuccess = (text: string) => {
    message.success(text)
  }
}

export const globalMessageStore = new GlobalMessageStore()
