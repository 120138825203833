import { ColSize } from 'antd/lib/grid'
import React from 'react'
import { Col, Form, Select } from 'antd'
import { DspBean } from '../../../api/base/api'

const { Option } = Select

interface Props {
  xs: ColSize | number | string
  value?: number[]
  options?: DspBean[]
  onChange: (value: number[]) => void
  label?: string
  placeholder?: string
  disabled?: boolean
}

export const DspFormMultipleSelect: React.FC<Props> = ({
  xs,
  value,
  options,
  onChange,
  label,
  placeholder,
  disabled,
}) => (
  <Col xs={xs}>
    <div className="ant-form-vertical">
      <Form.Item label={label}>
        <Select
          mode="multiple"
          value={value}
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
          disabled={disabled}
          filterOption={(inp, opt) => {
            if (opt !== undefined) {
              if (opt.children != null) return opt.children.toString().includes(inp)
            }
            return false
          }}
        >
          {options?.map((val) => (
            <Option key={val.id} value={val.id}>
              {val.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  </Col>
)
