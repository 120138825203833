import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import { AccountBean } from '../../../api/base/api'
import { useAccountBean } from '../../../utils/useAccountBean'

interface Props {
  deleteItem: () => Promise<any>
  refreshList: () => void
  redirectAfter: () => void
  page: string
}

export const ListDeleteButton: React.FC<Props> = ({ deleteItem, refreshList, redirectAfter, page }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const account: AccountBean = useAccountBean()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    deleteItem().then(() => {
      refreshList()
      redirectAfter()
      setIsModalOpen(false)
    })
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  function isShow(): boolean {
    if (account.role === AccountBean.RoleEnum.Admin) {
      return true
    }
    if (page != null && page === 'DspList' && !account.canDeleteDsp) {
      return false
    }
    if (page != null && page === 'SspList' && !account.canDeleteSsp) {
      return false
    }
    return true
  }

  return isShow() ? (
    <>
      <Button type="primary" danger onClick={showModal}>
        Delete
      </Button>
      <Modal title="Are you sure?" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText="Yes">
        <p>This action cannot be undone.</p>
      </Modal>
    </>
  ) : null
}
