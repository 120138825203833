import { Col, Row, Space, Typography } from 'antd'
import React from 'react'
import { SspBean, SspBean as SspBeanNamespace } from '../../api/base/api'
import { TrafficTypeFilter } from './TrafficTypeFilter'

const { Text } = Typography

interface Props {
  bean: SspBean
  updateBean: (bean: SspBean) => void
}

export const SspTrafficTypeFilter: React.FC<Props> = ({ bean, updateBean }) => (
  <Col xs={12}>
    <Space direction="vertical" style={{ width: '100%' }}>
      <Text>Traffic Filters</Text>

      <Row justify="space-between">
        <Col xs={5}>
          <Text strong>Traffic Type</Text>
        </Col>
        <Col xs={7}>
          <Text strong>Filter Rule</Text>
        </Col>
        <Col xs={11}>
          <Text strong>Placement Type</Text>
        </Col>
      </Row>

      <TrafficTypeFilter
        label="Audio"
        defaultMode={SspBeanNamespace.AudioDeviceTypesModeEnum[SspBeanNamespace.AudioDeviceTypesModeEnum.INCLUDEALL]}
        filterMode={
          SspBeanNamespace.AudioDeviceTypesModeEnum[bean.audioDeviceTypesMode as SspBean.AudioDeviceTypesModeEnum]
        }
        updateFilterMode={(filterMode: string) => {
          bean.audioDeviceTypesMode = SspBean.AudioDeviceTypesModeEnum[filterMode]
          updateBean(bean)
        }}
        deviceTypeList={
          bean.audioDeviceTypes?.map(
            (item) => SspBeanNamespace.AudioDeviceTypesEnum[item as SspBeanNamespace.AudioDeviceTypesEnum]
          ) as string[]
        }
        updatedeviceTypeList={(deviceTypes: string[]) => {
          bean.audioDeviceTypes = deviceTypes.map((item) => SspBeanNamespace.AudioDeviceTypesEnum[item])
          updateBean(bean)
        }}
      />

      <TrafficTypeFilter
        label="Video"
        defaultMode={SspBeanNamespace.VideoDeviceTypesModeEnum[SspBeanNamespace.VideoDeviceTypesModeEnum.INCLUDEALL]}
        filterMode={
          SspBeanNamespace.VideoDeviceTypesModeEnum[bean.videoDeviceTypesMode as SspBean.VideoDeviceTypesModeEnum]
        }
        updateFilterMode={(filterMode: string) => {
          bean.videoDeviceTypesMode = SspBean.VideoDeviceTypesModeEnum[filterMode]
          updateBean(bean)
        }}
        deviceTypeList={
          bean.videoDeviceTypes?.map(
            (item) => SspBeanNamespace.VideoDeviceTypesEnum[item as SspBeanNamespace.VideoDeviceTypesEnum]
          ) as string[]
        }
        updatedeviceTypeList={(deviceTypes: string[]) => {
          bean.videoDeviceTypes = deviceTypes.map((item) => SspBeanNamespace.VideoDeviceTypesEnum[item])
          updateBean(bean)
        }}
      />

      <TrafficTypeFilter
        label="Banner"
        defaultMode={SspBeanNamespace.BannerDeviceTypesModeEnum[SspBeanNamespace.BannerDeviceTypesModeEnum.INCLUDEALL]}
        filterMode={
          SspBeanNamespace.BannerDeviceTypesModeEnum[bean.bannerDeviceTypesMode as SspBean.BannerDeviceTypesModeEnum]
        }
        updateFilterMode={(filterMode: string) => {
          bean.bannerDeviceTypesMode = SspBean.BannerDeviceTypesModeEnum[filterMode]
          updateBean(bean)
        }}
        deviceTypeList={
          bean.bannerDeviceTypes?.map(
            (item) => SspBeanNamespace.BannerDeviceTypesEnum[item as SspBeanNamespace.BannerDeviceTypesEnum]
          ) as string[]
        }
        updatedeviceTypeList={(deviceTypes: string[]) => {
          bean.bannerDeviceTypes = deviceTypes.map((item) => SspBeanNamespace.BannerDeviceTypesEnum[item])
          updateBean(bean)
        }}
      />

      <TrafficTypeFilter
        label="Native"
        defaultMode={SspBeanNamespace.NativeDeviceTypesModeEnum[SspBeanNamespace.NativeDeviceTypesModeEnum.INCLUDEALL]}
        filterMode={
          SspBeanNamespace.NativeDeviceTypesModeEnum[bean.nativeDeviceTypesMode as SspBean.NativeDeviceTypesModeEnum]
        }
        updateFilterMode={(filterMode: string) => {
          bean.nativeDeviceTypesMode = SspBean.NativeDeviceTypesModeEnum[filterMode]
          updateBean(bean)
        }}
        deviceTypeList={
          bean.nativeDeviceTypes?.map(
            (item) => SspBeanNamespace.NativeDeviceTypesEnum[item as SspBeanNamespace.NativeDeviceTypesEnum]
          ) as string[]
        }
        updatedeviceTypeList={(deviceTypes: string[]) => {
          bean.nativeDeviceTypes = deviceTypes.map((item) => SspBeanNamespace.NativeDeviceTypesEnum[item])
          updateBean(bean)
        }}
      />
    </Space>
  </Col>
)
