import { CountryControllerApi } from './base/api'
import { configuration } from './utils/apiConfiguration'
import { customFetch } from './utils/customFetch'

class CountryApi {
  private api = new CountryControllerApi(configuration, configuration.basePath, customFetch)

  list() {
    return this.api.countries(1000, 0)
  }
}

export const countryApi = new CountryApi()
