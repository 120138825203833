import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Col, Row } from 'antd'
import moment from 'moment'
import { routes } from '../../../routes'
import { reportApi } from '../../../api/report'
import { FormSubmitButton } from '../../../components/form'
import { FormDatePicker } from '../../../components/form/inputs/FormDatePicker'
import { FormTypeSelect } from '../../../components/form/inputs/FormTypeSelect'
import { FilterEntry, StatsFilter } from '../../../api/base/api'
import { FormMultipleReportSelect } from '../../../components/form/inputs/FormMultipleReportSelect'
import { FiltersPanelComponent } from './FiltersPanelComponent'
import TypeEnum = StatsFilter.TypeEnum
import { Query } from '../StatisticsFormContainer'
import { FilterType } from './utils/FilterType'
import { keysAvailable } from './utils/KeysAvailable'
import { RequestPeriods, lableToDictionaryElems, FilterElem } from './utils/utils'
import { valuesAvailable } from './utils/ValuesAvailable'

interface Props {
  isFavorite: boolean | undefined
  setStatisticData: (StatisticData) => void
  query: Query
  updateQuery: (Query) => void
}

export const ReportBuilderForm: React.FC<Props> = ({ isFavorite, setStatisticData, query, updateQuery }) => {
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (!isLoading && query && isFavorite && location.pathname.includes(routes.favorite)) {
      generate()
    }
  }, [query])

  const requestPeriods = [
    RequestPeriods.Today,
    RequestPeriods.Yesterday,
    RequestPeriods.Past_3_days,
    RequestPeriods.Last_7_days,
    RequestPeriods.Last_30_days,
    RequestPeriods.This_month,
    RequestPeriods.Last_month,
    RequestPeriods.Custom_range,
  ]

  const keysAvailableFiltered = keysAvailable

  const activeRequestPeriodsChange = (periodString: string) => {
    let startDate = moment()
    let endDate = moment()
    switch (periodString) {
      case RequestPeriods.Today:
        startDate = moment()
        endDate = moment()
        break
      case RequestPeriods.Yesterday:
        startDate = moment().subtract(1, 'days')
        endDate = moment().subtract(1, 'days')
        break
      case RequestPeriods.Past_3_days:
        startDate = moment().subtract(2, 'days')
        endDate = moment()
        break
      case RequestPeriods.Last_7_days:
        startDate = moment().subtract(6, 'days')
        endDate = moment()
        break
      case RequestPeriods.Last_30_days:
        startDate = moment().subtract(29, 'days')
        endDate = moment()
        break
      case RequestPeriods.This_month:
        startDate = moment().date(1)
        endDate = moment()
        break
      case RequestPeriods.Last_month:
        startDate = moment().subtract(1, 'months').date(1)
        endDate = moment().subtract(1, 'months').endOf('month')
        break
      case RequestPeriods.Custom_range:
        startDate = moment()
        endDate = moment()
        break
    }
    if (query) {
      query.startDate = startDate
      query.endDate = endDate
    }
    updateQuery({ startDate, endDate, activeRequestPeriods: periodString })
  }

  const generate = () => {
    setIsLoading(true)

    if (query !== undefined && query.activeRequestPeriods !== RequestPeriods.Custom_range) {
      activeRequestPeriodsChange(query.activeRequestPeriods)
    }

    reportApi
      .statistics({
        startDate: moment(query?.startDate).format('YYYY-MM-DD') as string,
        endDate: moment(query?.endDate).format('YYYY-MM-DD') as string,
        // @ts-ignore
        keys: query?.keys.map((key) => key.value),
        // @ts-ignore
        values: query?.values.map((value) => value.value),
        filters: createFilterEntries(),
      })
      .then((response) =>
        setStatisticData({
          keys: query?.keys,
          values: query?.values,
          response,
        })
      )
      .finally(() => setIsLoading(false))
  }

  const createFilterEntries = (): FilterEntry[] => {
    const reportFilters: FilterEntry[] = []
    query?.filters.forEach((filter) => {
      if (!filter.key) {
        return
      }
      const filterKeys = [filter.key]
      const statFilter: StatsFilter = {
        type: TypeEnum.InList,
        params: [],
      }
      statFilter.params = filter.params.map((param) => param)
      const keyElem = keysAvailableFiltered.filter((key) => filter.key === key.value)[0]
      if (!keyElem) {
        return
      }

      switch (keyElem.filterType) {
        case FilterType.CUSTOM_BOOL:
          statFilter.type = StatsFilter.TypeEnum.Equal
          statFilter.params[0] = statFilter.params[0] ? statFilter.params[0] === 'true' : true
          break
        case FilterType.CUSTOM_RANGE:
          statFilter.type = StatsFilter.TypeEnum.Range
          break
        case FilterType.CUSTOM_RANGE_INT:
          statFilter.type = StatsFilter.TypeEnum.Range
          statFilter.params[0] = parseInt(statFilter.params[0], 10)
          statFilter.params[1] = parseInt(statFilter.params[1], 10)
          break
        case FilterType.CUSTOM_HOURS:
          statFilter.type = StatsFilter.TypeEnum.Hour
          statFilter.params[0] = parseInt(statFilter.params[0], 10)
          statFilter.params[1] = parseInt(statFilter.params[1], 10)
          break
        default:
          statFilter.type = StatsFilter.TypeEnum.InList
          break
      }
      if (statFilter.type) {
        const filterEntry: FilterEntry = {
          keys: filterKeys.map((key) => {
            const keyStr: string = FilterEntry.KeysEnum[key].toString()
            return FilterEntry.KeysEnum[keyStr]
          }),
          filter: statFilter,
        }
        reportFilters.push(filterEntry)
      }
    })

    return reportFilters
  }

  const onKeysChange = (selectedKeys: string[]) => {
    updateQuery({ keys: lableToDictionaryElems(selectedKeys, keysAvailable) })
  }

  const onValuesChange = (selectedValues: string[]) => {
    updateQuery({ values: lableToDictionaryElems(selectedValues, valuesAvailable) })
  }

  return (
    <>
      <Row justify="start" align="bottom" gutter={20}>
        <FormTypeSelect
          xs={5}
          value={query.activeRequestPeriods}
          options={requestPeriods}
          onChange={activeRequestPeriodsChange}
          label="Period"
        />

        {RequestPeriods.Custom_range === query.activeRequestPeriods && (
          <>
            <FormDatePicker
              xs={3}
              label="Start Date"
              value={moment(query.startDate) || moment()}
              onChange={(startDate) => updateQuery({ startDate })}
            />
            <FormDatePicker
              xs={3}
              label="End Date"
              value={moment(query.endDate) || moment()}
              onChange={(endDate) => updateQuery({ endDate })}
            />
          </>
        )}

        <Col flex="auto">
          <></>
        </Col>

        <FormSubmitButton onClick={generate} text="Generate" />
      </Row>

      <Row justify="space-between" gutter={20}>
        <FormMultipleReportSelect
          xs={10}
          onChange={onKeysChange}
          label="Group By"
          placeholder="Please select"
          value={query.keys.map((key) => key.label)}
          options={keysAvailable}
        />

        <FormMultipleReportSelect
          xs={14}
          onChange={onValuesChange}
          label="Values"
          placeholder="Please select"
          value={query.values ? query.values.map((value) => value.label) : []}
          options={valuesAvailable}
        />
      </Row>

      <FiltersPanelComponent
        reportFilters={query?.filters as FilterElem[]}
        setFilters={(filters) => updateQuery({ filters })}
        keysAvailable={keysAvailableFiltered.filter(
          (item) =>
            item.value !== FilterEntry.KeysEnum.SspName.toString() &&
            item.value !== FilterEntry.KeysEnum.Domain.toString() &&
            item.value !== FilterEntry.KeysEnum.Country.toString()
        )}
      />
    </>
  )
}
