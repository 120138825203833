enum FilterModeEnum {
  INCLUDE = 'Include Selected',
  EXCLUDE = 'Exclude Selected',
  INCLUDEALL = 'Include All',
}

export const filterModeArray: {
  key: string
  value: string
}[] = Object.entries(FilterModeEnum).map(([key, value]) => ({ key, value }))
