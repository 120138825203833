import { ColSize } from 'antd/lib/grid'
import React from 'react'
import { Col, Form } from 'antd'
import TextArea from 'antd/es/input/TextArea'

interface Props {
  xs: ColSize | number | string
  rows?: number
  value?: string
  onChange: (value: string) => void
  label?: string
  placeholder?: string
  disabled?: boolean
}

export const FormTextArea: React.FC<Props> = ({ xs, rows, value, onChange, label, placeholder, disabled }) => (
  <Col xs={xs}>
    <div className="ant-form-vertical">
      <Form.Item label={label}>
        <TextArea
          rows={rows}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          disabled={disabled}
        />
      </Form.Item>
    </div>
  </Col>
)
