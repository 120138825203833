import React, { useState } from 'react'
import { Card, Col, Row } from 'antd'
import { Moment } from 'moment'
import { DictionaryElem, FilterElem } from './components/utils/utils'
import { StatResponseBeanRawStatRow } from '../../api/base/api'
import { useQueryState } from './components/utils/useQueryState'
import { ReportBuilderForm } from './components/ReportBuilderForm'
import { SaveQueryComponent } from './components/SaveQueryComponent'
import { SelectFavoriteSavedQuery } from './components/SelectFavoriteSavedQuery'
import { StatisticsViewForm } from './components/StatisticsViewForm'

export interface StatisticData {
  response: StatResponseBeanRawStatRow
  keys: DictionaryElem[]
  values: DictionaryElem[]
}

export interface Query {
  activeRequestPeriods: string
  startDate: Moment
  endDate: Moment
  keys: DictionaryElem[]
  values: DictionaryElem[]
  filters: FilterElem[]
}

export interface QueryState {
  query: Query

  id: number
  name: string
  favorite: boolean
}

interface Props {
  id?: number
}

export const StatisticsFormContainer: React.FC<Props> = ({ id }) => {
  const [statisticData, setStatisticData] = useState<StatisticData>({ keys: [], response: {}, values: [] })
  const { queryState, updateQueryState } = useQueryState(id)

  const updateQuery = (query) => {
    queryState.query = { ...queryState.query, ...query }
    updateQueryState(queryState)
  }

  return (
    <Row gutter={[16, 8]}>
      <Col xs={24}>
        <Card
          title="Report Parameters"
          bordered={false}
          className="criclebox"
          headStyle={{ fontWeight: '600', fontSize: '20px', border: 'none' }}
        >
          {queryState.query !== undefined ? (
            <>
              <ReportBuilderForm
                isFavorite={queryState.favorite}
                setStatisticData={setStatisticData}
                query={queryState.query}
                updateQuery={updateQuery}
              />
              <Row justify="space-between" align="bottom">
                <Col xs={12}>
                  <SelectFavoriteSavedQuery queryState={queryState} updateQueryState={updateQueryState} />
                </Col>
                <Col>
                  <SaveQueryComponent queryState={queryState} updateQueryState={updateQueryState} />
                </Col>
              </Row>
            </>
          ) : null}
        </Card>
      </Col>
      {statisticData.response.rows != null && (
        <Col xs={24}>
          <StatisticsViewForm statisticData={statisticData} />
        </Col>
      )}
    </Row>
  )
}
