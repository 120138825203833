import React from 'react'
import { useHistory } from 'react-router-dom'
import { Row } from 'antd'
import { useFormData } from '../../../components/list'
import { getListPath, routes } from '../../../routes'
import { FormCancelButton, FormSubmitButton } from '../../../components/form'
import { dspApi } from '../../../api/dsp'
import { DspCreateEditForm } from './DspCreateEditForm'
import { DspTrafficFilters } from './DspTrafficFilters'
import { DspBean } from '../../../api/base/api'

interface Props {
  type: 'create' | 'edit'
  initialBean: Partial<DspBean>
  activeTab: 'Main' | 'TrafficFilters'
}

export const DspForm: React.FC<Props> = ({ type, initialBean, activeTab }) => {
  const history = useHistory()
  const [formData, updateForm] = useFormData({ ...initialBean })

  const create = () => {
    dspApi.create(formData as DspBean).then((data) => history.push(getListPath(routes.dsp), data.data?.id))
  }

  const edit = () => {
    dspApi.edit(formData as DspBean).then((data) => history.push(getListPath(routes.dsp), data.data?.id))
  }

  const submit = () => {
    if (type === 'create') {
      create()
    }
    if (type === 'edit') {
      edit()
    }
  }

  return (
    <>
      <Row>
        {activeTab === 'Main' && <DspCreateEditForm bean={formData} updateBean={updateForm} />}
        {activeTab === 'TrafficFilters' && <DspTrafficFilters bean={formData} updateBean={updateForm} />}
      </Row>

      <Row gutter={15}>
        <FormSubmitButton text={type === 'create' ? 'Create' : 'Edit'} onClick={submit} />
        <FormCancelButton path={getListPath('/dsp', initialBean.id)} />
      </Row>
    </>
  )
}
