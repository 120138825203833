import React from 'react'
import { Card, Col, Row } from 'antd'
import { Moment } from 'moment/moment'
import { SizeChartBean } from '../../../api/base/api'
import { DataSelect } from './DateSelect'
import { DashboardState } from '../Dashboard'
import { ChartWithSelect } from './ChartWithSelect'

interface Props {
  dataSelectState: Partial<DashboardState>
  dataSelectRefreshState: (
    startDate: Moment,
    endDate: Moment,
    button: 'None' | 'Range' | 'Today' | 'Yesterday' | 'Last 7 Days' | 'Last 30 Days' | 'This Month' | 'Last Month'
  ) => void
  adTypeData: SizeChartBean | undefined
}

export const TrafficTypes: React.FC<Props> = ({ dataSelectState, dataSelectRefreshState, adTypeData }) => (
  <Col xs={24}>
    <Card bordered={false} headStyle={{ fontWeight: '600', fontSize: '20px', border: 'none' }}>
      <DataSelect state={dataSelectState} refreshState={dataSelectRefreshState} />
    </Card>

    <Row justify="space-between" gutter={4}>
      <Col xs={24} sm={24} md={24}>
        <ChartWithSelect title="Bid requests by Traffic type" data={adTypeData} />
      </Col>
    </Row>
  </Col>
)
