import React from 'react'
import { Card, Table, Typography } from 'antd'
import moment from 'moment'
import { StatisticData } from '../StatisticsFormContainer'

const { Text } = Typography

interface Props {
  statisticData: StatisticData
}

interface Column {
  title: string
  dataIndex: string
  key: string
  align: 'center'
  render: any
}

export const StatisticsViewForm: React.FC<Props> = ({ statisticData }) => {
  const { keys, values, response } = statisticData

  const columns: Column[] = []
  keys.concat(values).forEach((item) =>
    columns.push({
      title: item.label,
      dataIndex: item.value,
      key: item.value,
      align: 'center',
      render: (text) => (text !== undefined ? String(text) : ''),
    })
  )

  let isShowTotal = true
  let keyFooterIndex = 100000
  const footer: any[] = []
  keys.forEach(() => {
    if (isShowTotal) {
      isShowTotal = false
      footer.push(
        <Table.Summary.Cell index={(keyFooterIndex += 1)} align="center">
          <Text strong>Total</Text>
        </Table.Summary.Cell>
      )
    } else {
      footer.push(<Table.Summary.Cell index={(keyFooterIndex += 1)} />)
    }
  })
  values.forEach((elem, index) =>
    footer.push(
      <Table.Summary.Cell index={index} align="center">
        <Text strong>{response.total?.[elem.value]}</Text>
      </Table.Summary.Cell>
    )
  )

  return (
    <Card
      title={`Result on ${moment().format('HH:mm, DD MMM YYYY')}`}
      headStyle={{ fontWeight: '400', fontSize: '18px', border: 'none' }}
    >
      <Table
        bordered
        style={{ overflow: 'auto' }}
        dataSource={response.rows}
        columns={columns}
        summary={() => <Table.Summary.Row>{footer}</Table.Summary.Row>}
      />
    </Card>
  )
}
