import { Col, Form, Checkbox } from 'antd'
import { ColSize } from 'antd/lib/grid'
import React from 'react'

interface Props {
  xs: ColSize | number | string
  checked?: boolean
  onChange: (value: boolean) => void
  label?: string
  disabled?: boolean
  description?: string
}

export const FormCheckbox: React.FC<Props> = ({ xs, checked, onChange, label, disabled, description }) => (
  <Col xs={xs}>
    <div className="ant-form-vertical">
      <Form.Item label={label}>
        <Checkbox checked={checked} onChange={(e) => onChange(e.target.checked)} disabled={disabled} />
        <div style={{ fontStyle: 'italic', color: 'gray' }}>{description}</div>
      </Form.Item>
    </div>
  </Col>
)
