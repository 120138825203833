import React from 'react'
import { Col, Row } from 'antd'
import { DailyStatsRow, DailyValuesBean, TopItem } from '../../../api/base/api'
import { NumericValues } from './NumericValues'
import { TopList } from './TopList'
import { numberRenderer } from './NumberRenderer'

interface Props {
  dailyValues: DailyValuesBean | undefined
  topSspList: TopItem[] | undefined
  topDspList: TopItem[] | undefined
  topDomainList: TopItem[] | undefined
  dailyStatsRows: DailyStatsRow[] | undefined
}

const cellRenderer = (topItem: TopItem) => [topItem.name, numberRenderer(topItem.value)]

export const DailyStats: React.FC<Props> = ({ dailyValues, topSspList, topDspList, topDomainList, dailyStatsRows }) => (
  <Col xs={24}>
    <NumericValues data={dailyValues} />
    <Col xs={24} sm={24} md={24}>
      <TopList
        title="Daily stats table (last 7 days)"
        list={dailyStatsRows}
        headers={[
          'Date',
          'SSP Request',
          'SSP Impression Wins',
          'SSP Gross Impression',
          'SSP Estimated Revenue',
          'SSP NET Revenue',
          'Profit',
        ]}
        renderRowCells={(dailyStats: DailyStatsRow) => [
          dailyStats.date,
          numberRenderer(dailyStats.sspRequest),
          numberRenderer(dailyStats.sspImpressionWins),
          numberRenderer(dailyStats.sspGrossImpression),
          numberRenderer(dailyStats.sspEstimatedRevenue),
          numberRenderer(dailyStats.sspNetRevenue),
          numberRenderer(dailyStats.profit),
        ]}
      />
    </Col>

    <Row justify="space-between" gutter={4}>
      <Col xs={24} sm={12} xl={8}>
        <TopList
          title="Top SSPs by Total Revenue (last 7 days)"
          list={topSspList}
          headers={['Name', 'Total Revenue']}
          renderRowCells={(item) => cellRenderer(item)}
        />
      </Col>

      <Col xs={24} sm={12} xl={8}>
        <TopList
          title="Top DSPs by Total Revenue (last 7 days)"
          list={topDspList}
          headers={['Name', 'Total Revenue']}
          renderRowCells={cellRenderer}
        />
      </Col>

      <Col xs={24} sm={12} xl={8}>
        <TopList
          title="Top Domains by Total Revenue (last 7 days)"
          list={topDomainList}
          headers={['Name', 'Total Revenue']}
          renderRowCells={cellRenderer}
        />
      </Col>
    </Row>
  </Col>
)
