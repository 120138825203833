import { useState } from 'react'

export function useFormData<Bean>(bean: Bean): [Bean, (bean: Partial<Bean>) => void] {
  const [formData, setFormData] = useState(bean)

  const updateForm = (data: Partial<Bean>) => {
    setFormData({ ...formData, ...data })
  }

  return [formData, updateForm]
}
