import { Descriptions } from 'antd'
import React, { useEffect, useState } from 'react'
import { CountryBean, DspBean, SspBean } from '../../api/base/api'
import { countryApi } from '../../api/country'
import { useGetList, useListFilters } from '../list'
import { iabCategoryApi } from '../../api/iabCategory'
import { iabFixedSizeApi } from '../../api/iabFixedSize'
import { inputListInfoString, listToString, modeToString, trafficTypesInfoString } from '../../utils/filtersToInfo'
import { browserApi } from '../../api/browser'

interface Props {
  selectedItem: DspBean | SspBean
}

export const InfoTrafficFilters: React.FC<Props> = ({ selectedItem }) => {
  const [countriesList, setCountriesList] = useState([] as CountryBean[])

  useEffect(() => {
    if (countriesList.length === 0) {
      countryApi.list().then((response) => {
        setCountriesList(response.data?.map(({ data }) => data as CountryBean) || [])
      })
    }
  })

  const iabCategoryFilters = useListFilters('iabCategory').filters
  const iabCategoryList = useGetList(() => iabCategoryApi.list(iabCategoryFilters), iabCategoryFilters).list
  const iabFixedSizeFilters = useListFilters('iabFixedSize').filters
  const iabFixedSizeList = useGetList(() => iabFixedSizeApi.list(iabFixedSizeFilters), iabFixedSizeFilters).list
  const browserFilters = useListFilters('browser').filters
  const browserList = useGetList(() => browserApi.list(browserFilters), browserFilters).list

  return (
    <Descriptions>
      <Descriptions.Item label="Publisher" span={3}>
        {inputListInfoString(
          selectedItem.publisherFilterMode,
          selectedItem.publisherList,
          selectedItem.publisherFilterMode === DspBean.PublisherFilterModeEnum.INCLUDEALL ||
            selectedItem.publisherFilterMode === SspBean.PublisherFilterModeEnum.INCLUDEALL
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Domain" span={3}>
        {inputListInfoString(
          selectedItem.domainFilterMode,
          selectedItem.domainList,
          selectedItem.domainFilterMode === DspBean.DomainFilterModeEnum.INCLUDEALL ||
            selectedItem.domainFilterMode === SspBean.DomainFilterModeEnum.INCLUDEALL
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Countries" span={3}>
        {selectedItem.countryFilterMode === DspBean.CountryFilterModeEnum.INCLUDEALL ||
        selectedItem.countryFilterMode === SspBean.CountryFilterModeEnum.INCLUDEALL
          ? `mode: ${modeToString(selectedItem.countryFilterMode)}`
          : `mode: ${modeToString(selectedItem.countryFilterMode)}, values: ${listToString(
              selectedItem.countryIds?.map((id) => countriesList.find((el) => el.id === id)?.name)
            )}`}
      </Descriptions.Item>
      <Descriptions.Item label="IP list" span={3}>
        {inputListInfoString(
          selectedItem.ipFilterMode,
          selectedItem.ipList,
          selectedItem.ipFilterMode === DspBean.IpFilterModeEnum.INCLUDEALL ||
            selectedItem.ipFilterMode === SspBean.IpFilterModeEnum.INCLUDEALL
        )}
      </Descriptions.Item>
      <Descriptions.Item label="App Id" span={3}>
        {inputListInfoString(
          selectedItem.appIdFilterMode,
          selectedItem.appIdList,
          selectedItem.appIdFilterMode === DspBean.AppIdFilterModeEnum.INCLUDEALL ||
            selectedItem.appIdFilterMode === SspBean.AppIdFilterModeEnum.INCLUDEALL
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Browser" span={3}>
        {selectedItem.browserFilterMode === DspBean.BrowserFilterModeEnum.INCLUDEALL ||
        selectedItem.browserFilterMode === SspBean.BrowserFilterModeEnum.INCLUDEALL
          ? `mode: ${modeToString(selectedItem.browserFilterMode)}`
          : `mode: ${modeToString(selectedItem.browserFilterMode)}, values: ${listToString(
              selectedItem.browserIds?.map((id) => {
                const browser = browserList.find((el) => el.id === id)
                return `${browser?.name}`
              })
            )}`}
      </Descriptions.Item>
      <Descriptions.Item label="IAB categories" span={3}>
        {selectedItem.iabCategoryFilterMode === DspBean.IabCategoryFilterModeEnum.INCLUDEALL ||
        selectedItem.iabCategoryFilterMode === SspBean.IabCategoryFilterModeEnum.INCLUDEALL
          ? `mode: ${modeToString(selectedItem.countryFilterMode)}`
          : `mode: ${modeToString(selectedItem.iabCategoryFilterMode)}, values: ${listToString(
              selectedItem.iabCategoryIds?.map((id) => {
                const category = iabCategoryList.find((el) => el.id === id)
                return `${category?.name} (${category?.code})`
              })
            )}`}
      </Descriptions.Item>
      <Descriptions.Item label="IAB fixed sizes" span={3}>
        {selectedItem.iabFixedSizeFilterMode === DspBean.IabFixedSizeFilterModeEnum.INCLUDEALL ||
        selectedItem.iabFixedSizeFilterMode === SspBean.IabFixedSizeFilterModeEnum.INCLUDEALL
          ? `mode: ${modeToString(selectedItem.countryFilterMode)}`
          : `mode: ${modeToString(selectedItem.iabFixedSizeFilterMode)}, values: ${listToString(
              selectedItem.iabFixedSizeIds?.map((id) => {
                const size = iabFixedSizeList.find((el) => el.id === id)
                return `${size?.adUnitName} (${size?.width}x${size?.height})`
              })
            )}`}
      </Descriptions.Item>
      <Descriptions.Item label="Audio placement type" span={3}>
        {trafficTypesInfoString(
          selectedItem.audioDeviceTypesMode,
          selectedItem.audioDeviceTypes,
          selectedItem.audioDeviceTypesMode === DspBean.AudioDeviceTypesModeEnum.INCLUDEALL ||
            selectedItem.audioDeviceTypesMode === SspBean.AudioDeviceTypesModeEnum.INCLUDEALL
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Video placement type" span={3}>
        {trafficTypesInfoString(
          selectedItem.videoDeviceTypesMode,
          selectedItem.videoDeviceTypes,
          selectedItem.videoDeviceTypesMode === DspBean.VideoDeviceTypesModeEnum.INCLUDEALL ||
            selectedItem.videoDeviceTypesMode === SspBean.VideoDeviceTypesModeEnum.INCLUDEALL
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Banner placement type" span={3}>
        {trafficTypesInfoString(
          selectedItem.bannerDeviceTypesMode,
          selectedItem.bannerDeviceTypes,
          selectedItem.bannerDeviceTypesMode === DspBean.BannerDeviceTypesModeEnum.INCLUDEALL ||
            selectedItem.bannerDeviceTypesMode === SspBean.BannerDeviceTypesModeEnum.INCLUDEALL
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Native placement type" span={3}>
        {trafficTypesInfoString(
          selectedItem.nativeDeviceTypesMode,
          selectedItem.nativeDeviceTypes,
          selectedItem.nativeDeviceTypesMode === DspBean.NativeDeviceTypesModeEnum.INCLUDEALL ||
            selectedItem.nativeDeviceTypesMode === SspBean.NativeDeviceTypesModeEnum.INCLUDEALL
        )}
      </Descriptions.Item>
      <Descriptions.Item label="OS" span={3}>
        {`values: ${listToString(
          selectedItem.osTypes?.map(
            (el) =>
              `${el.name} ${el.minVersion ? `from v. ${el.minVersion}` : ''} ${
                el.maxVersion ? `up to v. ${el.maxVersion}` : ''
              }`
          )
        )}`}
      </Descriptions.Item>
    </Descriptions>
  )
}
