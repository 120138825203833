import React from 'react'
import { Col, DatePicker, Form } from 'antd'
import { ColSize } from 'antd/lib/grid'
import { Moment } from 'moment'

interface Props {
  xs: ColSize | number | string
  label: string
  onChange: (date: Moment) => void
  value: Moment
}

export const FormDatePicker: React.FC<Props> = ({ xs, label, onChange, value }) => (
  <Col xs={xs}>
    <div className="ant-form-vertical">
      <Form.Item label={label}>
        <DatePicker value={value} format="DD MMM YYYY" onChange={(date) => (date != null ? onChange(date) : null)} />
      </Form.Item>
    </div>
  </Col>
)
