enum OpenRtbDeviceTypeEnum {
  Mobile = 'Mobile',
  PC = 'PC',
  ConnectedTV = 'Connected TV',
  Phone = 'Phone',
  Tablet = 'Tablet',
  ConnectedDevice = 'Connected Device',
  SetTopBox = 'Set Top Box',
}

export const listDeviceTypeArray: {
  key: string
  value: string
}[] = Object.entries(OpenRtbDeviceTypeEnum).map(([key, value]) => ({ key, value }))
