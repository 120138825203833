import React from 'react'
import { Button } from 'antd'

interface Props {
  refreshList: () => void
  action: () => void
  label?: string
}
export const ListActionButton: React.FC<Props> = ({ refreshList, label, action }) => {
  const click = () => {
    action()
    refreshList()
  }

  return (
    <Button type="primary" onClick={click}>
      {label || 'Action'}
    </Button>
  )
}
