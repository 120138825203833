import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { getListPath, routes } from '../../routes'
import { SavedQueryList } from './list/SavedQueryList'

export const SavedReportQueryRoot: React.FC = () => (
  <Switch>
    <Route
      path={`${getListPath(routes.savedQuery)}/:id?`}
      render={(rp) => <SavedQueryList selectedId={+(rp.match.params.id ?? '')} />}
    />
    <Redirect to={getListPath(routes.savedQuery)} />
  </Switch>
)
