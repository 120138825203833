import { useEffect, useState } from 'react'
import { SavedQueryBean } from '../api/base/api'
import { savedQueriesStore } from './SavedQueriesStore'

export function useSavedQueries() {
  const [savedQueries, setSavedQueries] = useState<SavedQueryBean[]>(savedQueriesStore.list)

  useEffect(() => {
    const cb = () => setSavedQueries(savedQueriesStore.list)
    savedQueriesStore.subscribe(cb)
    return () => savedQueriesStore.unsubscribe(cb)
  })

  const refreshSavedQueries = () => {
    savedQueriesStore.refresh()
  }

  return { savedQueries, refreshSavedQueries }
}
