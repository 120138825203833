import { Row } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { accountApi } from '../../../api/account'
import { AccountBean } from '../../../api/base/api'
import { dspApi } from '../../../api/dsp'
import { sspApi } from '../../../api/ssp'
import { FormCancelButton, FormCheckbox, FormSubmitButton, FormTextInput } from '../../../components/form'
import { DspFormMultipleSelect } from '../../../components/form/inputs/DspFormMultipleSelect'
import { FormTypeSelect } from '../../../components/form/inputs/FormTypeSelect'
import { SspFormMultipleSelect } from '../../../components/form/inputs/SspFormMultipleSelect'
import { useFormData, useGetList, useListFilters } from '../../../components/list'
import { getListPath, routes } from '../../../routes'
import { roleArray, roleToStringMap, stringToRoleMap } from '../../../shared/enum/RoleEnum'
import { globalMessageStore } from '../../../utils/globalMessageStore'

interface Props {
  type: 'create' | 'edit'
  initialBean: Partial<AccountBean>
}

export const AccountForm: React.FC<Props> = ({ type, initialBean }) => {
  const history = useHistory()
  const [formData, updateForm] = useFormData({ ...initialBean })
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [passStatus, setPassStatus] = useState<'' | 'warning' | 'error' | undefined>('')

  const dspFilters = useListFilters('account_dsp').filters
  const dspList = useGetList(() => dspApi.list(dspFilters), dspFilters).list
  const sspFilters = useListFilters('account_ssp').filters
  const sspList = useGetList(() => sspApi.list(sspFilters), sspFilters).list

  const create = () => {
    accountApi.create(formData as AccountBean).then((data) => history.push(getListPath(routes.account), data.data?.id))
  }

  const edit = () => {
    accountApi.edit(formData as AccountBean).then((data) => history.push(getListPath(routes.account), data.data?.id))
  }

  const submit = () => {
    if (formData.password != null && formData.password !== passwordConfirm) {
      globalMessageStore.addError("Passwords don't match")
      setPassStatus('error')
      return null
    }

    if (type === 'create') {
      create()
    }
    if (type === 'edit') {
      edit()
    }
  }

  const roleChange = (role: string) => {
    const roleEnum = stringToRoleMap.get(role)
    if (roleEnum === AccountBean.RoleEnum.Admin) {
      updateForm({
        role: roleEnum,
        canCreateDsp: true,
        canEditDsp: true,
        canDeleteDsp: true,
        canCreateSsp: true,
        canEditSsp: true,
        canDeleteSsp: true,
      })
    } else {
      updateForm({
        role: roleEnum,
        canCreateDsp: false,
        canEditDsp: false,
        canDeleteDsp: false,
        canCreateSsp: false,
        canEditSsp: false,
        canDeleteSsp: false,
      })
    }
  }

  return (
    <>
      <Row gutter={24}>
        <FormTextInput
          xs={12}
          value={formData.username}
          onChange={(username) => updateForm({ username })}
          label="Username"
          placeholder="Enter username"
        />
      </Row>

      <Row gutter={24}>
        <FormTextInput
          xs={12}
          value={formData.email}
          onChange={(email) => updateForm({ email })}
          label="Email"
          placeholder="Enter email"
          type="email"
        />
      </Row>

      <Row gutter={24}>
        <FormTextInput
          xs={12}
          value={formData.password}
          onChange={(password) => {
            updateForm({ password })
            if (password === passwordConfirm) {
              setPassStatus('')
            }
          }}
          label="Password"
          placeholder="Enter password if you want to change it"
          type="password"
          status={passStatus}
        />
      </Row>

      <Row gutter={24}>
        <FormTextInput
          xs={12}
          value={passwordConfirm}
          onChange={(password) => {
            setPasswordConfirm(password)
            if (password === formData.password) {
              setPassStatus('')
            }
          }}
          label="Confirm Password"
          placeholder="Enter password again"
          type="password"
          status={passStatus}
        />
      </Row>

      <Row gutter={24}>
        <FormTypeSelect
          xs={12}
          value={roleToStringMap.get(formData.role as AccountBean.RoleEnum)}
          options={roleArray}
          onChange={roleChange}
          label="Role"
        />
      </Row>

      <Row gutter={24}>
        <DspFormMultipleSelect
          xs={12}
          value={formData.dspIds}
          options={dspList.map((dspBean) => dspBean)}
          onChange={(dspIds) => updateForm({ dspIds })}
          label="DSPs access list"
          disabled={formData.role === AccountBean.RoleEnum.Admin}
        />
      </Row>

      <Row gutter={24}>
        <SspFormMultipleSelect
          xs={12}
          value={formData.sspIds}
          options={sspList.map((sspBean) => sspBean)}
          onChange={(sspIds) => updateForm({ sspIds })}
          label="SSPs access list"
          disabled={formData.role === AccountBean.RoleEnum.Admin}
        />
      </Row>

      <Row gutter={24}>
        <FormCheckbox
          xs={2}
          checked={formData.canCreateDsp}
          onChange={(canCreateDsp) => updateForm({ canCreateDsp })}
          label="Create dsp"
          disabled={formData.role === AccountBean.RoleEnum.Admin}
        />
        <FormCheckbox
          xs={2}
          checked={formData.canEditDsp}
          onChange={(canEditDsp) => updateForm({ canEditDsp })}
          label="Edit dsp"
          disabled={formData.role === AccountBean.RoleEnum.Admin}
        />
        <FormCheckbox
          xs={2}
          checked={formData.canDeleteDsp}
          onChange={(canDeleteDsp) => updateForm({ canDeleteDsp })}
          label="Delete dsp"
          disabled={formData.role === AccountBean.RoleEnum.Admin}
        />
      </Row>
      <Row gutter={24}>
        <FormCheckbox
          xs={2}
          checked={formData.canCreateSsp}
          onChange={(canCreateSsp) => updateForm({ canCreateSsp })}
          label="Create ssp"
          disabled={formData.role === AccountBean.RoleEnum.Admin}
        />
        <FormCheckbox
          xs={2}
          checked={formData.canEditSsp}
          onChange={(canEditSsp) => updateForm({ canEditSsp })}
          label="Edit ssp"
          disabled={formData.role === AccountBean.RoleEnum.Admin}
        />
        <FormCheckbox
          xs={2}
          checked={formData.canDeleteSsp}
          onChange={(canDeleteSsp) => updateForm({ canDeleteSsp })}
          label="Delete ssp"
          disabled={formData.role === AccountBean.RoleEnum.Admin}
        />
      </Row>

      <Row gutter={15}>
        <FormSubmitButton text={type === 'create' ? 'Create' : 'Edit'} onClick={submit} />
        <FormCancelButton path={getListPath('/account', initialBean.id)} />
      </Row>
    </>
  )
}
