import { Descriptions } from 'antd'
import React from 'react'
import { DspBean } from '../../../api/base/api'

interface Props {
  selectedItem: DspBean
}

export const DspInfoMain: React.FC<Props> = ({ selectedItem }) => (
  <Descriptions>
    <Descriptions.Item label="Id" span={3}>
      {selectedItem.id}
    </Descriptions.Item>
    <Descriptions.Item label="Name" span={3}>
      {selectedItem.name}
    </Descriptions.Item>
    <Descriptions.Item label="Endpoint" span={3}>
      {selectedItem.endpoint}
    </Descriptions.Item>
    <Descriptions.Item label="Enabled" span={3}>
      {`${selectedItem.enable}`}
    </Descriptions.Item>
    {selectedItem.maxQps && (
      <Descriptions.Item label="Max QPS" span={3}>
        {`${selectedItem.maxQps}`}
      </Descriptions.Item>
    )}
    <Descriptions.Item label="Bid type" span={3}>
      {`${selectedItem.bidType}`}
    </Descriptions.Item>
    <Descriptions.Item label="Revenue share" span={3}>
      {`${selectedItem.revShare}`}
    </Descriptions.Item>
    <Descriptions.Item label="Min bid floor" span={3}>
      {`${selectedItem.minBidFloor || 'not specified'}`}
    </Descriptions.Item>
    <Descriptions.Item label="Max bid floor" span={3}>
      {`${selectedItem.maxBidFloor || 'not specified'}`}
    </Descriptions.Item>
    {selectedItem.syncLink && (
      <Descriptions.Item label="Sync pixel link" span={3}>
        {`${selectedItem.syncLink}`}
      </Descriptions.Item>
    )}
    <Descriptions.Item label="Empty macro" span={3}>
      {`${selectedItem.emptyMacro}`}
    </Descriptions.Item>
    {selectedItem.syncMacro && (
      <Descriptions.Item label="User ID macro" span={3}>
        {`${selectedItem.syncMacro}`}
      </Descriptions.Item>
    )}
    <Descriptions.Item label="VAST Unwrapping" span={3}>
      {`${selectedItem.vastUnwrapping}`}
    </Descriptions.Item>
  </Descriptions>
)
