import { Button, Col, Input, Row, Select, Space, Typography } from 'antd'
import React, { useState } from 'react'
import { filterModeArray } from './utils/FilterModeEnum'

const { Text } = Typography
const { Option } = Select

interface Props {
  label: string
  values: string[]
  updateValues: (values: string[]) => void
  defaultMode: string
  filterMode: string
  updateFilterMode: (filterMode: string) => void
}

export const ListInputFilter: React.FC<Props> = ({
  label,
  values,
  updateValues,
  defaultMode,
  filterMode,
  updateFilterMode,
}) => {
  const [text, setText] = useState('')

  const add = () => {
    if (!values.includes(text) && text !== '') {
      values.push(text)
      updateValues(values)
    }
    setText('')
  }

  const deselect = (value: string) => {
    values.splice(values.indexOf(value), 1)
    updateValues(values)
  }

  const select = (value: string) => {
    if (!values.includes(value) && value !== '') {
      values.push(value)
      updateValues(values)
    }
    updateValues(values)
  }

  return (
    <Col xs={12}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Text>{label}</Text>
        <Row justify="space-between">
          <Col xl={2}>
            <Button disabled={filterMode === defaultMode || filterMode == null} onClick={add}>
              Add
            </Button>
          </Col>

          <Col xl={12}>
            <Input
              disabled={filterMode === defaultMode || filterMode == null}
              value={text}
              placeholder="Enter the text here"
              onChange={(e) => setText(e.target.value)}
            />
          </Col>
          <Col xl={7}>
            <Select
              size="large"
              style={{ width: '100%' }}
              placeholder="Please select"
              defaultValue={defaultMode}
              value={filterMode}
              onChange={updateFilterMode}
            >
              {filterModeArray?.map((item) => (
                <Option key={item.key} value={item.key}>
                  {item.value}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Select
          disabled={filterMode === defaultMode || filterMode == null}
          size="large"
          mode="tags"
          style={{ width: '100%' }}
          allowClear
          placeholder="Please select"
          defaultValue={values}
          onDeselect={deselect}
          onSelect={select}
          value={values}
        >
          {values?.map((val) => (
            <Option key={val} value={val}>
              {val}
            </Option>
          ))}
        </Select>
      </Space>
    </Col>
  )
}
