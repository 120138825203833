import { Card, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { accountApi } from '../../../api/account'
import { AccountBean } from '../../../api/base/api'
import { AccountForm } from './AccountForm'

interface Props {
  id?: number
}

export const AccountFormContainer: React.FC<Props> = ({ id }) => {
  const [isFormLoading, setIsFormLoading] = useState(Boolean(id))
  const [initialBean, setInitialBean] = useState<Partial<AccountBean>>({})

  useEffect(() => {
    if (id) {
      accountApi
        .view(id)
        .then((data) => setInitialBean(data.data as AccountBean))
        .then(() => setIsFormLoading(false))
    }
  }, [])

  return (
    <Card title={id ? initialBean.username : 'Create new account'} bordered={false}>
      {isFormLoading ? <Spin size="large" /> : <AccountForm type={id ? 'edit' : 'create'} initialBean={initialBean} />}
    </Card>
  )
}
