import { Row, Space } from 'antd'
import React, { useState } from 'react'
import { SspBean as SspBeanNamespace, SspBean } from '../../../api/base/api'
import { dspApi } from '../../../api/dsp'
import { FormTextInput, FormCheckbox, DspFormMultipleSelect } from '../../../components/form'
import { FormBidTypeSelect } from '../../../components/form/inputs/FormBidTypeSelect'
import { FormNumberInput } from '../../../components/form/inputs/FormNumberInput'
import { ListFilters, useGetList } from '../../../components/list'

interface Props {
  bean: Partial<SspBean>
  updateBean: (bean: Partial<SspBean>) => void
}

export const SspCreateEditForm: React.FC<Props> = ({ bean, updateBean }) => {
  const [filters] = useState<ListFilters>({} as ListFilters)
  const { list } = useGetList(() => dspApi.listAll(filters), filters)

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row gutter={24}>
        <FormTextInput
          xs={12}
          value={bean.name}
          onChange={(name) => updateBean({ name })}
          label="Name"
          placeholder="Enter name"
        />
      </Row>

      <Row gutter={24}>
        <FormTextInput
          xs={12}
          value={bean.endpoint}
          onChange={(endpoint) => updateBean({ endpoint })}
          label="Endpoint"
          placeholder="Enter endpoint"
        />
      </Row>

      <Row gutter={24}>
        <FormCheckbox xs={12} checked={bean.enable} onChange={(enable) => updateBean({ enable })} label="Enabled" />
      </Row>

      <Row gutter={24}>
        <DspFormMultipleSelect
          xs={12}
          value={bean.dspIds}
          options={list.map((dspBean) => dspBean)}
          onChange={(dspIds) => updateBean({ dspIds })}
          label="DSP list"
        />
      </Row>

      <Row gutter={24}>
        <FormNumberInput
          xs={12}
          value={bean.maxQps}
          min={0}
          onChange={(maxQps) => updateBean({ maxQps })}
          label="Max QPS"
        />
      </Row>

      <Row gutter={24}>
        <FormBidTypeSelect
          xs={12}
          value={bean.bidType}
          onChange={(bidType) => updateBean({ bidType })}
          options={[SspBeanNamespace.BidTypeEnum.NET, SspBeanNamespace.BidTypeEnum.GROSS]}
          label="Bid type"
        />
      </Row>

      <Row gutter={24}>
        <FormNumberInput
          xs={12}
          value={bean.revShare}
          max={1}
          min={0}
          onChange={(revShare) => updateBean({ revShare })}
          label="Revenue share"
        />
      </Row>
    </Space>
  )
}
