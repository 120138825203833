import { Space } from 'antd'
import React from 'react'
import {
  DspBean,
  DspBean as DspBeanNamespace,
  OSTypeBean,
  SspBean as SspBeanNamespace,
  SspBean,
} from '../../../api/base/api'
import { iabCategoryApi } from '../../../api/iabCategory'
import { iabFixedSizeApi } from '../../../api/iabFixedSize'
import { useListFilters, useGetList } from '../../../components/list'
import { CountryFilter } from '../../../components/traffic-filter/CountryFilter'
import { ListInputFilter } from '../../../components/traffic-filter/ListInputFilter'
import { OsAndVersionFilter } from '../../../components/traffic-filter/OsAndVersionFilter'
import { SspTrafficTypeFilter } from '../../../components/traffic-filter/SspTrafficTypeFilter'
import { OptionsFilter } from '../../../components/traffic-filter/OptionsFilter'
import { browserApi } from '../../../api/browser'

interface Props {
  bean: Partial<SspBean>
  updateBean: (bean: Partial<SspBean>) => void
}

export const SspTrafficFilters: React.FC<Props> = ({ bean, updateBean }) => {
  const iabCategoryFilters = useListFilters('iabCategory').filters
  const iabCategoryList = useGetList(() => iabCategoryApi.list(iabCategoryFilters), iabCategoryFilters).list
  const iabFixedSizeFilters = useListFilters('iabFixedSize').filters
  const iabFixedSizeList = useGetList(() => iabFixedSizeApi.list(iabFixedSizeFilters), iabFixedSizeFilters).list
  const browserFilters = useListFilters('browser').filters
  const browserList = useGetList(() => browserApi.list(browserFilters), browserFilters).list

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <ListInputFilter
        label="Publisher list"
        values={(bean.publisherList as string[]) || []}
        updateValues={(values: string[]) => {
          bean.publisherList = values
          updateBean(bean)
        }}
        defaultMode={SspBeanNamespace.PublisherFilterModeEnum[SspBeanNamespace.PublisherFilterModeEnum.INCLUDEALL]}
        filterMode={
          SspBeanNamespace.PublisherFilterModeEnum[bean.publisherFilterMode as SspBeanNamespace.PublisherFilterModeEnum]
        }
        updateFilterMode={(filterMode: string) => {
          bean.publisherFilterMode = SspBean.PublisherFilterModeEnum[filterMode]
          updateBean(bean)
        }}
      />

      <br />

      <ListInputFilter
        label="Domain list"
        values={(bean.domainList as string[]) || []}
        updateValues={(values: string[]) => {
          bean.domainList = values
          updateBean(bean)
        }}
        defaultMode={SspBeanNamespace.DomainFilterModeEnum[SspBeanNamespace.DomainFilterModeEnum.INCLUDEALL]}
        filterMode={SspBean.DomainFilterModeEnum[bean.domainFilterMode as SspBean.DomainFilterModeEnum]}
        updateFilterMode={(filterMode: string) => {
          bean.domainFilterMode = SspBean.DomainFilterModeEnum[filterMode]
          updateBean(bean)
        }}
      />

      <br />

      <CountryFilter
        countries={(bean.countryIds as number[]) || []}
        updateCountries={(countryIds) => {
          bean.countryIds = countryIds.map((item) => Number(item))
          updateBean(bean)
        }}
        defaultMode={SspBeanNamespace.CountryFilterModeEnum[SspBeanNamespace.CountryFilterModeEnum.INCLUDEALL]}
        filterMode={SspBean.CountryFilterModeEnum[bean.countryFilterMode as SspBean.CountryFilterModeEnum]}
        updateFilterMode={(filterMode: string) => {
          bean.countryFilterMode = SspBean.CountryFilterModeEnum[filterMode]
          updateBean(bean)
        }}
      />

      <br />

      <ListInputFilter
        label="IP list"
        values={(bean.ipList as string[]) || []}
        updateValues={(values: string[]) => {
          bean.ipList = values
          updateBean(bean)
        }}
        defaultMode={SspBeanNamespace.IpFilterModeEnum[SspBeanNamespace.IpFilterModeEnum.INCLUDEALL]}
        filterMode={SspBean.IpFilterModeEnum[bean.ipFilterMode as SspBean.IpFilterModeEnum]}
        updateFilterMode={(filterMode: string) => {
          bean.ipFilterMode = SspBean.IpFilterModeEnum[filterMode]
          updateBean(bean)
        }}
      />

      <br />

      <ListInputFilter
        label="App ID list"
        values={(bean.appIdList as string[]) || []}
        updateValues={(values: string[]) => {
          bean.appIdList = values
          updateBean(bean)
        }}
        defaultMode={SspBeanNamespace.AppIdFilterModeEnum[SspBeanNamespace.AppIdFilterModeEnum.INCLUDEALL]}
        filterMode={SspBean.AppIdFilterModeEnum[bean.appIdFilterMode as SspBean.AppIdFilterModeEnum]}
        updateFilterMode={(filterMode: string) => {
          bean.appIdFilterMode = SspBean.AppIdFilterModeEnum[filterMode]
          updateBean(bean)
        }}
      />

      <br />

      <OptionsFilter
        values={(bean.browserIds as number[]) || []}
        updateValues={(values: number[]) => {
          bean.browserIds = values
          updateBean(bean)
        }}
        label="Browser list"
        defaultMode={DspBeanNamespace.BrowserFilterModeEnum[DspBeanNamespace.BrowserFilterModeEnum.INCLUDEALL]}
        filterMode={DspBean.BrowserFilterModeEnum[bean.browserFilterMode as DspBeanNamespace.BrowserFilterModeEnum]}
        updateFilterMode={(filterMode: string) => {
          bean.browserFilterMode = DspBeanNamespace.BrowserFilterModeEnum[filterMode]
          updateBean(bean)
        }}
        optionList={browserList}
        formatter={(item) => `${item.name}`}
      />

      <br />

      <OptionsFilter
        values={(bean.iabCategoryIds as number[]) || []}
        updateValues={(values: number[]) => {
          bean.iabCategoryIds = values
          updateBean(bean)
        }}
        label="IAB categories"
        defaultMode={DspBeanNamespace.IabCategoryFilterModeEnum[DspBeanNamespace.IabCategoryFilterModeEnum.INCLUDEALL]}
        filterMode={
          DspBean.IabCategoryFilterModeEnum[bean.iabCategoryFilterMode as DspBeanNamespace.IabCategoryFilterModeEnum]
        }
        updateFilterMode={(filterMode) => {
          bean.iabCategoryFilterMode = DspBeanNamespace.IabCategoryFilterModeEnum[filterMode]
          updateBean(bean)
        }}
        optionList={iabCategoryList}
        formatter={(item) => `${item.name} (${item.code})`}
      />

      <br />

      <OptionsFilter
        values={(bean.iabFixedSizeIds as number[]) || []}
        updateValues={(values: number[]) => {
          bean.iabFixedSizeIds = values
          updateBean(bean)
        }}
        label="IAB fixed sizes"
        defaultMode={
          DspBeanNamespace.IabFixedSizeFilterModeEnum[DspBeanNamespace.IabFixedSizeFilterModeEnum.INCLUDEALL]
        }
        filterMode={
          DspBean.IabFixedSizeFilterModeEnum[bean.iabFixedSizeFilterMode as DspBeanNamespace.IabFixedSizeFilterModeEnum]
        }
        updateFilterMode={(filterMode) => {
          bean.iabFixedSizeFilterMode = DspBeanNamespace.IabFixedSizeFilterModeEnum[filterMode]
          updateBean(bean)
        }}
        optionList={iabFixedSizeList}
        formatter={(item) => `${item.adUnitName} (${item.width}x${item.height})`}
      />

      <br />

      <SspTrafficTypeFilter bean={bean as SspBean} updateBean={updateBean} />

      <br />

      <OsAndVersionFilter
        values={(bean.osTypes as OSTypeBean[]) || []}
        updateValues={(values) => {
          bean.osTypes = values
          updateBean(bean)
        }}
      />

      <br />
    </Space>
  )
}
