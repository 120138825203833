import { Col, Form, Select } from 'antd'
import { ColSize } from 'antd/lib/grid'
import React from 'react'

const { Option } = Select

interface Props {
  xs: ColSize | number | string
  value?: string
  options?: string[]
  onChange: (value: string, data?: any) => void
  label?: string
  placeholder?: string
  disabled?: boolean
}

export const FormTypeSelect: React.FC<Props> = ({ xs, value, options, onChange, label, placeholder, disabled }) => (
  <Col xs={xs}>
    <div className="ant-form-vertical">
      <Form.Item label={label}>
        <Select
          value={value}
          onChange={(e, data) => onChange(e, data)}
          placeholder={placeholder}
          disabled={disabled}
          filterOption={(inp, opt) => {
            if (opt !== undefined) {
              if (opt.children != null) return opt.children.toString().includes(inp)
            }
            return false
          }}
        >
          {options?.map((val) => (
            <Option key={val} value={val}>
              {val}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  </Col>
)
