import React from 'react'
import { Card, Col, Row } from 'antd'
import { Moment } from 'moment'
import { DataSelect } from './DateSelect'
import { ChartWithSelect } from './ChartWithSelect'
import { DashboardState } from '../Dashboard'
import { SizeChartBean } from '../../../api/base/api'

interface Props {
  dataSelectState: Partial<DashboardState>
  dataSelectRefreshState: (
    startDate: Moment,
    endDate: Moment,
    button: 'None' | 'Range' | 'Today' | 'Yesterday' | 'Last 7 Days' | 'Last 30 Days' | 'This Month' | 'Last Month'
  ) => void
  countryData: SizeChartBean | undefined
  browserData: SizeChartBean | undefined
  bannerIabSizeData: SizeChartBean | undefined
  videoIabSizeData: SizeChartBean | undefined
}

export const BidRequests: React.FC<Props> = ({
  dataSelectState,
  dataSelectRefreshState,
  countryData,
  browserData,
  bannerIabSizeData,
  videoIabSizeData,
}) => (
  <Col xs={24}>
    <Card bordered={false} headStyle={{ fontWeight: '600', fontSize: '20px', border: 'none' }}>
      <DataSelect state={dataSelectState} refreshState={dataSelectRefreshState} />
    </Card>

    <Row justify="space-between" gutter={4}>
      <Col xs={24} sm={24} md={12}>
        <ChartWithSelect title="Bid requests by Device Type" data={dataSelectState.deviceTypeData} />
      </Col>

      <Col xs={24} sm={24} md={12}>
        <ChartWithSelect title="Bid requests by OS" data={dataSelectState.osData} />
      </Col>
    </Row>

    <Row justify="space-between" gutter={4}>
      <Col xs={24} sm={24} md={12}>
        <ChartWithSelect title="Bid requests by Country" data={countryData} />
      </Col>

      <Col xs={24} sm={24} md={12}>
        <ChartWithSelect title="Bid requests by Browser" data={browserData} />
      </Col>
    </Row>

    <Row justify="space-between" gutter={4}>
      <Col xs={24} sm={24} md={12}>
        <ChartWithSelect title="Iab Sizes (Banner)" data={bannerIabSizeData} />
      </Col>

      <Col xs={24} sm={24} md={12}>
        <ChartWithSelect title="Iab Sizes (Video)" data={videoIabSizeData} />
      </Col>
    </Row>
  </Col>
)
