import React, { useEffect, useState } from 'react'
import { Layout, Row, Col } from 'antd'
import { buildInfoApi } from '../../api/buildInfo'

export const Footer: React.FC = () => {
  const [uiBuildInfo, setUiBuildInfo] = useState([] as any)
  const [exchangeBuildInfo, setExchangeBuildInfo] = useState([] as any)

  useEffect(() => {
    buildInfoApi.uiBuildInfo().then((response) => setUiBuildInfo(response))
    buildInfoApi
      .exchangeBuildInfo()
      .then((response) => response.json())
      .then((response) => setExchangeBuildInfo(response))
  }, [])

  return (
    <Layout.Footer>
      <Row className="just" align="bottom">
        <Col xs={24} md={12} lg={12}>
          <table className="copyright">
            <tbody>
              <tr>
                <td style={{ padding: '0 30px 0 0' }}>{`Exchange version: ${exchangeBuildInfo['build.version']}`}</td>
                <td>{`build: ${new Date(exchangeBuildInfo['build.time']).toUTCString()}`}</td>
              </tr>
              <tr>
                <td>{`UI version: ${uiBuildInfo['build.version']}`}</td>
                <td>{`build: ${new Date(uiBuildInfo['build.time']).toUTCString()}`}</td>
              </tr>
            </tbody>
          </table>
          <div className="copyright">2022</div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <div className="footer-menu">
            <ul>
              <li className="nav-item">
                <a href="/" className="nav-link text-muted" target="_self" rel="noreferrer">
                  Go to main page
                </a>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Layout.Footer>
  )
}
