import { Row, Col, Card, Descriptions, Checkbox } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { accountApi } from '../../../api/account'
import { AccountBean } from '../../../api/base/api'

import {
  List,
  ListButtons,
  ListCreateButton,
  ListDeleteButton,
  ListEditButton,
  useGetList,
  useGetListItem,
  useListFilters,
} from '../../../components/list'
import { getCreatePath, getEditPath, getListPath, routes } from '../../../routes'
import { roleToStringMap } from '../../../shared/enum/RoleEnum'

interface Props {
  selectedId?: number
}

export const AccountList: React.FC<Props> = ({ selectedId }) => {
  const history = useHistory()
  const { filters, setFilter } = useListFilters('account')

  const { list, refreshList, pagination } = useGetList(() => accountApi.list(filters), filters)
  const selectedItem = useGetListItem((id) => accountApi.view(id), selectedId)

  return (
    <Row gutter={[16, 16]}>
      <Col md={24} lg={14}>
        <Card
          title="Accounts"
          bordered={false}
          className="criclebox tablespace"
          extra={<ListCreateButton path={getCreatePath(routes.account)} page="AccountList" />}
        >
          <List
            list={list}
            headers={['id', 'name', 'email', 'role']}
            filters={[
              {
                name: 'id',
                value: filters.id,
                onSearch: (value) => setFilter('id', value),
              },
              {
                name: 'name',
                value: filters.name,
                onSearch: (value) => setFilter('name', value),
              },
              {
                name: 'email',
                value: filters.email,
                onSearch: (value) => setFilter('email', value),
              },
            ]}
            renderRowCells={(account) => [
              account.id,
              account.username,
              account.email,
              roleToStringMap.get(account.role as AccountBean.RoleEnum),
            ]}
            selectedRow={selectedItem}
            onSelect={(id) => history.push(getListPath(routes.account, id))}
            page={filters.page}
            setPage={(page) => setFilter('page', page)}
            totalPages={pagination.totalPages ?? 0}
          />
        </Card>
      </Col>

      {selectedItem && (
        <Col md={24} lg={10}>
          <Card
            bordered={false}
            title="Account info"
            extra={
              <ListButtons>
                <ListEditButton path={getEditPath(routes.account, selectedItem.id)} page="AccountList" />
                <ListDeleteButton
                  deleteItem={() => accountApi.delete(selectedItem.id as number)}
                  refreshList={refreshList}
                  redirectAfter={() => history.push(getListPath(routes.account))}
                  page="AccountList"
                />
              </ListButtons>
            }
          >
            <Descriptions>
              <Descriptions.Item label="Id" span={3}>
                {selectedItem.id}
              </Descriptions.Item>
              <Descriptions.Item label="Username" span={3}>
                {selectedItem.username}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                {selectedItem.email}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions layout="horizontal">
              <Descriptions.Item label="Create DSP" span={1}>
                <Checkbox checked={selectedItem.canCreateDsp} />
              </Descriptions.Item>
              <Descriptions.Item label="Edit DSP" span={1}>
                <Checkbox checked={selectedItem.canEditDsp} />
              </Descriptions.Item>
              <Descriptions.Item label="Delete DSP" span={1}>
                <Checkbox checked={selectedItem.canDeleteDsp} />
              </Descriptions.Item>
              <Descriptions.Item label="Create SSP" span={1}>
                <Checkbox checked={selectedItem.canCreateSsp} />
              </Descriptions.Item>
              <Descriptions.Item label="Edit SSP" span={1}>
                <Checkbox checked={selectedItem.canEditSsp} />
              </Descriptions.Item>
              <Descriptions.Item label="Delete SSP" span={1}>
                <Checkbox checked={selectedItem.canDeleteSsp} />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      )}
    </Row>
  )
}
