import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Tabs, Button } from 'antd'
import moment, { Moment } from 'moment'
import { ReloadOutlined } from '@ant-design/icons'
import { useFormData } from '../../components/list'
import { dashboardApi } from '../../api/dashboard'
import { DailyStatsRow, DailyValuesBean, SizeChartBean, TopItem } from '../../api/base/api'
import { DailyStats } from './components/DailyStats'
import { BidRequests } from './components/BidRequests'
import { TrafficTypes } from './components/TrafficTypes'

export interface DashboardState {
  startDate: Moment
  endDate: Moment
  deviceTypeData: SizeChartBean | undefined
  osData: SizeChartBean | undefined
  button: 'None' | 'Range' | 'Today' | 'Yesterday' | 'Last 7 Days' | 'Last 30 Days' | 'This Month' | 'Last Month'
}

export const Dashboard: React.FC = () => {
  const [state, updateState] = useFormData<DashboardState>({
    startDate: moment(),
    endDate: moment(),
    deviceTypeData: undefined,
    osData: undefined,
    button: 'None',
  })

  const [countryData, setCountryData] = useState<SizeChartBean | undefined>(undefined)
  const [browserData, setBrowserData] = useState<SizeChartBean | undefined>(undefined)
  const [adTypeData, setAdTypeData] = useState<SizeChartBean | undefined>(undefined)
  const [bannerIabSizeData, setBannerIabSizeData] = useState<SizeChartBean | undefined>(undefined)
  const [videoIabSizeData, setVideoIabSizeData] = useState<SizeChartBean | undefined>(undefined)
  const [dailyValues, setDailyValues] = useState<DailyValuesBean | undefined>(undefined)

  const [topSspList, setTopSspList] = useState<TopItem[]>([])
  const [topDspList, setTopDspList] = useState<TopItem[]>([])
  const [topDomainList, setTopDomainList] = useState<TopItem[]>([])

  const [dailyStatsRows, setDailyStatsRows] = useState<DailyStatsRow[]>([])

  const refreshState = (
    startDate: Moment,
    endDate: Moment,
    button: 'None' | 'Range' | 'Today' | 'Yesterday' | 'Last 7 Days' | 'Last 30 Days' | 'This Month' | 'Last Month'
  ) => {
    Promise.all([
      dashboardApi.deviceTypeChart(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')),
      dashboardApi.osChart(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')),
    ]).then((resultArray) => {
      updateState({ startDate, endDate, deviceTypeData: resultArray[0], osData: resultArray[1], button })

      dashboardApi.dailyValues(moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')).then((res) => {
        setDailyValues(res)
      })

      dashboardApi
        .dailyStats(moment().subtract(6, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
        .then((res) => {
          setDailyStatsRows(res.stats as DailyStatsRow[])
        })

      dashboardApi
        .topLists(moment().subtract(6, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
        .then((res) => {
          setTopSspList(res.ssps as TopItem[])
          setTopDspList(res.dsps as TopItem[])
          setTopDomainList(res.domains as TopItem[])
        })

      dashboardApi.countryChart(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')).then((res) => {
        setCountryData(res)
      })

      dashboardApi.browserChart(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')).then((res) => {
        setBrowserData(res)
      })

      dashboardApi.adTypeChart(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')).then((res) => {
        setAdTypeData(res)
      })

      dashboardApi.bannerIabSize(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')).then((res) => {
        setBannerIabSizeData(res)
      })

      dashboardApi.videoIabSize(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')).then((res) => {
        setVideoIabSizeData(res)
      })
    })
  }

  useEffect(() => refreshState(moment(), moment(), 'Today'), [])

  const items = [
    {
      label: 'Daily stats',
      key: 'item-daily-stats',
      children: (
        <DailyStats
          dailyValues={dailyValues}
          topSspList={topSspList}
          topDspList={topDspList}
          topDomainList={topDomainList}
          dailyStatsRows={dailyStatsRows}
        />
      ),
    },
    {
      label: 'Traffic breakdown',
      key: 'item-bid-requests',
      children: (
        <BidRequests
          dataSelectState={state}
          dataSelectRefreshState={refreshState}
          countryData={countryData}
          browserData={browserData}
          bannerIabSizeData={bannerIabSizeData}
          videoIabSizeData={videoIabSizeData}
        />
      ),
    },
    {
      label: 'Traffic types',
      key: 'item-traffic-types',
      children: <TrafficTypes dataSelectState={state} dataSelectRefreshState={refreshState} adTypeData={adTypeData} />,
    },
  ]

  return (
    <Row gutter={[4, 4]}>
      <Col xs={24}>
        <Card title="Dashboard" bordered={false} headStyle={{ fontWeight: '600', fontSize: '20px', border: 'none' }}>
          <Tabs
            items={items}
            tabBarExtraContent={
              <Button
                type="text"
                style={{ boxShadow: '0 0 0' }}
                onClick={() => {
                  refreshState(state.startDate, state.endDate, state.button)
                }}
              >
                <ReloadOutlined style={{ fontSize: '16px', padding: '0 0 0 4px' }} />
              </Button>
            }
          />
        </Card>
      </Col>
    </Row>
  )
}
