import { Checkbox, Col, Input, Row, Space, Typography } from 'antd'
import React from 'react'
import { OSTypeBean } from '../../api/base/api'

const { Text } = Typography

interface Props {
  values: OSTypeBean[]
  updateValues: (values: OSTypeBean[]) => void
}

export const OsAndVersionFilter: React.FC<Props> = ({ values, updateValues }) => {
  const onChange = (osName, minVersion, maxVersion) => {
    switch (osName) {
      case 'ios': {
        const ios = values.find((item) => item.name === 'ios')
        if (ios) {
          ios.minVersion = minVersion === undefined ? ios.minVersion : minVersion
          ios.maxVersion = maxVersion === undefined ? ios.maxVersion : maxVersion
        } else {
          values.push({ name: 'ios', minVersion, maxVersion })
        }
        break
      }
      case 'android': {
        const android = values.find((item) => item.name === 'android')
        if (android) {
          android.minVersion = minVersion === undefined ? android.minVersion : minVersion
          android.maxVersion = maxVersion === undefined ? android.maxVersion : maxVersion
        } else {
          values.push({ name: 'android', minVersion, maxVersion })
        }
        break
      }
    }
    updateValues(values)
  }

  return (
    <Col xs={12}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Text>OS type and version</Text>

        <Row justify="space-between">
          <Col xs={5}>
            <Text strong>OS</Text>
          </Col>
          <Col xs={8}>
            <Text strong>Minimum Version</Text>
          </Col>
          <Col xs={8}>
            <Text strong>Maximum Version</Text>
          </Col>
        </Row>

        <Row justify="space-between" align="middle">
          <Col xs={5}>
            <Checkbox
              checked={values.find((item) => item.name === 'ios') !== undefined}
              onChange={() => {
                const index = values.findIndex((item) => item.name === 'ios')
                if (index < 0) {
                  values.push({ name: 'ios', minVersion: undefined, maxVersion: undefined })
                } else {
                  values.splice(index, 1)
                }
                updateValues(values)
              }}
            >
              iOS
            </Checkbox>
          </Col>

          <Col xs={8}>
            <Input
              style={{ width: '100%' }}
              value={values.find((item) => item.name === 'ios')?.minVersion}
              onChange={(minVersion) =>
                onChange('ios', minVersion.target.value, values.find((item) => item.name === 'ios')?.maxVersion)
              }
              placeholder="Enter the min version here"
            />
          </Col>

          <Col xs={8}>
            <Input
              style={{ width: '100%' }}
              value={values.find((item) => item.name === 'ios')?.maxVersion}
              onChange={(maxVersion) =>
                onChange('ios', values.find((item) => item.name === 'ios')?.minVersion, maxVersion.target.value)
              }
              placeholder="Enter the max version here"
            />
          </Col>
        </Row>

        <Row justify="space-between" align="middle">
          <Col xs={5}>
            <Checkbox
              checked={values.find((item) => item.name === 'android') !== undefined}
              onChange={() => {
                const index = values.findIndex((item) => item.name === 'android')
                if (index < 0) {
                  values.push({ name: 'android', minVersion: undefined, maxVersion: undefined })
                } else {
                  values.splice(index, 1)
                }
                updateValues(values)
              }}
            >
              Android
            </Checkbox>
          </Col>

          <Col xs={8}>
            <Input
              style={{ width: '100%' }}
              value={values.find((item) => item.name === 'android')?.minVersion}
              onChange={(minVersion) =>
                onChange('android', minVersion.target.value, values.find((item) => item.name === 'android')?.maxVersion)
              }
              placeholder="Enter the min version here"
            />
          </Col>

          <Col xs={8}>
            <Input
              style={{ width: '100%' }}
              value={values.find((item) => item.name === 'android')?.maxVersion}
              onChange={(maxVersion) =>
                onChange('android', values.find((item) => item.name === 'android')?.minVersion, maxVersion.target.value)
              }
              placeholder="Enter the max version here"
            />
          </Col>
        </Row>
      </Space>
    </Col>
  )
}
