import { IabFixedSizesControllerApi } from './base/api'
import { configuration } from './utils/apiConfiguration'
import { customFetch } from './utils/customFetch'
import { IAB_FIXED_SIZE_LIST_SIZE, ListFilters } from '../components/list'

class IabFixedSizeApi {
  api = new IabFixedSizesControllerApi(configuration, configuration.basePath, customFetch)

  list(filter: ListFilters) {
    return this.api.iabFixedSizeList(IAB_FIXED_SIZE_LIST_SIZE, filter.page - 1)
  }
}

export const iabFixedSizeApi = new IabFixedSizeApi()
