import { Col, Row, Select, Typography } from 'antd'
import React from 'react'
import { filterModeArray } from './utils/FilterModeEnum'
import { listDeviceTypeArray } from './utils/OpenRtbDeviceTypeEnum'

const { Text } = Typography
const { Option } = Select

interface Props {
  label: string
  defaultMode: string
  filterMode: string
  updateFilterMode: (filterMode: string) => void
  deviceTypeList: string[]
  updatedeviceTypeList: (deviceTypeList: string[]) => void
}

export const TrafficTypeFilter: React.FC<Props> = ({
  label,
  defaultMode,
  filterMode,
  updateFilterMode,
  deviceTypeList,
  updatedeviceTypeList,
}) => (
  <Row justify="space-between" align="middle">
    <Col xs={5}>
      <Text>{label}</Text>
    </Col>

    <Col xs={7}>
      <Select
        size="large"
        style={{ width: '100%' }}
        placeholder="Please select"
        defaultValue={defaultMode}
        value={filterMode}
        onChange={updateFilterMode}
      >
        {filterModeArray?.map((item) => (
          <Option key={item.key} value={item.key}>
            {item.value}
          </Option>
        ))}
      </Select>
    </Col>

    <Col xs={11}>
      <Select
        disabled={filterMode === defaultMode || filterMode == null}
        size="large"
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        placeholder="Please select"
        defaultValue={deviceTypeList}
        onChange={updatedeviceTypeList}
      >
        {listDeviceTypeArray?.map((item) => (
          <Option key={item.key} value={item.key}>
            {item.value}
          </Option>
        ))}
      </Select>
    </Col>
  </Row>
)
