import { Col, Form, Select } from 'antd'
import { ColSize } from 'antd/lib/grid'
import React from 'react'
import { DspBean } from '../../../api/base/api'

const { Option } = Select

interface Props {
  xs: ColSize | number | string
  value?: DspBean.BidTypeEnum
  options?: DspBean.BidTypeEnum[]
  onChange: (value: DspBean.BidTypeEnum) => void
  label?: string
  placeholder?: DspBean.BidTypeEnum
  disabled?: boolean
}

export const FormBidTypeSelect: React.FC<Props> = ({ xs, value, options, onChange, label, placeholder, disabled }) => (
  <Col xs={xs}>
    <div className="ant-form-vertical">
      <Form.Item label={label}>
        <Select value={value} onChange={(e) => onChange(e)} placeholder={placeholder} disabled={disabled}>
          {options?.map((val) => (
            <Option key={val} value={val}>
              {val}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  </Col>
)
