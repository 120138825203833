import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { StatisticsFormContainer } from './StatisticsFormContainer'

export const StatisticsRoot: React.FC = () => (
  <Switch>
    <Route path="/statistics/edit/:id" render={(rp) => <StatisticsFormContainer id={+rp.match.params.id} />} />
    <Route path="/statistics" component={StatisticsFormContainer} />
  </Switch>
)
