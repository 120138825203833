import { Descriptions } from 'antd'
import React, { useState } from 'react'
import { SspBean } from '../../../api/base/api'
import { ListFilters, useGetList } from '../../../components/list'
import { dspApi } from '../../../api/dsp'

interface Props {
  selectedItem: SspBean
}

export const SspInfoMain: React.FC<Props> = ({ selectedItem }) => {
  const [filters] = useState<ListFilters>({} as ListFilters)
  const { list, isLoading } = useGetList(() => dspApi.listAll(filters), filters)

  return (
    <Descriptions>
      <Descriptions.Item label="Id" span={3}>
        {selectedItem.id}
      </Descriptions.Item>
      <Descriptions.Item label="Name" span={3}>
        {selectedItem.name}
      </Descriptions.Item>
      <Descriptions.Item label="Endpoint" span={3}>
        {selectedItem.endpoint}
      </Descriptions.Item>
      <Descriptions.Item label="Enabled" span={3}>
        {`${selectedItem.enable}`}
      </Descriptions.Item>
      {selectedItem.maxQps && (
        <Descriptions.Item label="Max QPS" span={3}>
          {`${selectedItem.maxQps}`}
        </Descriptions.Item>
      )}
      <Descriptions.Item label="Bid type" span={3}>
        {`${selectedItem.bidType}`}
      </Descriptions.Item>
      <Descriptions.Item label="Revenue share" span={3}>
        {`${selectedItem.revShare}`}
      </Descriptions.Item>
      <Descriptions.Item label="DSPs" span={3}>
        {`${
          selectedItem.dspIds && selectedItem.dspIds.length > 0
            ? isLoading
              ? 'loading...'
              : selectedItem.dspIds
                  ?.map((el) => list.find((item) => item.id === el))
                  .map((el) => el?.name)
                  .reduce((acc, el) => `${acc}, ${el}`)
            : ''
        }`}
      </Descriptions.Item>
    </Descriptions>
  )
}
