import React, { useState } from 'react'
import { Button, Col, Row } from 'antd'
import { ListFilters, useFormData, useGetList } from '../../../components/list'
import { sspApi } from '../../../api/ssp'
import { FormSelect } from '../../../components/form/inputs/FormSelect'
import { FormTextArea } from '../../../components/form/inputs/FormTextArea'
import { FormSubmitButton, FormTextInput } from '../../../components/form'
import { bidderApi } from '../../../api/bidder'
import { bidderConfiguration } from '../../../api/utils/bidderConfiguration'
import { FormNumberInput } from '../../../components/form/inputs/FormNumberInput'
import { FormTypeSelect } from '../../../components/form/inputs/FormTypeSelect'
import { DspBean as DspBeanNamespace } from '../../../api/base/api'

const bidRequest = require('./bidRequest.json')
const nativeImp = require('./native.json')
const nonNativeImp = require('./nonNative.json')

// interface BiddingForm {
//   url: string;
//   body: string;
//   responseHeaders: string;
//   responseBody: string;
//   responseStatus: string;
//   activeTab: string;
//   adCode: string;
//   vast: boolean;
//   width: number;
//   height: number;
//   type: string;
//   os: string;
//   osv: string;
//   country: string;
//   countriesAvailable: any;
//   debug: boolean;
//   ssp: string;
//   sspAvailable: any;
// }

const activeStyle = {
  borderColor: '#40a9ff',
  color: '#40a9ff',
}

interface Props {
  setStatus: (string) => void
  setBody: (string) => void
  setBanner: (string) => void
}

export const TestPageForm: React.FC<Props> = ({ setStatus, setBody, setBanner }) => {
  const bidUrl = `${bidderConfiguration.basePath}/rtb/bid/trace`

  const [formData, updateForm] = useFormData({
    sspId: 0,
    url: bidUrl,
    country: 'CAN',
    body: JSON.stringify(bidRequest, null, 2),
    type: 'banner',
    width: 300,
    height: 250,
    customInput: false,
    response: '',
  })

  const [filters] = useState<ListFilters>({} as ListFilters)
  const { list } = useGetList(
    () =>
      sspApi.listAll(filters).then((result) => {
        const id = result.data?.at(0)?.data?.id
        updateForm({ sspId: id, url: `${bidUrl}/${id}` })
        return result
      }),
    filters
  )

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={12}>
        <FormTextInput
          xs={24}
          value={formData.url}
          onChange={(url) => {
            updateForm({ url })
          }}
          label="URL"
        />

        <FormSelect
          xs={24}
          value={formData.sspId}
          options={list}
          onChange={(sspId) => {
            updateForm({
              sspId,
              url: `${bidUrl}/${sspId}`,
            })
          }}
          label="SSP"
        />

        <FormTypeSelect
          xs={24}
          onChange={(type) => {
            const body = JSON.parse(formData.body)
            let typeValue
            if (type === 'native') {
              typeValue = nativeImp
            } else typeValue = nonNativeImp
            typeValue.w = formData.width
            typeValue.h = formData.height
            delete body.imp[0][formData.type]
            body.imp[0][type] = typeValue
            updateForm({ body: JSON.stringify(body, null, 2), type })
          }}
          value={formData.type}
          options={['video', 'banner', 'native']}
          label="Type"
        />

        <Col>
          <Row gutter={8}>
            <FormNumberInput
              xs={4}
              value={formData.width}
              onChange={(width) => {
                const body = JSON.parse(formData.body)
                body.imp[0][formData.type].w = width
                updateForm({ body: JSON.stringify(body, null, 2), width })
              }}
              label="Width"
            />
            <FormNumberInput
              xs={4}
              value={formData.height}
              onChange={(height) => {
                const body = JSON.parse(formData.body)
                body.imp[0][formData.type].h = height
                updateForm({ body: JSON.stringify(body, null, 2), height })
              }}
              label="Height"
            />
          </Row>
        </Col>

        <FormTextInput
          xs={24}
          value={formData.country}
          onChange={(country) => {
            const body = JSON.parse(formData.body)
            body.device.geo.country = country
            updateForm({ body: JSON.stringify(body, null, 2), country })
          }}
          label="Country"
        />

        <Col>
          <Row>
            <Col style={{ marginRight: '8px' }}>
              <Button
                style={formData.customInput ? activeStyle : {}}
                onClick={() => updateForm({ customInput: !formData.customInput })}
              >
                {' '}
                Custom{' '}
              </Button>
            </Col>
            <FormSubmitButton
              text="Send"
              onClick={() =>
                bidderApi
                  .bid(formData.url, formData.body)
                  .then((response) => {
                    setStatus(`${response.status} ${response.statusText}`)
                    setBody('')
                    setBanner('')
                    return response.text()
                  })
                  .then(async (body) => {
                    setBody(JSON.stringify(JSON.parse(body), null, 2))
                    const bidList = JSON.parse(body).seatbid[0].bid as Array<any>
                    if (bidList.length > 0) {
                      const bid = bidList[0]
                      const ssp = list.find((ssp) => ssp.id === formData.sspId)
                      if (ssp !== undefined) {
                        setBanner(
                          bid.adm.replace(
                            '${AUCTION_PRICE}',
                            ssp.bidType === DspBeanNamespace.BidTypeEnum.NET
                              ? bid.price
                              : bid.price * (1 - ssp.revShare)
                          )
                        )
                        await bidderApi.win(
                          encodeURI(
                            `${bid.nurl}`.replace(
                              '${AUCTION_PRICE}',
                              ssp.bidType === DspBeanNamespace.BidTypeEnum.NET
                                ? bid.price
                                : bid.price * (1 - ssp.revShare)
                            )
                          )
                        )
                      }
                      return bid
                    }
                  })
              }
            />
          </Row>
        </Col>
      </Col>

      <Col xs={24} sm={12}>
        <FormTextArea
          xs={24}
          rows={20}
          value={`${formData.body}`}
          onChange={(body) => {
            updateForm({ body })
          }}
          disabled={!formData.customInput}
          label="Source"
        />
      </Col>
    </Row>
  )
}
