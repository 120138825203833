import { AccountBean } from '../api/base/api'

class AuthorizationStore {
  private storageKey = 'access-token'

  private accountStorageKey = 'account-bean'

  public token: string | null = null

  public account: AccountBean

  private subscribers: (() => void)[] = []

  constructor() {
    this.token = JSON.parse(localStorage.getItem(this.storageKey) ?? 'null')
    this.account = JSON.parse(localStorage.getItem(this.accountStorageKey) ?? 'null')
  }

  saveAccountProps = (accountBean) => {
    this.account = accountBean
    this.storeInfo()
    this.notifySubscribers()
  }

  signIn = (token: string) => {
    this.token = token
    this.storeInfo()
    this.notifySubscribers()
  }

  signOut = () => {
    this.token = null
    this.storeInfo()
    this.notifySubscribers()
  }

  private storeInfo() {
    localStorage.setItem(this.storageKey, JSON.stringify(this.token))
    localStorage.setItem(this.accountStorageKey, JSON.stringify(this.account))
  }

  subscribe = (cb: () => void) => {
    this.subscribers.push(cb)
  }

  unsubscribe = (cb: () => void) => {
    this.subscribers = this.subscribers.filter((subscriber) => subscriber !== cb)
  }

  private notifySubscribers = () => {
    this.subscribers.forEach((cb) => cb())
  }
}

export const authorizationStore = new AuthorizationStore()
