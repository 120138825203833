import { Col, Row, Select, Space, Typography } from 'antd'
import React, { useState, useEffect } from 'react'
import { countryApi } from '../../api/country'
import { CountryBean } from '../../api/base/api'
import { filterModeArray } from './utils/FilterModeEnum'

const { Text } = Typography
const { Option } = Select

interface Props {
  countries: number[]
  updateCountries: (countries: string[]) => void
  defaultMode: string
  filterMode: string
  updateFilterMode: (filterMode: string) => void
}

export const CountryFilter: React.FC<Props> = ({
  countries,
  updateCountries,
  defaultMode,
  filterMode,
  updateFilterMode,
}) => {
  const [countriesForBean, setCountriesForBean] = useState([] as CountryBean[])
  const [countriesList, setCountriesList] = useState([] as CountryBean[])

  useEffect(() => {
    if (countriesList.length === 0) {
      countryApi.list().then((response) => {
        const uploaded = response.data?.map(({ data }) => data as CountryBean) || []
        setCountriesList(uploaded)
        setCountriesForBean(uploaded.filter((item) => item.id && countries.includes(item.id)))
      })
    }
  })

  useEffect(() => {
    const uploaded = countriesList.filter((item) => item.id && countries.includes(item.id))
    setCountriesForBean(uploaded)
  }, [countries])

  return (
    <Col xs={12}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Text>Countries</Text>

        <Row justify="end">
          <Col xs={7}>
            <Select
              size="large"
              style={{ width: '100%' }}
              placeholder="Please select"
              defaultValue={defaultMode}
              value={filterMode}
              onChange={updateFilterMode}
            >
              {filterModeArray?.map((item) => (
                <Option key={item.key} value={item.key}>
                  {item.value}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Select
          disabled={filterMode === defaultMode || filterMode == null}
          size="large"
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Please select"
          value={countriesForBean.map((item) => item.name) as string[]}
          onChange={(name, option) => updateCountries(option.map((item) => item.key))}
        >
          {countriesList?.map((item) => (
            <Option key={item.id} value={item.name}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Space>
    </Col>
  )
}
