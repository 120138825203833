import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { getCreatePath, getEditPath, getListPath, routes } from '../../routes'
import { SspList } from './list/SspList'
import { SspFormContainer } from './form/SspFormContainer'

export const SspRoot: React.FC = () => (
  <Switch>
    <Route
      path={`${getListPath(routes.ssp)}/:id?`}
      render={(rp) => <SspList selectedId={+(rp.match.params.id ?? '')} />}
    />
    <Route path={`${getCreatePath(routes.ssp)}`} component={SspFormContainer} />
    <Route path={`${getEditPath(routes.ssp)}/:id`} render={(rp) => <SspFormContainer id={+rp.match.params.id} />} />
    <Redirect to={getListPath(routes.ssp)} />
  </Switch>
)
