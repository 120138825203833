import React from 'react'
import { Card, Descriptions } from 'antd'
import { DailyValuesBean } from '../../../api/base/api'
import { numberRenderer } from './NumberRenderer'

interface Props {
  data: DailyValuesBean | undefined
}

export const NumericValues: React.FC<Props> = ({ data }) => (
  <Card>
    <Descriptions column={{ xs: 1, sm: 3, md: 4 }}>
      <Descriptions.Item label="Today Request">{numberRenderer(data?.todayRequest)}</Descriptions.Item>
      <Descriptions.Item label="Today Impressions">{numberRenderer(data?.todayImpressions)}</Descriptions.Item>
      <Descriptions.Item label="Estimated Revenue">{numberRenderer(data?.estimatedRevenue)}</Descriptions.Item>
      <Descriptions.Item label="Net Revenue">{numberRenderer(data?.netRevenue)}</Descriptions.Item>
    </Descriptions>
  </Card>
)
