import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { AccountRoot } from './pages/account'
import { SspRoot } from './pages/ssp'
import { DspRoot } from './pages/dsp'
import { Dashboard } from './pages/dashboard/Dashboard'
import { SignIn } from './pages/signIn/SignIn'
import { SignOut } from './pages/signOut/SignOut'

import { Main } from './components/layout/Main'
import { useAuthorization } from './utils/useAuthorization'
import { routes } from './routes'

import 'antd/dist/antd.min.css'
import './style.css'
import { TestPageRoot } from './pages/testPage'
import { StatisticsRoot } from './pages/statistics'
import { SavedReportQueryRoot } from './pages/savedQuery'
import { StatisticsFormContainer } from './pages/statistics/StatisticsFormContainer'

export const App: React.FC = () => {
  const isAuthorized = useAuthorization()

  return isAuthorized ? (
    <Main>
      <Switch>
        <Route path={routes.account} component={AccountRoot} />
        <Route path={routes.ssp} component={SspRoot} />
        <Route path={routes.dsp} component={DspRoot} />
        <Route path={routes.dashboard} component={Dashboard} />
        <Route path={routes.signOut} component={SignOut} />
        <Route path={routes.testRequest} component={TestPageRoot} />
        <Route path={routes.statistics} component={StatisticsRoot} />
        <Route path={`${routes.favorite}/:id`} render={(rp) => <StatisticsFormContainer id={+rp.match.params.id} />} />
        <Route path={routes.savedQuery} component={SavedReportQueryRoot} />
        <Redirect from="/" to={routes.dashboard} />
      </Switch>
    </Main>
  ) : (
    <Switch>
      <Route path={routes.signIn} component={SignIn} />
      <Redirect from="/" to={routes.signIn} />
    </Switch>
  )
}
