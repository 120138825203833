import React, { useEffect, useState } from 'react'
import { Card, Spin } from 'antd'
import { DspBean } from '../../../api/base/api'
import { dspApi } from '../../../api/dsp'
import { DspForm } from './DspForm'

interface Props {
  id?: number
}

const tabList = [
  {
    key: 'Main',
    tab: 'Main',
  },
  {
    key: 'TrafficFilters',
    tab: 'Traffic Filters',
  },
]

export const DspFormContainer: React.FC<Props> = ({ id }) => {
  const [isFormLoading, setIsFormLoading] = useState(Boolean(id))
  const [initialBean, setInitialBean] = useState<Partial<DspBean>>({
    enable: false,
    osTypes: [{ name: 'ios' }, { name: 'android' }],
  })
  const [activeTab, setActiveTab] = useState<'Main' | 'TrafficFilters'>('Main')

  useEffect(() => {
    if (id) {
      dspApi
        .view(id)
        .then((data) => setInitialBean(data.data as DspBean))
        .then(() => setIsFormLoading(false))
    }
  }, [])

  return (
    <Card
      title={id ? initialBean.name : 'Create new dsp'}
      bordered={false}
      tabList={tabList}
      onTabChange={(tab) => setActiveTab(tab as 'Main' | 'TrafficFilters')}
    >
      {isFormLoading ? (
        <Spin size="large" />
      ) : (
        <DspForm type={id ? 'edit' : 'create'} initialBean={initialBean} activeTab={activeTab} />
      )}
    </Card>
  )
}
