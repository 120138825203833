import React, { useEffect, useState } from 'react'
import { Button, Modal, Space, Switch } from 'antd'
import { QueryState } from '../StatisticsFormContainer'
import { savedQueryApi } from '../../../api/savedQuery'
import { globalMessageStore } from '../../../utils/globalMessageStore'
import { FormTextInput } from '../../../components/form/inputs/FormTextInput'
import { useSavedQueries } from '../../../utils/useSavedQueries'

interface Props {
  queryState: Partial<QueryState>
  updateQueryState: (QueryState) => void
}

export const SaveQueryComponent: React.FC<Props> = ({ queryState, updateQueryState }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [name, setName] = useState(queryState.name as string)
  const [isFavorite, setIsFavorite] = useState(queryState.favorite as boolean)
  const { refreshSavedQueries } = useSavedQueries()

  useEffect(() => {
    setName(queryState.name as string)
    setIsFavorite(queryState.favorite as boolean)
  }, [queryState])

  const saveAs = () => {
    setIsModalOpen(false)
    if (name === '') {
      return
    }

    if (queryState.id && queryState.name === name) {
      savedQueryApi
        .edit({
          id: queryState.id,
          name,
          favorite: isFavorite,
          body: JSON.stringify(queryState.query),
        })
        .then((response) => {
          updateQueryState({
            id: response.data?.id,
            name: response.data?.name,
            favorite: response.data?.favorite,
            query: JSON.parse(response.data?.body as string),
          })
          globalMessageStore.addSuccess('Query changed successfully')
          if (isFavorite) {
            refreshSavedQueries()
          }
        })
    } else {
      savedQueryApi
        .create({
          name,
          favorite: isFavorite,
          body: JSON.stringify(queryState.query),
        })
        .then((response) => {
          updateQueryState({
            id: response.data?.id,
            name: response.data?.name,
            favorite: response.data?.favorite,
            query: JSON.parse(response.data?.body as string),
          })
          globalMessageStore.addSuccess('Query saved successfully')
          if (isFavorite) {
            refreshSavedQueries()
          }
        })
    }
  }

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>Save Query</Button>
      <Modal
        title="Are you sure?"
        open={isModalOpen}
        onOk={saveAs}
        onCancel={() => setIsModalOpen(false)}
        okText="Save"
      >
        <Space direction="vertical">
          <FormTextInput xs={24} value={name} onChange={setName} label="Query Name" placeholder="Enter query name" />
          <Switch
            checkedChildren="Favorite"
            unCheckedChildren="Not a favorite"
            checked={isFavorite}
            onChange={setIsFavorite}
          />
        </Space>
      </Modal>
    </>
  )
}
