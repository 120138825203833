class BidderApi {
  bid(url: string, body: any) {
    return fetch(url, {
      body,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  win(url: string) {
    return fetch(url, {
      method: 'GET',
    })
  }
}

export const bidderApi = new BidderApi()
