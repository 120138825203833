/* eslint-disable */  
/// <reference path="./custom.d.ts" />
// tslint:disable
// @ts-nocheck
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "http://localhost:8080".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 *
 * @export
 * @interface AccountBean
 */
export interface AccountBean {
    /**
     *
     * @type {number}
     * @memberof AccountBean
     */
    id?: number;
    /**
     * Account username
     * @type {string}
     * @memberof AccountBean
     */
    username: string;
    /**
     * Account email
     * @type {string}
     * @memberof AccountBean
     */
    email: string;
    /**
     * Account password
     * @type {string}
     * @memberof AccountBean
     */
    password?: string;
    /**
     * Account access role
     * @type {string}
     * @memberof AccountBean
     */
    role: AccountBean.RoleEnum;
    /**
     * List of DSPs
     * @type {Array<number>}
     * @memberof AccountBean
     */
    dspIds?: Array<number>;
    /**
     * List of SSPs
     * @type {Array<number>}
     * @memberof AccountBean
     */
    sspIds?: Array<number>;
    /**
     * Сan account create dsp?
     * @type {boolean}
     * @memberof AccountBean
     */
    canCreateDsp?: boolean;
    /**
     * Сan account edit dsp?
     * @type {boolean}
     * @memberof AccountBean
     */
    canEditDsp?: boolean;
    /**
     * Сan account delete dsp?
     * @type {boolean}
     * @memberof AccountBean
     */
    canDeleteDsp?: boolean;
    /**
     * Сan account create ssp?
     * @type {boolean}
     * @memberof AccountBean
     */
    canCreateSsp?: boolean;
    /**
     * Сan account edit ssp?
     * @type {boolean}
     * @memberof AccountBean
     */
    canEditSsp?: boolean;
    /**
     * Сan account delete ssp?
     * @type {boolean}
     * @memberof AccountBean
     */
    canDeleteSsp?: boolean;
}

/**
 * @export
 * @namespace AccountBean
 */
export namespace AccountBean {
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Admin = <any> 'admin',
        AccountManager = <any> 'account_manager',
        DspUser = <any> 'dsp_user',
        SspUser = <any> 'ssp_user'
    }
}
/**
 *
 * @export
 * @interface BrowserBean
 */
export interface BrowserBean {
    /**
     *
     * @type {number}
     * @memberof BrowserBean
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof BrowserBean
     */
    name?: string;
}
/**
 *
 * @export
 * @interface ChartData
 */
export interface ChartData {
    /**
     *
     * @type {string}
     * @memberof ChartData
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ChartData
     */
    xvalue?: string;
    /**
     *
     * @type {number}
     * @memberof ChartData
     */
    yvalue?: number;
}
/**
 *
 * @export
 * @interface CountryBean
 */
export interface CountryBean {
    /**
     *
     * @type {number}
     * @memberof CountryBean
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof CountryBean
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof CountryBean
     */
    iso3: string;
}
/**
 *
 * @export
 * @interface DailyStatsBean
 */
export interface DailyStatsBean {
    /**
     *
     * @type {Array<DailyStatsRow>}
     * @memberof DailyStatsBean
     */
    stats?: Array<DailyStatsRow>;
}
/**
 *
 * @export
 * @interface DailyStatsRow
 */
export interface DailyStatsRow {
    /**
     *
     * @type {string}
     * @memberof DailyStatsRow
     */
    date?: string;
    /**
     *
     * @type {number}
     * @memberof DailyStatsRow
     */
    sspRequest?: number;
    /**
     *
     * @type {number}
     * @memberof DailyStatsRow
     */
    sspImpressionWins?: number;
    /**
     *
     * @type {number}
     * @memberof DailyStatsRow
     */
    sspGrossImpression?: number;
    /**
     *
     * @type {number}
     * @memberof DailyStatsRow
     */
    sspNetImpression?: number;
    /**
     *
     * @type {number}
     * @memberof DailyStatsRow
     */
    sspEstimatedRevenue?: number;
    /**
     *
     * @type {number}
     * @memberof DailyStatsRow
     */
    sspNetRevenue?: number;
    /**
     *
     * @type {number}
     * @memberof DailyStatsRow
     */
    profit?: number;
}
/**
 *
 * @export
 * @interface DailyValuesBean
 */
export interface DailyValuesBean {
    /**
     *
     * @type {number}
     * @memberof DailyValuesBean
     */
    todayRequest?: number;
    /**
     *
     * @type {number}
     * @memberof DailyValuesBean
     */
    todayImpressions?: number;
    /**
     *
     * @type {number}
     * @memberof DailyValuesBean
     */
    estimatedRevenue?: number;
    /**
     *
     * @type {number}
     * @memberof DailyValuesBean
     */
    netRevenue?: number;
}
/**
 *
 * @export
 * @interface DashboardRequestBean
 */
export interface DashboardRequestBean {
    /**
     * Request start date
     * @type {string}
     * @memberof DashboardRequestBean
     */
    startDate: string;
    /**
     * Request end date including
     * @type {string}
     * @memberof DashboardRequestBean
     */
    endDate: string;
}
/**
 *
 * @export
 * @interface DspBean
 */
export interface DspBean {
    /**
     *
     * @type {number}
     * @memberof DspBean
     */
    id?: number;
    /**
     * Dsp name
     * @type {string}
     * @memberof DspBean
     */
    name: string;
    /**
     * Dsp endpoint url
     * @type {string}
     * @memberof DspBean
     */
    endpoint: string;
    /**
     * Enable flag
     * @type {boolean}
     * @memberof DspBean
     */
    enable: boolean;
    /**
     * Max QPS
     * @type {number}
     * @memberof DspBean
     */
    maxQps?: number;
    /**
     * Bid type
     * @type {string}
     * @memberof DspBean
     */
    bidType: DspBean.BidTypeEnum;
    /**
     * Revenue share
     * @type {number}
     * @memberof DspBean
     */
    revShare: number;
    /**
     *
     * @type {string}
     * @memberof DspBean
     */
    syncLink?: string;
    /**
     *
     * @type {string}
     * @memberof DspBean
     */
    syncMacro?: string;
    /**
     *
     * @type {boolean}
     * @memberof DspBean
     */
    emptyMacro?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof DspBean
     */
    vastUnwrapping?: boolean;
    /**
     *
     * @type {Array<number>}
     * @memberof DspBean
     */
    countryIds?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof DspBean
     */
    countryFilterMode?: DspBean.CountryFilterModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof DspBean
     */
    publisherList?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof DspBean
     */
    publisherFilterMode?: DspBean.PublisherFilterModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof DspBean
     */
    domainList?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof DspBean
     */
    domainFilterMode?: DspBean.DomainFilterModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof DspBean
     */
    ipList?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof DspBean
     */
    ipFilterMode?: DspBean.IpFilterModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof DspBean
     */
    appIdList?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof DspBean
     */
    appIdFilterMode?: DspBean.AppIdFilterModeEnum;
    /**
     *
     * @type {Array<number>}
     * @memberof DspBean
     */
    browserIds?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof DspBean
     */
    browserFilterMode?: DspBean.BrowserFilterModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof DspBean
     */
    deviceList?: Array<DspBean.DeviceListEnum>;
    /**
     *
     * @type {string}
     * @memberof DspBean
     */
    deviceFilterMode?: DspBean.DeviceFilterModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof DspBean
     */
    nativeDeviceTypes?: Array<DspBean.NativeDeviceTypesEnum>;
    /**
     *
     * @type {string}
     * @memberof DspBean
     */
    nativeDeviceTypesMode?: DspBean.NativeDeviceTypesModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof DspBean
     */
    videoDeviceTypes?: Array<DspBean.VideoDeviceTypesEnum>;
    /**
     *
     * @type {string}
     * @memberof DspBean
     */
    videoDeviceTypesMode?: DspBean.VideoDeviceTypesModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof DspBean
     */
    audioDeviceTypes?: Array<DspBean.AudioDeviceTypesEnum>;
    /**
     *
     * @type {string}
     * @memberof DspBean
     */
    audioDeviceTypesMode?: DspBean.AudioDeviceTypesModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof DspBean
     */
    bannerDeviceTypes?: Array<DspBean.BannerDeviceTypesEnum>;
    /**
     *
     * @type {string}
     * @memberof DspBean
     */
    bannerDeviceTypesMode?: DspBean.BannerDeviceTypesModeEnum;
    /**
     * IAB category filter mode
     * @type {string}
     * @memberof DspBean
     */
    iabCategoryFilterMode: DspBean.IabCategoryFilterModeEnum;
    /**
     * IAB categories
     * @type {Array<number>}
     * @memberof DspBean
     */
    iabCategoryIds?: Array<number>;
    /**
     * IAB fixed size filter mode
     * @type {string}
     * @memberof DspBean
     */
    iabFixedSizeFilterMode: DspBean.IabFixedSizeFilterModeEnum;
    /**
     * IAB fixed sizes
     * @type {Array<number>}
     * @memberof DspBean
     */
    iabFixedSizeIds?: Array<number>;
    /**
     *
     * @type {Array<OSTypeBean>}
     * @memberof DspBean
     */
    osTypes?: Array<OSTypeBean>;
    /**
     *
     * @type {number}
     * @memberof DspBean
     */
    minBidFloor?: number;
    /**
     *
     * @type {number}
     * @memberof DspBean
     */
    maxBidFloor?: number;
}

/**
 * @export
 * @namespace DspBean
 */
export namespace DspBean {
    /**
     * @export
     * @enum {string}
     */
    export enum BidTypeEnum {
        GROSS = <any> 'GROSS',
        NET = <any> 'NET'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum CountryFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PublisherFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DomainFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum IpFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AppIdFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BrowserFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceListEnum {
        Mobile = <any> 'mobile',
        PC = <any> 'PC',
        ConnectedTV = <any> 'connectedTV',
        Phone = <any> 'phone',
        Tablet = <any> 'Tablet',
        ConnectedDevice = <any> 'connectedDevice',
        SetTopBox = <any> 'setTopBox'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum NativeDeviceTypesEnum {
        Mobile = <any> 'mobile',
        PC = <any> 'PC',
        ConnectedTV = <any> 'connectedTV',
        Phone = <any> 'phone',
        Tablet = <any> 'Tablet',
        ConnectedDevice = <any> 'connectedDevice',
        SetTopBox = <any> 'setTopBox'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum NativeDeviceTypesModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum VideoDeviceTypesEnum {
        Mobile = <any> 'mobile',
        PC = <any> 'PC',
        ConnectedTV = <any> 'connectedTV',
        Phone = <any> 'phone',
        Tablet = <any> 'Tablet',
        ConnectedDevice = <any> 'connectedDevice',
        SetTopBox = <any> 'setTopBox'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum VideoDeviceTypesModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AudioDeviceTypesEnum {
        Mobile = <any> 'mobile',
        PC = <any> 'PC',
        ConnectedTV = <any> 'connectedTV',
        Phone = <any> 'phone',
        Tablet = <any> 'Tablet',
        ConnectedDevice = <any> 'connectedDevice',
        SetTopBox = <any> 'setTopBox'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AudioDeviceTypesModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BannerDeviceTypesEnum {
        Mobile = <any> 'mobile',
        PC = <any> 'PC',
        ConnectedTV = <any> 'connectedTV',
        Phone = <any> 'phone',
        Tablet = <any> 'Tablet',
        ConnectedDevice = <any> 'connectedDevice',
        SetTopBox = <any> 'setTopBox'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BannerDeviceTypesModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum IabCategoryFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum IabFixedSizeFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
}
/**
 *
 * @export
 * @interface FilterEntry
 */
export interface FilterEntry {
    /**
     *
     * @type {Array<string>}
     * @memberof FilterEntry
     */
    keys?: Array<FilterEntry.KeysEnum>;
    /**
     *
     * @type {StatsFilter}
     * @memberof FilterEntry
     */
    filter?: StatsFilter;
}

/**
 * @export
 * @namespace FilterEntry
 */
export namespace FilterEntry {
    /**
     * @export
     * @enum {string}
     */
    export enum KeysEnum {
        Ssp = <any> 'ssp',
        Dsp = <any> 'dsp',
        DspName = <any> 'dspName',
        DspimprpriceSum = <any> 'dspimprprice_sum',
        SspName = <any> 'sspName',
        SsptimeCount = <any> 'ssptime_count',
        SspimprpriceCount = <any> 'sspimprprice_count',
        SspimprpriceSum = <any> 'sspimprprice_sum',
        Audio = <any> 'audio',
        Banner = <any> 'banner',
        Video = <any> 'video',
        Nnative = <any> 'nnative',
        DeviceType = <any> 'deviceType',
        ImpW = <any> 'impW',
        ImpH = <any> 'impH',
        BidW = <any> 'bidW',
        BidH = <any> 'bidH',
        Country = <any> 'country',
        Domain = <any> 'domain',
        Day = <any> 'day',
        Time = <any> 'time',
        Hour = <any> 'hour',
        Date = <any> 'date',
        Os = <any> 'os',
        Browser = <any> 'browser',
        IabSize = <any> 'iabSize',
        Revenue = <any> 'revenue',
        Cost = <any> 'cost'
    }
}
/**
 *
 * @export
 * @interface IabCategoryBean
 */
export interface IabCategoryBean {
    /**
     *
     * @type {number}
     * @memberof IabCategoryBean
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof IabCategoryBean
     */
    parentId?: number;
    /**
     *
     * @type {string}
     * @memberof IabCategoryBean
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof IabCategoryBean
     */
    code?: string;
}
/**
 *
 * @export
 * @interface IabFixedSizeBean
 */
export interface IabFixedSizeBean {
    /**
     *
     * @type {number}
     * @memberof IabFixedSizeBean
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof IabFixedSizeBean
     */
    adUnitName?: string;
    /**
     *
     * @type {number}
     * @memberof IabFixedSizeBean
     */
    width?: number;
    /**
     *
     * @type {number}
     * @memberof IabFixedSizeBean
     */
    height?: number;
}
/**
 *
 * @export
 * @interface ItemResponseAccountBeanObject
 */
export interface ItemResponseAccountBeanObject {
    /**
     *
     * @type {AccountBean}
     * @memberof ItemResponseAccountBeanObject
     */
    data?: AccountBean;
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseAccountBeanObject
     */
    permissions?: { [key: string]: boolean; };
}
/**
 *
 * @export
 * @interface ItemResponseBrowserBeanObject
 */
export interface ItemResponseBrowserBeanObject {
    /**
     *
     * @type {BrowserBean}
     * @memberof ItemResponseBrowserBeanObject
     */
    data?: BrowserBean;
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseBrowserBeanObject
     */
    permissions?: { [key: string]: boolean; };
}
/**
 *
 * @export
 * @interface ItemResponseCountryBeanObject
 */
export interface ItemResponseCountryBeanObject {
    /**
     *
     * @type {CountryBean}
     * @memberof ItemResponseCountryBeanObject
     */
    data?: CountryBean;
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseCountryBeanObject
     */
    permissions?: { [key: string]: boolean; };
}
/**
 *
 * @export
 * @interface ItemResponseDspBeanObject
 */
export interface ItemResponseDspBeanObject {
    /**
     *
     * @type {DspBean}
     * @memberof ItemResponseDspBeanObject
     */
    data?: DspBean;
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseDspBeanObject
     */
    permissions?: { [key: string]: boolean; };
}
/**
 *
 * @export
 * @interface ItemResponseIabCategoryBeanObject
 */
export interface ItemResponseIabCategoryBeanObject {
    /**
     *
     * @type {IabCategoryBean}
     * @memberof ItemResponseIabCategoryBeanObject
     */
    data?: IabCategoryBean;
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseIabCategoryBeanObject
     */
    permissions?: { [key: string]: boolean; };
}
/**
 *
 * @export
 * @interface ItemResponseIabFixedSizeBeanObject
 */
export interface ItemResponseIabFixedSizeBeanObject {
    /**
     *
     * @type {IabFixedSizeBean}
     * @memberof ItemResponseIabFixedSizeBeanObject
     */
    data?: IabFixedSizeBean;
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseIabFixedSizeBeanObject
     */
    permissions?: { [key: string]: boolean; };
}
/**
 *
 * @export
 * @interface ItemResponseSavedQueryBeanObject
 */
export interface ItemResponseSavedQueryBeanObject {
    /**
     *
     * @type {SavedQueryBean}
     * @memberof ItemResponseSavedQueryBeanObject
     */
    data?: SavedQueryBean;
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseSavedQueryBeanObject
     */
    permissions?: { [key: string]: boolean; };
}
/**
 *
 * @export
 * @interface ItemResponseSspBeanObject
 */
export interface ItemResponseSspBeanObject {
    /**
     *
     * @type {SspBean}
     * @memberof ItemResponseSspBeanObject
     */
    data?: SspBean;
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof ItemResponseSspBeanObject
     */
    permissions?: { [key: string]: boolean; };
}
/**
 *
 * @export
 * @interface ListPagination
 */
export interface ListPagination {
    /**
     *
     * @type {number}
     * @memberof ListPagination
     */
    size?: number;
    /**
     *
     * @type {number}
     * @memberof ListPagination
     */
    currentPage?: number;
    /**
     *
     * @type {number}
     * @memberof ListPagination
     */
    totalPages?: number;
    /**
     *
     * @type {number}
     * @memberof ListPagination
     */
    total?: number;
}
/**
 *
 * @export
 * @interface ListResponseAccountBean
 */
export interface ListResponseAccountBean {
    /**
     *
     * @type {Array<ItemResponseAccountBeanObject>}
     * @memberof ListResponseAccountBean
     */
    data?: Array<ItemResponseAccountBeanObject>;
    /**
     *
     * @type {ListPagination}
     * @memberof ListResponseAccountBean
     */
    pagination?: ListPagination;
}
/**
 *
 * @export
 * @interface ListResponseBrowserBean
 */
export interface ListResponseBrowserBean {
    /**
     *
     * @type {Array<ItemResponseBrowserBeanObject>}
     * @memberof ListResponseBrowserBean
     */
    data?: Array<ItemResponseBrowserBeanObject>;
    /**
     *
     * @type {ListPagination}
     * @memberof ListResponseBrowserBean
     */
    pagination?: ListPagination;
}
/**
 *
 * @export
 * @interface ListResponseCountryBean
 */
export interface ListResponseCountryBean {
    /**
     *
     * @type {Array<ItemResponseCountryBeanObject>}
     * @memberof ListResponseCountryBean
     */
    data?: Array<ItemResponseCountryBeanObject>;
    /**
     *
     * @type {ListPagination}
     * @memberof ListResponseCountryBean
     */
    pagination?: ListPagination;
}
/**
 *
 * @export
 * @interface ListResponseDspBean
 */
export interface ListResponseDspBean {
    /**
     *
     * @type {Array<ItemResponseDspBeanObject>}
     * @memberof ListResponseDspBean
     */
    data?: Array<ItemResponseDspBeanObject>;
    /**
     *
     * @type {ListPagination}
     * @memberof ListResponseDspBean
     */
    pagination?: ListPagination;
}
/**
 *
 * @export
 * @interface ListResponseIabCategoryBean
 */
export interface ListResponseIabCategoryBean {
    /**
     *
     * @type {Array<ItemResponseIabCategoryBeanObject>}
     * @memberof ListResponseIabCategoryBean
     */
    data?: Array<ItemResponseIabCategoryBeanObject>;
    /**
     *
     * @type {ListPagination}
     * @memberof ListResponseIabCategoryBean
     */
    pagination?: ListPagination;
}
/**
 *
 * @export
 * @interface ListResponseIabFixedSizeBean
 */
export interface ListResponseIabFixedSizeBean {
    /**
     *
     * @type {Array<ItemResponseIabFixedSizeBeanObject>}
     * @memberof ListResponseIabFixedSizeBean
     */
    data?: Array<ItemResponseIabFixedSizeBeanObject>;
    /**
     *
     * @type {ListPagination}
     * @memberof ListResponseIabFixedSizeBean
     */
    pagination?: ListPagination;
}
/**
 *
 * @export
 * @interface ListResponseSavedQueryBean
 */
export interface ListResponseSavedQueryBean {
    /**
     *
     * @type {Array<ItemResponseSavedQueryBeanObject>}
     * @memberof ListResponseSavedQueryBean
     */
    data?: Array<ItemResponseSavedQueryBeanObject>;
    /**
     *
     * @type {ListPagination}
     * @memberof ListResponseSavedQueryBean
     */
    pagination?: ListPagination;
}
/**
 *
 * @export
 * @interface ListResponseSspBean
 */
export interface ListResponseSspBean {
    /**
     *
     * @type {Array<ItemResponseSspBeanObject>}
     * @memberof ListResponseSspBean
     */
    data?: Array<ItemResponseSspBeanObject>;
    /**
     *
     * @type {ListPagination}
     * @memberof ListResponseSspBean
     */
    pagination?: ListPagination;
}
/**
 *
 * @export
 * @interface OSTypeBean
 */
export interface OSTypeBean {
    /**
     *
     * @type {string}
     * @memberof OSTypeBean
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof OSTypeBean
     */
    minVersion?: string;
    /**
     *
     * @type {string}
     * @memberof OSTypeBean
     */
    maxVersion?: string;
}
/**
 *
 * @export
 * @interface RawStatRow
 */
export interface RawStatRow {
    /**
     *
     * @type {string}
     * @memberof RawStatRow
     */
    day?: string;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    hour?: number;
    /**
     *
     * @type {string}
     * @memberof RawStatRow
     */
    time?: string;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    impW?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    impH?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    bidW?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    bidH?: number;
    /**
     *
     * @type {boolean}
     * @memberof RawStatRow
     */
    audio?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RawStatRow
     */
    banner?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RawStatRow
     */
    video?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof RawStatRow
     */
    nnative?: boolean;
    /**
     *
     * @type {string}
     * @memberof RawStatRow
     */
    country?: string;
    /**
     *
     * @type {string}
     * @memberof RawStatRow
     */
    domain?: string;
    /**
     *
     * @type {string}
     * @memberof RawStatRow
     */
    reqId?: string;
    /**
     *
     * @type {string}
     * @memberof RawStatRow
     */
    bidId?: string;
    /**
     *
     * @type {string}
     * @memberof RawStatRow
     */
    os?: string;
    /**
     *
     * @type {string}
     * @memberof RawStatRow
     */
    browser?: string;
    /**
     *
     * @type {string}
     * @memberof RawStatRow
     */
    iabSize?: string;
    /**
     *
     * @type {string}
     * @memberof RawStatRow
     */
    date?: string;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dsp?: number;
    /**
     *
     * @type {string}
     * @memberof RawStatRow
     */
    dspName?: string;
    /**
     *
     * @type {string}
     * @memberof RawStatRow
     */
    deviceType?: string;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspTimeout?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspError?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspWinError?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspReq?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspBid?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspWin?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspImpr?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dsptimeSum?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dsptimeCount?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspbidfloorSum?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspbidfloorCount?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspbidpriceSum?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspbidpriceCount?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspwinpriceSum?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspwinpriceCount?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspimprpriceSum?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspimprpriceCount?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspWinRate?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspbidflooravg?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspbidpriceavg?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspwinpriceavg?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dspimprpriceavg?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    dsptimeavg?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    ssp?: number;
    /**
     *
     * @type {string}
     * @memberof RawStatRow
     */
    sspName?: string;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspReq?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspBid?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspWin?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspIgnoredWin?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspImpr?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspIgnoredImpr?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspbidfloorSum?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspbidfloorCount?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspbidpriceSum?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspbidpriceCount?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspwinpriceSum?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspwinpriceCount?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspimprpriceSum?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspimprpriceCount?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspbidflooravg?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspbidpriceavg?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspwinpriceavg?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspimprpriceavg?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    ssptimeavg?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    sspWinRate?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    ssptimeCount?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    cost?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    profit?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    revenue?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    externalImpressions?: number;
    /**
     *
     * @type {number}
     * @memberof RawStatRow
     */
    externalRevenue?: number;
}
/**
 *
 * @export
 * @interface SavedQueryBean
 */
export interface SavedQueryBean {
    /**
     *
     * @type {number}
     * @memberof SavedQueryBean
     */
    id?: number;
    /**
     * Saved query name
     * @type {string}
     * @memberof SavedQueryBean
     */
    name: string;
    /**
     * Saved query body
     * @type {string}
     * @memberof SavedQueryBean
     */
    body: string;
    /**
     * Favorite flag
     * @type {boolean}
     * @memberof SavedQueryBean
     */
    favorite: boolean;
}
/**
 *
 * @export
 * @interface SizeChartBean
 */
export interface SizeChartBean {
    /**
     *
     * @type {Array<ChartData>}
     * @memberof SizeChartBean
     */
    bidRequests?: Array<ChartData>;
    /**
     *
     * @type {Array<ChartData>}
     * @memberof SizeChartBean
     */
    impressions?: Array<ChartData>;
    /**
     *
     * @type {Array<ChartData>}
     * @memberof SizeChartBean
     */
    revenue?: Array<ChartData>;
    /**
     *
     * @type {string}
     * @memberof SizeChartBean
     */
    startDate?: string;
    /**
     *
     * @type {string}
     * @memberof SizeChartBean
     */
    endDate?: string;
}
/**
 *
 * @export
 * @interface SspBean
 */
export interface SspBean {
    /**
     *
     * @type {number}
     * @memberof SspBean
     */
    id?: number;
    /**
     * Ssp name
     * @type {string}
     * @memberof SspBean
     */
    name: string;
    /**
     * Ssp endpoint url
     * @type {string}
     * @memberof SspBean
     */
    endpoint: string;
    /**
     * Enable flag
     * @type {boolean}
     * @memberof SspBean
     */
    enable: boolean;
    /**
     * List of DSPs
     * @type {Array<number>}
     * @memberof SspBean
     */
    dspIds?: Array<number>;
    /**
     * Max QPS
     * @type {number}
     * @memberof SspBean
     */
    maxQps?: number;
    /**
     * Bid type
     * @type {string}
     * @memberof SspBean
     */
    bidType: SspBean.BidTypeEnum;
    /**
     * Revenue share
     * @type {number}
     * @memberof SspBean
     */
    revShare: number;
    /**
     *
     * @type {Array<number>}
     * @memberof SspBean
     */
    countryIds?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof SspBean
     */
    countryFilterMode?: SspBean.CountryFilterModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof SspBean
     */
    publisherList?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SspBean
     */
    publisherFilterMode?: SspBean.PublisherFilterModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof SspBean
     */
    domainList?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SspBean
     */
    domainFilterMode?: SspBean.DomainFilterModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof SspBean
     */
    ipList?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SspBean
     */
    ipFilterMode?: SspBean.IpFilterModeEnum;
    /**
     *
     * @type {Array<number>}
     * @memberof SspBean
     */
    browserIds?: Array<number>;
    /**
     *
     * @type {string}
     * @memberof SspBean
     */
    browserFilterMode?: SspBean.BrowserFilterModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof SspBean
     */
    appIdList?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SspBean
     */
    appIdFilterMode?: SspBean.AppIdFilterModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof SspBean
     */
    deviceList?: Array<SspBean.DeviceListEnum>;
    /**
     *
     * @type {string}
     * @memberof SspBean
     */
    deviceFilterMode?: SspBean.DeviceFilterModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof SspBean
     */
    nativeDeviceTypes?: Array<SspBean.NativeDeviceTypesEnum>;
    /**
     *
     * @type {string}
     * @memberof SspBean
     */
    nativeDeviceTypesMode?: SspBean.NativeDeviceTypesModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof SspBean
     */
    videoDeviceTypes?: Array<SspBean.VideoDeviceTypesEnum>;
    /**
     *
     * @type {string}
     * @memberof SspBean
     */
    videoDeviceTypesMode?: SspBean.VideoDeviceTypesModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof SspBean
     */
    audioDeviceTypes?: Array<SspBean.AudioDeviceTypesEnum>;
    /**
     *
     * @type {string}
     * @memberof SspBean
     */
    audioDeviceTypesMode?: SspBean.AudioDeviceTypesModeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof SspBean
     */
    bannerDeviceTypes?: Array<SspBean.BannerDeviceTypesEnum>;
    /**
     *
     * @type {string}
     * @memberof SspBean
     */
    bannerDeviceTypesMode?: SspBean.BannerDeviceTypesModeEnum;
    /**
     * IAB category filter mode
     * @type {string}
     * @memberof SspBean
     */
    iabCategoryFilterMode: SspBean.IabCategoryFilterModeEnum;
    /**
     * IAB categories
     * @type {Array<number>}
     * @memberof SspBean
     */
    iabCategoryIds?: Array<number>;
    /**
     * IAB fixed size filter mode
     * @type {string}
     * @memberof SspBean
     */
    iabFixedSizeFilterMode: SspBean.IabFixedSizeFilterModeEnum;
    /**
     * IAB fixed sizes
     * @type {Array<number>}
     * @memberof SspBean
     */
    iabFixedSizeIds?: Array<number>;
    /**
     *
     * @type {Array<OSTypeBean>}
     * @memberof SspBean
     */
    osTypes?: Array<OSTypeBean>;
}

/**
 * @export
 * @namespace SspBean
 */
export namespace SspBean {
    /**
     * @export
     * @enum {string}
     */
    export enum BidTypeEnum {
        GROSS = <any> 'GROSS',
        NET = <any> 'NET'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum CountryFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PublisherFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DomainFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum IpFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BrowserFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AppIdFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceListEnum {
        Mobile = <any> 'mobile',
        PC = <any> 'PC',
        ConnectedTV = <any> 'connectedTV',
        Phone = <any> 'phone',
        Tablet = <any> 'Tablet',
        ConnectedDevice = <any> 'connectedDevice',
        SetTopBox = <any> 'setTopBox'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum DeviceFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum NativeDeviceTypesEnum {
        Mobile = <any> 'mobile',
        PC = <any> 'PC',
        ConnectedTV = <any> 'connectedTV',
        Phone = <any> 'phone',
        Tablet = <any> 'Tablet',
        ConnectedDevice = <any> 'connectedDevice',
        SetTopBox = <any> 'setTopBox'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum NativeDeviceTypesModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum VideoDeviceTypesEnum {
        Mobile = <any> 'mobile',
        PC = <any> 'PC',
        ConnectedTV = <any> 'connectedTV',
        Phone = <any> 'phone',
        Tablet = <any> 'Tablet',
        ConnectedDevice = <any> 'connectedDevice',
        SetTopBox = <any> 'setTopBox'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum VideoDeviceTypesModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AudioDeviceTypesEnum {
        Mobile = <any> 'mobile',
        PC = <any> 'PC',
        ConnectedTV = <any> 'connectedTV',
        Phone = <any> 'phone',
        Tablet = <any> 'Tablet',
        ConnectedDevice = <any> 'connectedDevice',
        SetTopBox = <any> 'setTopBox'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum AudioDeviceTypesModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BannerDeviceTypesEnum {
        Mobile = <any> 'mobile',
        PC = <any> 'PC',
        ConnectedTV = <any> 'connectedTV',
        Phone = <any> 'phone',
        Tablet = <any> 'Tablet',
        ConnectedDevice = <any> 'connectedDevice',
        SetTopBox = <any> 'setTopBox'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BannerDeviceTypesModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum IabCategoryFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum IabFixedSizeFilterModeEnum {
        INCLUDE = <any> 'INCLUDE',
        EXCLUDE = <any> 'EXCLUDE',
        INCLUDEALL = <any> 'INCLUDE_ALL'
    }
}
/**
 *
 * @export
 * @interface StatRequestBean
 */
export interface StatRequestBean {
    /**
     * Columns for select
     * @type {Array<string>}
     * @memberof StatRequestBean
     */
    values: Array<StatRequestBean.ValuesEnum>;
    /**
     * Group by columns
     * @type {Array<string>}
     * @memberof StatRequestBean
     */
    keys: Array<StatRequestBean.KeysEnum>;
    /**
     *
     * @type {string}
     * @memberof StatRequestBean
     */
    startDate: string;
    /**
     *
     * @type {string}
     * @memberof StatRequestBean
     */
    endDate: string;
    /**
     *
     * @type {Array<FilterEntry>}
     * @memberof StatRequestBean
     */
    filters?: Array<FilterEntry>;
    /**
     *
     * @type {string}
     * @memberof StatRequestBean
     */
    orderBy?: string;
    /**
     *
     * @type {string}
     * @memberof StatRequestBean
     */
    order?: StatRequestBean.OrderEnum;
    /**
     *
     * @type {number}
     * @memberof StatRequestBean
     */
    limit?: number;
}

/**
 * @export
 * @namespace StatRequestBean
 */
export namespace StatRequestBean {
    /**
     * @export
     * @enum {string}
     */
    export enum ValuesEnum {
        DspReq = <any> 'dspReq',
        DspBid = <any> 'dspBid',
        DspWin = <any> 'dspWin',
        DspImpr = <any> 'dspImpr',
        DspTimeout = <any> 'dspTimeout',
        DspError = <any> 'dspError',
        DspWinError = <any> 'dspWinError',
        DsptimeSum = <any> 'dsptime_sum',
        DsptimeCount = <any> 'dsptime_count',
        DspbidfloorCount = <any> 'dspbidfloor_count',
        DspbidfloorSum = <any> 'dspbidfloor_sum',
        DspbidpriceCount = <any> 'dspbidprice_count',
        DspbidpriceSum = <any> 'dspbidprice_sum',
        DspwinpriceCount = <any> 'dspwinprice_count',
        DspwinpriceSum = <any> 'dspwinprice_sum',
        DspimprpriceCount = <any> 'dspimprprice_count',
        DspimprpriceSum = <any> 'dspimprprice_sum',
        Dspbidflooravg = <any> 'dspbidflooravg',
        Dspbidpriceavg = <any> 'dspbidpriceavg',
        Dspwinpriceavg = <any> 'dspwinpriceavg',
        Dspimprpriceavg = <any> 'dspimprpriceavg',
        Dsptimeavg = <any> 'dsptimeavg',
        DspWinRate = <any> 'dspWinRate',
        SspReq = <any> 'sspReq',
        SspBid = <any> 'sspBid',
        SspWin = <any> 'sspWin',
        SspImpr = <any> 'sspImpr',
        SspIgnoredWin = <any> 'sspIgnoredWin',
        SspIgnoredImpr = <any> 'sspIgnoredImpr',
        SsptimeSum = <any> 'ssptime_sum',
        SsptimeCount = <any> 'ssptime_count',
        SspbidfloorSum = <any> 'sspbidfloor_sum',
        SspbidfloorCount = <any> 'sspbidfloor_count',
        SspbidpriceSum = <any> 'sspbidprice_sum',
        SspbidpriceCount = <any> 'sspbidprice_count',
        SspwinpriceSum = <any> 'sspwinprice_sum',
        SspwinpriceCount = <any> 'sspwinprice_count',
        SspimprpriceSum = <any> 'sspimprprice_sum',
        SspimprpriceCount = <any> 'sspimprprice_count',
        Sspbidflooravg = <any> 'sspbidflooravg',
        Sspbidpriceavg = <any> 'sspbidpriceavg',
        Sspwinpriceavg = <any> 'sspwinpriceavg',
        Sspimprpriceavg = <any> 'sspimprpriceavg',
        Ssptimeavg = <any> 'ssptimeavg',
        SspWinRate = <any> 'sspWinRate',
        Cost = <any> 'cost',
        Revenue = <any> 'revenue',
        Profit = <any> 'profit',
        Time = <any> 'time',
        DeviceType = <any> 'deviceType',
        Os = <any> 'os',
        Country = <any> 'country',
        Browser = <any> 'browser',
        IabSize = <any> 'iabSize',
        Date = <any> 'date',
        ExternalImpressions = <any> 'external_impressions',
        ExternalRevenue = <any> 'external_revenue'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum KeysEnum {
        Ssp = <any> 'ssp',
        Dsp = <any> 'dsp',
        DspName = <any> 'dspName',
        DspimprpriceSum = <any> 'dspimprprice_sum',
        SspName = <any> 'sspName',
        SsptimeCount = <any> 'ssptime_count',
        SspimprpriceCount = <any> 'sspimprprice_count',
        SspimprpriceSum = <any> 'sspimprprice_sum',
        Audio = <any> 'audio',
        Banner = <any> 'banner',
        Video = <any> 'video',
        Nnative = <any> 'nnative',
        DeviceType = <any> 'deviceType',
        ImpW = <any> 'impW',
        ImpH = <any> 'impH',
        BidW = <any> 'bidW',
        BidH = <any> 'bidH',
        Country = <any> 'country',
        Domain = <any> 'domain',
        Day = <any> 'day',
        Time = <any> 'time',
        Hour = <any> 'hour',
        Date = <any> 'date',
        Os = <any> 'os',
        Browser = <any> 'browser',
        IabSize = <any> 'iabSize',
        Revenue = <any> 'revenue',
        Cost = <any> 'cost'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum OrderEnum {
        Asc = <any> 'asc',
        Desc = <any> 'desc'
    }
}
/**
 *
 * @export
 * @interface StatResponseBeanRawStatRow
 */
export interface StatResponseBeanRawStatRow {
    /**
     *
     * @type {Array<RawStatRow>}
     * @memberof StatResponseBeanRawStatRow
     */
    rows?: Array<RawStatRow>;
    /**
     *
     * @type {{ [key: string]: number; }}
     * @memberof StatResponseBeanRawStatRow
     */
    total?: { [key: string]: number; };
    /**
     *
     * @type {number}
     * @memberof StatResponseBeanRawStatRow
     */
    lastUpdate?: number;
}
/**
 *
 * @export
 * @interface StatsFilter
 */
export interface StatsFilter {
    /**
     *
     * @type {string}
     * @memberof StatsFilter
     */
    type: StatsFilter.TypeEnum;
    /**
     *
     * @type {Array<any>}
     * @memberof StatsFilter
     */
    params: Array<any>;
}

/**
 * @export
 * @namespace StatsFilter
 */
export namespace StatsFilter {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        InList = <any> 'inList',
        Range = <any> 'range',
        Equal = <any> 'equal',
        Hour = <any> 'hour'
    }
}
/**
 *
 * @export
 * @interface TestDataBean
 */
export interface TestDataBean {
    /**
     *
     * @type {number}
     * @memberof TestDataBean
     */
    dspId?: number;
    /**
     *
     * @type {number}
     * @memberof TestDataBean
     */
    sspId?: number;
}
/**
 *
 * @export
 * @interface TopItem
 */
export interface TopItem {
    /**
     *
     * @type {string}
     * @memberof TopItem
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof TopItem
     */
    value?: number;
}
/**
 *
 * @export
 * @interface TopListsBean
 */
export interface TopListsBean {
    /**
     *
     * @type {Array<TopItem>}
     * @memberof TopListsBean
     */
    ssps?: Array<TopItem>;
    /**
     *
     * @type {Array<TopItem>}
     * @memberof TopListsBean
     */
    dsps?: Array<TopItem>;
    /**
     *
     * @type {Array<TopItem>}
     * @memberof TopListsBean
     */
    domains?: Array<TopItem>;
}
/**
 * AccountControllerApi - fetch parameter creator
 * @export
 */
export const AccountControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Account by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        account(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling account.');
            }
            const localVarPath = `/api/account/{id}`
              .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user properties by sign in account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountProps(options: any = {}): FetchArgs {
            const localVarPath = `/api/account/accountProps`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Accounts
         * @param {string} [idFilter]
         * @param {string} [nameFilter]
         * @param {string} [emailFilter]
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accounts(idFilter?: string, nameFilter?: string, emailFilter?: string, size?: number, page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (idFilter !== undefined) {
                localVarQueryParameter['idFilter'] = idFilter;
            }

            if (nameFilter !== undefined) {
                localVarQueryParameter['nameFilter'] = nameFilter;
            }

            if (emailFilter !== undefined) {
                localVarQueryParameter['emailFilter'] = emailFilter;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new Account
         * @param {AccountBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(body: AccountBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAccount.');
            }
            const localVarPath = `/api/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Account by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAccount.');
            }
            const localVarPath = `/api/account/{id}`
              .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Account by id
         * @param {AccountBean} body
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update3(body: AccountBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling update3.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling update3.');
            }
            const localVarPath = `/api/account/{id}`
              .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountControllerApi - functional programming interface
 * @export
 */
export const AccountControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get Account by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        account(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAccountBeanObject> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).account(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get user properties by sign in account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountProps(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAccountBeanObject> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).accountProps(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get all Accounts
         * @param {string} [idFilter]
         * @param {string} [nameFilter]
         * @param {string} [emailFilter]
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accounts(idFilter?: string, nameFilter?: string, emailFilter?: string, size?: number, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseAccountBean> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).accounts(idFilter, nameFilter, emailFilter, size, page, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Create new Account
         * @param {AccountBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(body: AccountBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAccountBeanObject> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).createAccount(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Delete Account by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).deleteAccount(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Update Account by id
         * @param {AccountBean} body
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update3(body: AccountBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseAccountBeanObject> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).update3(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountControllerApi - factory interface
 * @export
 */
export const AccountControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get Account by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        account(id: number, options?: any) {
            return AccountControllerApiFp(configuration).account(id, options)(fetch, basePath);
        },
        /**
         * Get user properties by sign in account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountProps(options?: any) {
            return AccountControllerApiFp(configuration).accountProps(options)(fetch, basePath);
        },
        /**
         * Get all Accounts
         * @param {string} [idFilter]
         * @param {string} [nameFilter]
         * @param {string} [emailFilter]
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accounts(idFilter?: string, nameFilter?: string, emailFilter?: string, size?: number, page?: number, options?: any) {
            return AccountControllerApiFp(configuration).accounts(idFilter, nameFilter, emailFilter, size, page, options)(fetch, basePath);
        },
        /**
         * Create new Account
         * @param {AccountBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(body: AccountBean, options?: any) {
            return AccountControllerApiFp(configuration).createAccount(body, options)(fetch, basePath);
        },
        /**
         * Delete Account by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(id: number, options?: any) {
            return AccountControllerApiFp(configuration).deleteAccount(id, options)(fetch, basePath);
        },
        /**
         * Update Account by id
         * @param {AccountBean} body
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update3(body: AccountBean, id: number, options?: any) {
            return AccountControllerApiFp(configuration).update3(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
export class AccountControllerApi extends BaseAPI {
    /**
     * Get Account by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public account(id: number, options?: any) {
        return AccountControllerApiFp(this.configuration).account(id, options)(this.fetch, this.basePath);
    }

    /**
     * Get user properties by sign in account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public accountProps(options?: any) {
        return AccountControllerApiFp(this.configuration).accountProps(options)(this.fetch, this.basePath);
    }

    /**
     * Get all Accounts
     * @param {string} [idFilter]
     * @param {string} [nameFilter]
     * @param {string} [emailFilter]
     * @param {number} [size] Number of records per page.
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public accounts(idFilter?: string, nameFilter?: string, emailFilter?: string, size?: number, page?: number, options?: any) {
        return AccountControllerApiFp(this.configuration).accounts(idFilter, nameFilter, emailFilter, size, page, options)(this.fetch, this.basePath);
    }

    /**
     * Create new Account
     * @param {AccountBean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public createAccount(body: AccountBean, options?: any) {
        return AccountControllerApiFp(this.configuration).createAccount(body, options)(this.fetch, this.basePath);
    }

    /**
     * Delete Account by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public deleteAccount(id: number, options?: any) {
        return AccountControllerApiFp(this.configuration).deleteAccount(id, options)(this.fetch, this.basePath);
    }

    /**
     * Update Account by id
     * @param {AccountBean} body
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public update3(body: AccountBean, id: number, options?: any) {
        return AccountControllerApiFp(this.configuration).update3(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * BrowserControllerApi - fetch parameter creator
 * @export
 */
export const BrowserControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all browsers
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        browserList(size?: number, page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/browser`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrowserControllerApi - functional programming interface
 * @export
 */
export const BrowserControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get all browsers
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        browserList(size?: number, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseBrowserBean> {
            const localVarFetchArgs = BrowserControllerApiFetchParamCreator(configuration).browserList(size, page, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BrowserControllerApi - factory interface
 * @export
 */
export const BrowserControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get all browsers
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        browserList(size?: number, page?: number, options?: any) {
            return BrowserControllerApiFp(configuration).browserList(size, page, options)(fetch, basePath);
        },
    };
};

/**
 * BrowserControllerApi - object-oriented interface
 * @export
 * @class BrowserControllerApi
 * @extends {BaseAPI}
 */
export class BrowserControllerApi extends BaseAPI {
    /**
     * Get all browsers
     * @param {number} [size] Number of records per page.
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrowserControllerApi
     */
    public browserList(size?: number, page?: number, options?: any) {
        return BrowserControllerApiFp(this.configuration).browserList(size, page, options)(this.fetch, this.basePath);
    }

}
/**
 * BuildInfoControllerApi - fetch parameter creator
 * @export
 */
export const BuildInfoControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildInfo(options: any = {}): FetchArgs {
            const localVarPath = `/build-info`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildInfoPrometheus(options: any = {}): FetchArgs {
            const localVarPath = `/build-info/prometheus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuildInfoControllerApi - functional programming interface
 * @export
 */
export const BuildInfoControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildInfo(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: any; }> {
            const localVarFetchArgs = BuildInfoControllerApiFetchParamCreator(configuration).buildInfo(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildInfoPrometheus(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = BuildInfoControllerApiFetchParamCreator(configuration).buildInfoPrometheus(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BuildInfoControllerApi - factory interface
 * @export
 */
export const BuildInfoControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildInfo(options?: any) {
            return BuildInfoControllerApiFp(configuration).buildInfo(options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildInfoPrometheus(options?: any) {
            return BuildInfoControllerApiFp(configuration).buildInfoPrometheus(options)(fetch, basePath);
        },
    };
};

/**
 * BuildInfoControllerApi - object-oriented interface
 * @export
 * @class BuildInfoControllerApi
 * @extends {BaseAPI}
 */
export class BuildInfoControllerApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildInfoControllerApi
     */
    public buildInfo(options?: any) {
        return BuildInfoControllerApiFp(this.configuration).buildInfo(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildInfoControllerApi
     */
    public buildInfoPrometheus(options?: any) {
        return BuildInfoControllerApiFp(this.configuration).buildInfoPrometheus(options)(this.fetch, this.basePath);
    }

}
/**
 * CountryControllerApi - fetch parameter creator
 * @export
 */
export const CountryControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all countries
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countries(size?: number, page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/country`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryControllerApi - functional programming interface
 * @export
 */
export const CountryControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get all countries
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countries(size?: number, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseCountryBean> {
            const localVarFetchArgs = CountryControllerApiFetchParamCreator(configuration).countries(size, page, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CountryControllerApi - factory interface
 * @export
 */
export const CountryControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get all countries
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countries(size?: number, page?: number, options?: any) {
            return CountryControllerApiFp(configuration).countries(size, page, options)(fetch, basePath);
        },
    };
};

/**
 * CountryControllerApi - object-oriented interface
 * @export
 * @class CountryControllerApi
 * @extends {BaseAPI}
 */
export class CountryControllerApi extends BaseAPI {
    /**
     * Get all countries
     * @param {number} [size] Number of records per page.
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryControllerApi
     */
    public countries(size?: number, page?: number, options?: any) {
        return CountryControllerApiFp(this.configuration).countries(size, page, options)(this.fetch, this.basePath);
    }

}
/**
 * DashboardControllerApi - fetch parameter creator
 * @export
 */
export const DashboardControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdTypeChart(body: DashboardRequestBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getAdTypeChart.');
            }
            const localVarPath = `/api/dashboard/ad-type`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBannerIabSizeChart(body: DashboardRequestBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getBannerIabSizeChart.');
            }
            const localVarPath = `/api/dashboard/iab-size/banner`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrowserChart(body: DashboardRequestBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getBrowserChart.');
            }
            const localVarPath = `/api/dashboard/browser`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryChart(body: DashboardRequestBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getCountryChart.');
            }
            const localVarPath = `/api/dashboard/country`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyStats(body: DashboardRequestBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getDailyStats.');
            }
            const localVarPath = `/api/dashboard/daily-stats`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyValues(body: DashboardRequestBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getDailyValues.');
            }
            const localVarPath = `/api/dashboard/daily-values`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get bidRequest statistics
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeChart(body: DashboardRequestBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getDeviceTypeChart.');
            }
            const localVarPath = `/api/dashboard/device-type`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsChart(body: DashboardRequestBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getOsChart.');
            }
            const localVarPath = `/api/dashboard/os`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopListsByTotalRevenue(body: DashboardRequestBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getTopListsByTotalRevenue.');
            }
            const localVarPath = `/api/dashboard/top-lists`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoIabSizeChart(body: DashboardRequestBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getVideoIabSizeChart.');
            }
            const localVarPath = `/api/dashboard/iab-size/video`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DashboardRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardControllerApi - functional programming interface
 * @export
 */
export const DashboardControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdTypeChart(body: DashboardRequestBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SizeChartBean> {
            const localVarFetchArgs = DashboardControllerApiFetchParamCreator(configuration).getAdTypeChart(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBannerIabSizeChart(body: DashboardRequestBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SizeChartBean> {
            const localVarFetchArgs = DashboardControllerApiFetchParamCreator(configuration).getBannerIabSizeChart(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrowserChart(body: DashboardRequestBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SizeChartBean> {
            const localVarFetchArgs = DashboardControllerApiFetchParamCreator(configuration).getBrowserChart(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryChart(body: DashboardRequestBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SizeChartBean> {
            const localVarFetchArgs = DashboardControllerApiFetchParamCreator(configuration).getCountryChart(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyStats(body: DashboardRequestBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DailyStatsBean> {
            const localVarFetchArgs = DashboardControllerApiFetchParamCreator(configuration).getDailyStats(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyValues(body: DashboardRequestBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DailyValuesBean> {
            const localVarFetchArgs = DashboardControllerApiFetchParamCreator(configuration).getDailyValues(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get bidRequest statistics
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeChart(body: DashboardRequestBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SizeChartBean> {
            const localVarFetchArgs = DashboardControllerApiFetchParamCreator(configuration).getDeviceTypeChart(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsChart(body: DashboardRequestBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SizeChartBean> {
            const localVarFetchArgs = DashboardControllerApiFetchParamCreator(configuration).getOsChart(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopListsByTotalRevenue(body: DashboardRequestBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TopListsBean> {
            const localVarFetchArgs = DashboardControllerApiFetchParamCreator(configuration).getTopListsByTotalRevenue(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoIabSizeChart(body: DashboardRequestBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SizeChartBean> {
            const localVarFetchArgs = DashboardControllerApiFetchParamCreator(configuration).getVideoIabSizeChart(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DashboardControllerApi - factory interface
 * @export
 */
export const DashboardControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdTypeChart(body: DashboardRequestBean, options?: any) {
            return DashboardControllerApiFp(configuration).getAdTypeChart(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBannerIabSizeChart(body: DashboardRequestBean, options?: any) {
            return DashboardControllerApiFp(configuration).getBannerIabSizeChart(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrowserChart(body: DashboardRequestBean, options?: any) {
            return DashboardControllerApiFp(configuration).getBrowserChart(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryChart(body: DashboardRequestBean, options?: any) {
            return DashboardControllerApiFp(configuration).getCountryChart(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyStats(body: DashboardRequestBean, options?: any) {
            return DashboardControllerApiFp(configuration).getDailyStats(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyValues(body: DashboardRequestBean, options?: any) {
            return DashboardControllerApiFp(configuration).getDailyValues(body, options)(fetch, basePath);
        },
        /**
         * Get bidRequest statistics
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeChart(body: DashboardRequestBean, options?: any) {
            return DashboardControllerApiFp(configuration).getDeviceTypeChart(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOsChart(body: DashboardRequestBean, options?: any) {
            return DashboardControllerApiFp(configuration).getOsChart(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopListsByTotalRevenue(body: DashboardRequestBean, options?: any) {
            return DashboardControllerApiFp(configuration).getTopListsByTotalRevenue(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {DashboardRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoIabSizeChart(body: DashboardRequestBean, options?: any) {
            return DashboardControllerApiFp(configuration).getVideoIabSizeChart(body, options)(fetch, basePath);
        },
    };
};

/**
 * DashboardControllerApi - object-oriented interface
 * @export
 * @class DashboardControllerApi
 * @extends {BaseAPI}
 */
export class DashboardControllerApi extends BaseAPI {
    /**
     *
     * @param {DashboardRequestBean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getAdTypeChart(body: DashboardRequestBean, options?: any) {
        return DashboardControllerApiFp(this.configuration).getAdTypeChart(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {DashboardRequestBean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getBannerIabSizeChart(body: DashboardRequestBean, options?: any) {
        return DashboardControllerApiFp(this.configuration).getBannerIabSizeChart(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {DashboardRequestBean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getBrowserChart(body: DashboardRequestBean, options?: any) {
        return DashboardControllerApiFp(this.configuration).getBrowserChart(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {DashboardRequestBean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getCountryChart(body: DashboardRequestBean, options?: any) {
        return DashboardControllerApiFp(this.configuration).getCountryChart(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {DashboardRequestBean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getDailyStats(body: DashboardRequestBean, options?: any) {
        return DashboardControllerApiFp(this.configuration).getDailyStats(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {DashboardRequestBean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getDailyValues(body: DashboardRequestBean, options?: any) {
        return DashboardControllerApiFp(this.configuration).getDailyValues(body, options)(this.fetch, this.basePath);
    }

    /**
     * Get bidRequest statistics
     * @param {DashboardRequestBean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getDeviceTypeChart(body: DashboardRequestBean, options?: any) {
        return DashboardControllerApiFp(this.configuration).getDeviceTypeChart(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {DashboardRequestBean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getOsChart(body: DashboardRequestBean, options?: any) {
        return DashboardControllerApiFp(this.configuration).getOsChart(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {DashboardRequestBean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getTopListsByTotalRevenue(body: DashboardRequestBean, options?: any) {
        return DashboardControllerApiFp(this.configuration).getTopListsByTotalRevenue(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {DashboardRequestBean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getVideoIabSizeChart(body: DashboardRequestBean, options?: any) {
        return DashboardControllerApiFp(this.configuration).getVideoIabSizeChart(body, options)(this.fetch, this.basePath);
    }

}
/**
 * DspControllerApi - fetch parameter creator
 * @export
 */
export const DspControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create new DSP
         * @param {DspBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create2(body: DspBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling create2.');
            }
            const localVarPath = `/api/dsp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DspBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete DSP by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete2(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling delete2.');
            }
            const localVarPath = `/api/dsp/{id}`
              .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Dsp by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dsp(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling dsp.');
            }
            const localVarPath = `/api/dsp/{id}`
              .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all DSPs
         * @param {string} [idFilter]
         * @param {string} [nameFilter]
         * @param {string} [endpointFilter]
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dspList(idFilter?: string, nameFilter?: string, endpointFilter?: string, size?: number, page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/dsp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (idFilter !== undefined) {
                localVarQueryParameter['idFilter'] = idFilter;
            }

            if (nameFilter !== undefined) {
                localVarQueryParameter['nameFilter'] = nameFilter;
            }

            if (endpointFilter !== undefined) {
                localVarQueryParameter['endpointFilter'] = endpointFilter;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Dsp by id
         * @param {DspBean} body
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update2(body: DspBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling update2.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling update2.');
            }
            const localVarPath = `/api/dsp/{id}`
              .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DspBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DspControllerApi - functional programming interface
 * @export
 */
export const DspControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Create new DSP
         * @param {DspBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create2(body: DspBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseDspBeanObject> {
            const localVarFetchArgs = DspControllerApiFetchParamCreator(configuration).create2(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Delete DSP by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete2(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DspControllerApiFetchParamCreator(configuration).delete2(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get Dsp by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dsp(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseDspBeanObject> {
            const localVarFetchArgs = DspControllerApiFetchParamCreator(configuration).dsp(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get all DSPs
         * @param {string} [idFilter]
         * @param {string} [nameFilter]
         * @param {string} [endpointFilter]
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dspList(idFilter?: string, nameFilter?: string, endpointFilter?: string, size?: number, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseDspBean> {
            const localVarFetchArgs = DspControllerApiFetchParamCreator(configuration).dspList(idFilter, nameFilter, endpointFilter, size, page, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Update Dsp by id
         * @param {DspBean} body
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update2(body: DspBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseDspBeanObject> {
            const localVarFetchArgs = DspControllerApiFetchParamCreator(configuration).update2(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DspControllerApi - factory interface
 * @export
 */
export const DspControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Create new DSP
         * @param {DspBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create2(body: DspBean, options?: any) {
            return DspControllerApiFp(configuration).create2(body, options)(fetch, basePath);
        },
        /**
         * Delete DSP by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete2(id: number, options?: any) {
            return DspControllerApiFp(configuration).delete2(id, options)(fetch, basePath);
        },
        /**
         * Get Dsp by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dsp(id: number, options?: any) {
            return DspControllerApiFp(configuration).dsp(id, options)(fetch, basePath);
        },
        /**
         * Get all DSPs
         * @param {string} [idFilter]
         * @param {string} [nameFilter]
         * @param {string} [endpointFilter]
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dspList(idFilter?: string, nameFilter?: string, endpointFilter?: string, size?: number, page?: number, options?: any) {
            return DspControllerApiFp(configuration).dspList(idFilter, nameFilter, endpointFilter, size, page, options)(fetch, basePath);
        },
        /**
         * Update Dsp by id
         * @param {DspBean} body
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update2(body: DspBean, id: number, options?: any) {
            return DspControllerApiFp(configuration).update2(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * DspControllerApi - object-oriented interface
 * @export
 * @class DspControllerApi
 * @extends {BaseAPI}
 */
export class DspControllerApi extends BaseAPI {
    /**
     * Create new DSP
     * @param {DspBean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DspControllerApi
     */
    public create2(body: DspBean, options?: any) {
        return DspControllerApiFp(this.configuration).create2(body, options)(this.fetch, this.basePath);
    }

    /**
     * Delete DSP by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DspControllerApi
     */
    public delete2(id: number, options?: any) {
        return DspControllerApiFp(this.configuration).delete2(id, options)(this.fetch, this.basePath);
    }

    /**
     * Get Dsp by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DspControllerApi
     */
    public dsp(id: number, options?: any) {
        return DspControllerApiFp(this.configuration).dsp(id, options)(this.fetch, this.basePath);
    }

    /**
     * Get all DSPs
     * @param {string} [idFilter]
     * @param {string} [nameFilter]
     * @param {string} [endpointFilter]
     * @param {number} [size] Number of records per page.
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DspControllerApi
     */
    public dspList(idFilter?: string, nameFilter?: string, endpointFilter?: string, size?: number, page?: number, options?: any) {
        return DspControllerApiFp(this.configuration).dspList(idFilter, nameFilter, endpointFilter, size, page, options)(this.fetch, this.basePath);
    }

    /**
     * Update Dsp by id
     * @param {DspBean} body
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DspControllerApi
     */
    public update2(body: DspBean, id: number, options?: any) {
        return DspControllerApiFp(this.configuration).update2(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * ExternalStatsControllerApi - fetch parameter creator
 * @export
 */
export const ExternalStatsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadExternalStats(options: any = {}): FetchArgs {
            const localVarPath = `/api/external-stats/load`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalStatsControllerApi - functional programming interface
 * @export
 */
export const ExternalStatsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadExternalStats(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ExternalStatsControllerApiFetchParamCreator(configuration).loadExternalStats(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ExternalStatsControllerApi - factory interface
 * @export
 */
export const ExternalStatsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadExternalStats(options?: any) {
            return ExternalStatsControllerApiFp(configuration).loadExternalStats(options)(fetch, basePath);
        },
    };
};

/**
 * ExternalStatsControllerApi - object-oriented interface
 * @export
 * @class ExternalStatsControllerApi
 * @extends {BaseAPI}
 */
export class ExternalStatsControllerApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalStatsControllerApi
     */
    public loadExternalStats(options?: any) {
        return ExternalStatsControllerApiFp(this.configuration).loadExternalStats(options)(this.fetch, this.basePath);
    }

}
/**
 * HeartbeatControllerApi - fetch parameter creator
 * @export
 */
export const HeartbeatControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(options: any = {}): FetchArgs {
            const localVarPath = `/heartbeat`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HeartbeatControllerApi - functional programming interface
 * @export
 */
export const HeartbeatControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = HeartbeatControllerApiFetchParamCreator(configuration).getStatus(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * HeartbeatControllerApi - factory interface
 * @export
 */
export const HeartbeatControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(options?: any) {
            return HeartbeatControllerApiFp(configuration).getStatus(options)(fetch, basePath);
        },
    };
};

/**
 * HeartbeatControllerApi - object-oriented interface
 * @export
 * @class HeartbeatControllerApi
 * @extends {BaseAPI}
 */
export class HeartbeatControllerApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HeartbeatControllerApi
     */
    public getStatus(options?: any) {
        return HeartbeatControllerApiFp(this.configuration).getStatus(options)(this.fetch, this.basePath);
    }

}
/**
 * IabCategoriesControllerApi - fetch parameter creator
 * @export
 */
export const IabCategoriesControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all IAB categories
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iabCategoryList(size?: number, page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/iab-categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IabCategoriesControllerApi - functional programming interface
 * @export
 */
export const IabCategoriesControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get all IAB categories
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iabCategoryList(size?: number, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseIabCategoryBean> {
            const localVarFetchArgs = IabCategoriesControllerApiFetchParamCreator(configuration).iabCategoryList(size, page, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * IabCategoriesControllerApi - factory interface
 * @export
 */
export const IabCategoriesControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get all IAB categories
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iabCategoryList(size?: number, page?: number, options?: any) {
            return IabCategoriesControllerApiFp(configuration).iabCategoryList(size, page, options)(fetch, basePath);
        },
    };
};

/**
 * IabCategoriesControllerApi - object-oriented interface
 * @export
 * @class IabCategoriesControllerApi
 * @extends {BaseAPI}
 */
export class IabCategoriesControllerApi extends BaseAPI {
    /**
     * Get all IAB categories
     * @param {number} [size] Number of records per page.
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IabCategoriesControllerApi
     */
    public iabCategoryList(size?: number, page?: number, options?: any) {
        return IabCategoriesControllerApiFp(this.configuration).iabCategoryList(size, page, options)(this.fetch, this.basePath);
    }

}
/**
 * IabFixedSizesControllerApi - fetch parameter creator
 * @export
 */
export const IabFixedSizesControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all IAB fixed sizes
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iabFixedSizeList(size?: number, page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/iab-fixed-size`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IabFixedSizesControllerApi - functional programming interface
 * @export
 */
export const IabFixedSizesControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get all IAB fixed sizes
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iabFixedSizeList(size?: number, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseIabFixedSizeBean> {
            const localVarFetchArgs = IabFixedSizesControllerApiFetchParamCreator(configuration).iabFixedSizeList(size, page, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * IabFixedSizesControllerApi - factory interface
 * @export
 */
export const IabFixedSizesControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get all IAB fixed sizes
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        iabFixedSizeList(size?: number, page?: number, options?: any) {
            return IabFixedSizesControllerApiFp(configuration).iabFixedSizeList(size, page, options)(fetch, basePath);
        },
    };
};

/**
 * IabFixedSizesControllerApi - object-oriented interface
 * @export
 * @class IabFixedSizesControllerApi
 * @extends {BaseAPI}
 */
export class IabFixedSizesControllerApi extends BaseAPI {
    /**
     * Get all IAB fixed sizes
     * @param {number} [size] Number of records per page.
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IabFixedSizesControllerApi
     */
    public iabFixedSizeList(size?: number, page?: number, options?: any) {
        return IabFixedSizesControllerApiFp(this.configuration).iabFixedSizeList(size, page, options)(this.fetch, this.basePath);
    }

}
/**
 * ReportControllerApi - fetch parameter creator
 * @export
 */
export const ReportControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {StatRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsRequests(body: StatRequestBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling statisticsRequests.');
            }
            const localVarPath = `/api/report/stats`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StatRequestBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportControllerApi - functional programming interface
 * @export
 */
export const ReportControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {StatRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsRequests(body: StatRequestBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StatResponseBeanRawStatRow> {
            const localVarFetchArgs = ReportControllerApiFetchParamCreator(configuration).statisticsRequests(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReportControllerApi - factory interface
 * @export
 */
export const ReportControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {StatRequestBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsRequests(body: StatRequestBean, options?: any) {
            return ReportControllerApiFp(configuration).statisticsRequests(body, options)(fetch, basePath);
        },
    };
};

/**
 * ReportControllerApi - object-oriented interface
 * @export
 * @class ReportControllerApi
 * @extends {BaseAPI}
 */
export class ReportControllerApi extends BaseAPI {
    /**
     *
     * @param {StatRequestBean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportControllerApi
     */
    public statisticsRequests(body: StatRequestBean, options?: any) {
        return ReportControllerApiFp(this.configuration).statisticsRequests(body, options)(this.fetch, this.basePath);
    }

}
/**
 * SavedQueryControllerApi - fetch parameter creator
 * @export
 */
export const SavedQueryControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {SavedQueryBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(body: SavedQueryBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling create1.');
            }
            const localVarPath = `/api/saved-query`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SavedQueryBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling delete1.');
            }
            const localVarPath = `/api/saved-query/{id}`
              .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all favorite saved queries
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteSavedQueries(size?: number, page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/saved-query/favorite-saved-query`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all saved queries
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savedQueries(size?: number, page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/saved-query`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savedQuery(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling savedQuery.');
            }
            const localVarPath = `/api/saved-query/{id}`
              .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {SavedQueryBean} body
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(body: SavedQueryBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling update1.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling update1.');
            }
            const localVarPath = `/api/saved-query/{id}`
              .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SavedQueryBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SavedQueryControllerApi - functional programming interface
 * @export
 */
export const SavedQueryControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {SavedQueryBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(body: SavedQueryBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseSavedQueryBeanObject> {
            const localVarFetchArgs = SavedQueryControllerApiFetchParamCreator(configuration).create1(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SavedQueryControllerApiFetchParamCreator(configuration).delete1(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get all favorite saved queries
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteSavedQueries(size?: number, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseSavedQueryBean> {
            const localVarFetchArgs = SavedQueryControllerApiFetchParamCreator(configuration).favoriteSavedQueries(size, page, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get all saved queries
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savedQueries(size?: number, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseSavedQueryBean> {
            const localVarFetchArgs = SavedQueryControllerApiFetchParamCreator(configuration).savedQueries(size, page, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savedQuery(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseSavedQueryBeanObject> {
            const localVarFetchArgs = SavedQueryControllerApiFetchParamCreator(configuration).savedQuery(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {SavedQueryBean} body
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(body: SavedQueryBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseSavedQueryBeanObject> {
            const localVarFetchArgs = SavedQueryControllerApiFetchParamCreator(configuration).update1(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SavedQueryControllerApi - factory interface
 * @export
 */
export const SavedQueryControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {SavedQueryBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(body: SavedQueryBean, options?: any) {
            return SavedQueryControllerApiFp(configuration).create1(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(id: number, options?: any) {
            return SavedQueryControllerApiFp(configuration).delete1(id, options)(fetch, basePath);
        },
        /**
         * Get all favorite saved queries
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteSavedQueries(size?: number, page?: number, options?: any) {
            return SavedQueryControllerApiFp(configuration).favoriteSavedQueries(size, page, options)(fetch, basePath);
        },
        /**
         * Get all saved queries
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savedQueries(size?: number, page?: number, options?: any) {
            return SavedQueryControllerApiFp(configuration).savedQueries(size, page, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savedQuery(id: number, options?: any) {
            return SavedQueryControllerApiFp(configuration).savedQuery(id, options)(fetch, basePath);
        },
        /**
         *
         * @param {SavedQueryBean} body
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(body: SavedQueryBean, id: number, options?: any) {
            return SavedQueryControllerApiFp(configuration).update1(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * SavedQueryControllerApi - object-oriented interface
 * @export
 * @class SavedQueryControllerApi
 * @extends {BaseAPI}
 */
export class SavedQueryControllerApi extends BaseAPI {
    /**
     *
     * @param {SavedQueryBean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedQueryControllerApi
     */
    public create1(body: SavedQueryBean, options?: any) {
        return SavedQueryControllerApiFp(this.configuration).create1(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedQueryControllerApi
     */
    public delete1(id: number, options?: any) {
        return SavedQueryControllerApiFp(this.configuration).delete1(id, options)(this.fetch, this.basePath);
    }

    /**
     * Get all favorite saved queries
     * @param {number} [size] Number of records per page.
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedQueryControllerApi
     */
    public favoriteSavedQueries(size?: number, page?: number, options?: any) {
        return SavedQueryControllerApiFp(this.configuration).favoriteSavedQueries(size, page, options)(this.fetch, this.basePath);
    }

    /**
     * Get all saved queries
     * @param {number} [size] Number of records per page.
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedQueryControllerApi
     */
    public savedQueries(size?: number, page?: number, options?: any) {
        return SavedQueryControllerApiFp(this.configuration).savedQueries(size, page, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedQueryControllerApi
     */
    public savedQuery(id: number, options?: any) {
        return SavedQueryControllerApiFp(this.configuration).savedQuery(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {SavedQueryBean} body
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavedQueryControllerApi
     */
    public update1(body: SavedQueryBean, id: number, options?: any) {
        return SavedQueryControllerApiFp(this.configuration).update1(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * SspControllerApi - fetch parameter creator
 * @export
 */
export const SspControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete Ssp by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling _delete.');
            }
            const localVarPath = `/api/ssp/{id}`
              .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new Ssp
         * @param {SspBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(body: SspBean, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling create.');
            }
            const localVarPath = `/api/ssp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SspBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Ssp by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssp(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling ssp.');
            }
            const localVarPath = `/api/ssp/{id}`
              .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all Ssp
         * @param {string} [idFilter]
         * @param {string} [nameFilter]
         * @param {string} [endpointFilter]
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sspList(idFilter?: string, nameFilter?: string, endpointFilter?: string, size?: number, page?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/ssp`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (idFilter !== undefined) {
                localVarQueryParameter['idFilter'] = idFilter;
            }

            if (nameFilter !== undefined) {
                localVarQueryParameter['nameFilter'] = nameFilter;
            }

            if (endpointFilter !== undefined) {
                localVarQueryParameter['endpointFilter'] = endpointFilter;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Ssp by id
         * @param {SspBean} body
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(body: SspBean, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling update.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling update.');
            }
            const localVarPath = `/api/ssp/{id}`
              .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SspBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SspControllerApi - functional programming interface
 * @export
 */
export const SspControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Delete Ssp by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SspControllerApiFetchParamCreator(configuration)._delete(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Create new Ssp
         * @param {SspBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(body: SspBean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseSspBeanObject> {
            const localVarFetchArgs = SspControllerApiFetchParamCreator(configuration).create(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get Ssp by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssp(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseSspBeanObject> {
            const localVarFetchArgs = SspControllerApiFetchParamCreator(configuration).ssp(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get all Ssp
         * @param {string} [idFilter]
         * @param {string} [nameFilter]
         * @param {string} [endpointFilter]
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sspList(idFilter?: string, nameFilter?: string, endpointFilter?: string, size?: number, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListResponseSspBean> {
            const localVarFetchArgs = SspControllerApiFetchParamCreator(configuration).sspList(idFilter, nameFilter, endpointFilter, size, page, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Update Ssp by id
         * @param {SspBean} body
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(body: SspBean, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ItemResponseSspBeanObject> {
            const localVarFetchArgs = SspControllerApiFetchParamCreator(configuration).update(body, id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SspControllerApi - factory interface
 * @export
 */
export const SspControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Delete Ssp by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any) {
            return SspControllerApiFp(configuration)._delete(id, options)(fetch, basePath);
        },
        /**
         * Create new Ssp
         * @param {SspBean} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(body: SspBean, options?: any) {
            return SspControllerApiFp(configuration).create(body, options)(fetch, basePath);
        },
        /**
         * Get Ssp by id
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssp(id: number, options?: any) {
            return SspControllerApiFp(configuration).ssp(id, options)(fetch, basePath);
        },
        /**
         * Get all Ssp
         * @param {string} [idFilter]
         * @param {string} [nameFilter]
         * @param {string} [endpointFilter]
         * @param {number} [size] Number of records per page.
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sspList(idFilter?: string, nameFilter?: string, endpointFilter?: string, size?: number, page?: number, options?: any) {
            return SspControllerApiFp(configuration).sspList(idFilter, nameFilter, endpointFilter, size, page, options)(fetch, basePath);
        },
        /**
         * Update Ssp by id
         * @param {SspBean} body
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(body: SspBean, id: number, options?: any) {
            return SspControllerApiFp(configuration).update(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * SspControllerApi - object-oriented interface
 * @export
 * @class SspControllerApi
 * @extends {BaseAPI}
 */
export class SspControllerApi extends BaseAPI {
    /**
     * Delete Ssp by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SspControllerApi
     */
    public _delete(id: number, options?: any) {
        return SspControllerApiFp(this.configuration)._delete(id, options)(this.fetch, this.basePath);
    }

    /**
     * Create new Ssp
     * @param {SspBean} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SspControllerApi
     */
    public create(body: SspBean, options?: any) {
        return SspControllerApiFp(this.configuration).create(body, options)(this.fetch, this.basePath);
    }

    /**
     * Get Ssp by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SspControllerApi
     */
    public ssp(id: number, options?: any) {
        return SspControllerApiFp(this.configuration).ssp(id, options)(this.fetch, this.basePath);
    }

    /**
     * Get all Ssp
     * @param {string} [idFilter]
     * @param {string} [nameFilter]
     * @param {string} [endpointFilter]
     * @param {number} [size] Number of records per page.
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SspControllerApi
     */
    public sspList(idFilter?: string, nameFilter?: string, endpointFilter?: string, size?: number, page?: number, options?: any) {
        return SspControllerApiFp(this.configuration).sspList(idFilter, nameFilter, endpointFilter, size, page, options)(this.fetch, this.basePath);
    }

    /**
     * Update Ssp by id
     * @param {SspBean} body
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SspControllerApi
     */
    public update(body: SspBean, id: number, options?: any) {
        return SspControllerApiFp(this.configuration).update(body, id, options)(this.fetch, this.basePath);
    }

}
/**
 * TestDataControllerApi - fetch parameter creator
 * @export
 */
export const TestDataControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {TestDataBean} body
         * @param {number} amount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestData(body: TestDataBean, amount: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling generateTestData.');
            }
            // verify required parameter 'amount' is not null or undefined
            if (amount === null || amount === undefined) {
                throw new RequiredError('amount','Required parameter amount was null or undefined when calling generateTestData.');
            }
            const localVarPath = `/api/test-data`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TestDataBean" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestDataControllerApi - functional programming interface
 * @export
 */
export const TestDataControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {TestDataBean} body
         * @param {number} amount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestData(body: TestDataBean, amount: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TestDataControllerApiFetchParamCreator(configuration).generateTestData(body, amount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TestDataControllerApi - factory interface
 * @export
 */
export const TestDataControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {TestDataBean} body
         * @param {number} amount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestData(body: TestDataBean, amount: number, options?: any) {
            return TestDataControllerApiFp(configuration).generateTestData(body, amount, options)(fetch, basePath);
        },
    };
};

/**
 * TestDataControllerApi - object-oriented interface
 * @export
 * @class TestDataControllerApi
 * @extends {BaseAPI}
 */
export class TestDataControllerApi extends BaseAPI {
    /**
     *
     * @param {TestDataBean} body
     * @param {number} amount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestDataControllerApi
     */
    public generateTestData(body: TestDataBean, amount: number, options?: any) {
        return TestDataControllerApiFp(this.configuration).generateTestData(body, amount, options)(this.fetch, this.basePath);
    }

}
