import React, { useEffect } from 'react'
import { Button, Col, DatePicker, Row } from 'antd'
import moment, { Moment } from 'moment'
import { DashboardState } from '../Dashboard'

const { RangePicker } = DatePicker

const activeStyle = {
  borderColor: '#40a9ff',
  color: '#40a9ff',
}

interface Props {
  state: Partial<DashboardState>
  refreshState: (
    startDate: Moment,
    endDate: Moment,
    button: 'None' | 'Range' | 'Today' | 'Yesterday' | 'Last 7 Days' | 'Last 30 Days' | 'This Month' | 'Last Month'
  ) => void
}

export const DataSelect: React.FC<Props> = ({ state, refreshState }) => {
  useEffect(() => {
    if (state.button === 'None') {
      refreshState(moment(), moment(), 'Today')
    }
  })

  return (
    <Row align="middle" gutter={[10, 10]}>
      <Col>
        <RangePicker
          style={state.button === 'Range' ? activeStyle : {}}
          value={[state.startDate as Moment, state.endDate as Moment]}
          onChange={(date) => {
            refreshState(date ? (date[0] as Moment) : moment(), date ? (date[1] as Moment) : moment(), 'Range')
          }}
          size="large"
        />
      </Col>

      <Col flex="auto">
        <></>
      </Col>

      <Col>
        <Button
          style={state.button === 'Today' ? activeStyle : {}}
          onClick={() => {
            refreshState(moment(), moment(), 'Today')
          }}
        >
          Today
        </Button>
      </Col>

      <Col>
        <Button
          style={state.button === 'Yesterday' ? activeStyle : {}}
          onClick={() => {
            refreshState(moment().subtract(1, 'days'), moment().subtract(1, 'days'), 'Yesterday')
          }}
        >
          Yesterday
        </Button>
      </Col>

      <Col>
        <Button
          style={state.button === 'Last 7 Days' ? activeStyle : {}}
          onClick={() => {
            refreshState(moment().subtract(6, 'days'), moment(), 'Last 7 Days')
          }}
        >
          Last 7 Days
        </Button>
      </Col>

      <Col>
        <Button
          style={state.button === 'Last 30 Days' ? activeStyle : {}}
          onClick={() => {
            refreshState(moment().subtract(29, 'days'), moment(), 'Last 30 Days')
          }}
        >
          Last 30 Days
        </Button>
      </Col>

      <Col>
        <Button
          style={state.button === 'This Month' ? activeStyle : {}}
          onClick={() => {
            refreshState(moment().date(1), moment(), 'This Month')
          }}
        >
          This Month
        </Button>
      </Col>

      <Col>
        <Button
          style={state.button === 'Last Month' ? activeStyle : {}}
          onClick={() => {
            refreshState(
              moment().subtract(1, 'months').date(1),
              moment().subtract(1, 'months').endOf('month'),
              'Last Month'
            )
          }}
        >
          Last Month
        </Button>
      </Col>
    </Row>
  )
}
