import React from 'react'
import { useHistory } from 'react-router-dom'
import { Row } from 'antd'
import { useFormData } from '../../../components/list'
import { getListPath, routes } from '../../../routes'
import { FormCancelButton, FormSubmitButton } from '../../../components/form'
import { sspApi } from '../../../api/ssp'
import { SspBean } from '../../../api/base/api'

import { SspCreateEditForm } from './SspCreateEditForm'
import { SspTrafficFilters } from './SspTrafficFilters'

interface Props {
  type: 'create' | 'edit'
  initialBean: Partial<SspBean>
  activeTab: 'Main' | 'TrafficFilters'
}

export const SspForm: React.FC<Props> = ({ type, initialBean, activeTab }) => {
  const history = useHistory()
  const [formData, updateForm] = useFormData({ ...initialBean })

  const create = () => {
    sspApi.create(formData as SspBean).then((data) => history.push(getListPath(routes.ssp), data.data?.id))
  }

  const edit = () => {
    sspApi.edit(formData as SspBean).then((data) => history.push(getListPath(routes.ssp), data.data?.id))
  }

  const submit = () => {
    if (type === 'create') {
      create()
    }
    if (type === 'edit') {
      edit()
    }
  }

  return (
    <>
      <Row>
        {activeTab === 'Main' && <SspCreateEditForm bean={formData} updateBean={updateForm} />}
        {activeTab === 'TrafficFilters' && <SspTrafficFilters bean={formData} updateBean={updateForm} />}
      </Row>

      <Row gutter={15}>
        <FormSubmitButton text={type === 'create' ? 'Create' : 'Edit'} onClick={submit} />
        <FormCancelButton path={getListPath('/ssp', initialBean.id)} />
      </Row>
    </>
  )
}
