import React from 'react'
import { Menu } from 'antd'
import { NavLink, useLocation } from 'react-router-dom'
import { QqOutlined } from '@ant-design/icons'

import { navs } from './navs'
import { useAccountBean } from '../../utils/useAccountBean'
import { AccountBean } from '../../api/base/api'
import { useSavedQueries } from '../../utils/useSavedQueries'

export const Sidenav: React.FC = () => {
  const { pathname } = useLocation()
  const account: AccountBean = useAccountBean()
  const { savedQueries } = useSavedQueries()

  const color = '#1890ff'
  const LINK_CUT_INDEX = 17

  return (
    <>
      <div className="brand">
        <QqOutlined />
        <span>Asterio</span>
      </div>
      <hr />

      <Menu theme="light" mode="inline">
        {navs(savedQueries)
          .filter(
            (nav) => !(nav.excludeForRoles != null && account != null && nav.excludeForRoles.includes(account.role))
          )
          .map((nav) =>
            nav.isTitle ? (
              <Menu.Item className="menu-item-header" key={nav.name}>
                {nav.name}
              </Menu.Item>
            ) : (
              <Menu.Item key={nav.name}>
                <NavLink to={nav.url ?? ''}>
                  <span
                    className="icon"
                    style={{
                      background: pathname.includes(nav.url ?? '') ? color : '',
                    }}
                  >
                    {nav.icon && <nav.icon />}
                  </span>
                  <span className="label">
                    {nav.name.length < LINK_CUT_INDEX ? nav.name : nav.name.substring(0, LINK_CUT_INDEX).concat('...')}
                  </span>
                </NavLink>
              </Menu.Item>
            )
          )}
      </Menu>
    </>
  )
}
