import { Col, Form, Input } from 'antd'
import { ColSize } from 'antd/lib/grid'
import React from 'react'

interface Props {
  xs: ColSize | number | string
  value?: string
  onChange: (value: string) => void
  label?: string
  placeholder?: string
  type?: string
  disabled?: boolean
  status?: '' | 'warning' | 'error' | undefined
  description?: string
}

export const FormTextInput: React.FC<Props> = ({
  xs,
  value,
  onChange,
  label,
  placeholder,
  type,
  disabled,
  status,
  description,
}) => (
  <Col xs={xs}>
    <div className="ant-form-vertical">
      <Form.Item label={label}>
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          status={status}
        />
        <div style={{ fontStyle: 'italic', color: 'gray' }}>{description}</div>
      </Form.Item>
    </div>
  </Col>
)
