import { useEffect, useState } from 'react'
import { authorizationStore } from './authorizationStore'

export function useAuthorization() {
  const [isAuthorized, setIsAuthorized] = useState(!!authorizationStore.token)

  useEffect(() => {
    const cb = () => setIsAuthorized(!!authorizationStore.token)
    authorizationStore.subscribe(cb)
    return () => authorizationStore.unsubscribe(cb)
  }, [])

  return isAuthorized
}
