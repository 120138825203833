import { listDeviceTypeArray } from '../components/traffic-filter/utils/OpenRtbDeviceTypeEnum'

export const modeToString = (mode) => mode.toString().replace('_', ' ')

export const listToString = (list) => (list && list.length > 0 ? list.reduce((acc, el) => `${acc}, ${el}`) : '')

export const trafficTypesInfoString = (mode, list, includeAll) =>
  includeAll
    ? `mode: ${modeToString(mode)}`
    : `mode: ${modeToString(mode)}, values: ${listToString(
        list?.map(
          (el) => listDeviceTypeArray.find((item) => item.key.toLowerCase() === el.toString().toLowerCase())?.value
        )
      )}`
export const inputListInfoString = (mode, list, includeAll) =>
  includeAll ? `mode: ${modeToString(mode)}` : `mode: ${modeToString(mode)}, values: ${listToString(list)}`
