import { Card, Col, Row } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { sspApi } from '../../../api/ssp'
import { getCreatePath, getEditPath, getListPath, routes } from '../../../routes'

import {
  List,
  ListButtons,
  ListCreateButton,
  ListDeleteButton,
  ListEditButton,
  useGetList,
  useGetListItem,
  useListFilters,
} from '../../../components/list'

import { SspInfoMain } from './SspInfoMain'
import { InfoTrafficFilters } from '../../../components/info/InfoTrafficFilters'

interface Props {
  selectedId?: number
}

const tabList = [
  {
    key: 'SspInfoMain',
    tab: 'Main',
  },
  {
    key: 'SspInfoTrafficFilters',
    tab: 'Traffic Filters',
  },
]

export const SspList: React.FC<Props> = ({ selectedId }) => {
  const history = useHistory()
  const { filters, setFilter } = useListFilters('ssp')

  const { list, refreshList, pagination } = useGetList(() => sspApi.list(filters), filters)
  const selectedItem = useGetListItem((id) => sspApi.view(id), selectedId)

  const [activeTab, setActiveTab] = useState<'SspInfoMain' | 'SspInfoTrafficFilters'>('SspInfoMain')

  return (
    <Row gutter={[16, 16]}>
      <Col md={24} lg={14}>
        <Card
          title="SSPs"
          bordered={false}
          className="criclebox tablespace"
          extra={<ListCreateButton path={getCreatePath(routes.ssp)} page="SspList" />}
        >
          <List
            list={list}
            headers={['id', 'name', 'endpoint', 'enable', 'max QPS', 'bid type', 'rev share']}
            filters={[
              {
                name: 'id',
                value: filters.id,
                onSearch: (value) => setFilter('id', value),
              },
              {
                name: 'name',
                value: filters.name,
                onSearch: (value) => setFilter('name', value),
              },
              {
                name: 'endpoint',
                value: filters.endpoint,
                onSearch: (value) => setFilter('endpoint', value),
              },
            ]}
            renderRowCells={(ssp) => [
              ssp.id,
              ssp.name,
              ssp.endpoint,
              `${ssp.enable}`,
              ssp.maxQps,
              ssp.bidType,
              ssp.revShare,
            ]}
            selectedRow={selectedItem}
            onSelect={(id) => history.push(getListPath(routes.ssp, id))}
            page={filters.page}
            setPage={(page) => setFilter('page', page)}
            totalPages={pagination.totalPages ?? 0}
          />
        </Card>
      </Col>

      {selectedItem && (
        <Col md={24} lg={10}>
          <Card
            onTabChange={(tab) => setActiveTab(tab as 'SspInfoMain' | 'SspInfoTrafficFilters')}
            tabList={tabList}
            bordered={false}
            title="Ssp info"
            extra={
              <ListButtons>
                <ListEditButton path={getEditPath(routes.ssp, selectedItem.id)} page="SspList" />
                <ListDeleteButton
                  deleteItem={() => sspApi.delete(selectedItem.id as number)}
                  refreshList={refreshList}
                  redirectAfter={() => history.push(getListPath(routes.ssp))}
                  page="SspList"
                />
              </ListButtons>
            }
          >
            <Row>
              {activeTab === 'SspInfoMain' && <SspInfoMain selectedItem={selectedItem} />}
              {activeTab === 'SspInfoTrafficFilters' && <InfoTrafficFilters selectedItem={selectedItem} />}
            </Row>
          </Card>
        </Col>
      )}
    </Row>
  )
}
