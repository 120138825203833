import { Col, Row, Space, Typography } from 'antd'
import React from 'react'
import { DspBean, DspBean as DspBeanNamespace } from '../../api/base/api'
import { TrafficTypeFilter } from './TrafficTypeFilter'

const { Text } = Typography

interface Props {
  bean: DspBean
  updateBean: (bean: DspBean) => void
}

export const DspTrafficTypeFilter: React.FC<Props> = ({ bean, updateBean }) => (
  <Col xs={12}>
    <Space direction="vertical" style={{ width: '100%' }}>
      <Text>Traffic Filters</Text>

      <Row justify="space-between">
        <Col xs={5}>
          <Text strong>Traffic Type</Text>
        </Col>
        <Col xs={7}>
          <Text strong>Filter Rule</Text>
        </Col>
        <Col xs={11}>
          <Text strong>Placement Type</Text>
        </Col>
      </Row>

      <TrafficTypeFilter
        label="Audio"
        defaultMode={DspBeanNamespace.AudioDeviceTypesModeEnum[DspBeanNamespace.AudioDeviceTypesModeEnum.INCLUDEALL]}
        filterMode={
          DspBeanNamespace.AudioDeviceTypesModeEnum[bean.audioDeviceTypesMode as DspBean.AudioDeviceTypesModeEnum]
        }
        updateFilterMode={(filterMode: string) => {
          bean.audioDeviceTypesMode = DspBean.AudioDeviceTypesModeEnum[filterMode]
          updateBean(bean)
        }}
        deviceTypeList={
          bean.audioDeviceTypes?.map(
            (item) => DspBeanNamespace.AudioDeviceTypesEnum[item as DspBean.AudioDeviceTypesEnum]
          ) as string[]
        }
        updatedeviceTypeList={(deviceTypes: string[]) => {
          bean.audioDeviceTypes = deviceTypes.map((item) => DspBean.AudioDeviceTypesEnum[item])
          updateBean(bean)
        }}
      />

      <TrafficTypeFilter
        label="Video"
        defaultMode={DspBeanNamespace.VideoDeviceTypesModeEnum[DspBeanNamespace.VideoDeviceTypesModeEnum.INCLUDEALL]}
        filterMode={
          DspBeanNamespace.VideoDeviceTypesModeEnum[bean.videoDeviceTypesMode as DspBean.VideoDeviceTypesModeEnum]
        }
        updateFilterMode={(filterMode: string) => {
          bean.videoDeviceTypesMode = DspBean.VideoDeviceTypesModeEnum[filterMode]
          updateBean(bean)
        }}
        deviceTypeList={
          bean.videoDeviceTypes?.map(
            (item) => DspBean.VideoDeviceTypesEnum[item as DspBean.VideoDeviceTypesEnum]
          ) as string[]
        }
        updatedeviceTypeList={(deviceTypes: string[]) => {
          bean.videoDeviceTypes = deviceTypes.map((item) => DspBean.VideoDeviceTypesEnum[item])
          updateBean(bean)
        }}
      />

      <TrafficTypeFilter
        label="Banner"
        defaultMode={DspBeanNamespace.BannerDeviceTypesModeEnum[DspBeanNamespace.BannerDeviceTypesModeEnum.INCLUDEALL]}
        filterMode={
          DspBeanNamespace.BannerDeviceTypesModeEnum[bean.bannerDeviceTypesMode as DspBean.BannerDeviceTypesModeEnum]
        }
        updateFilterMode={(filterMode: string) => {
          bean.bannerDeviceTypesMode = DspBean.BannerDeviceTypesModeEnum[filterMode]
          updateBean(bean)
        }}
        deviceTypeList={
          bean.bannerDeviceTypes?.map(
            (item) => DspBean.BannerDeviceTypesEnum[item as DspBean.BannerDeviceTypesEnum]
          ) as string[]
        }
        updatedeviceTypeList={(deviceTypes: string[]) => {
          bean.bannerDeviceTypes = deviceTypes.map((item) => DspBean.BannerDeviceTypesEnum[item])
          updateBean(bean)
        }}
      />

      <TrafficTypeFilter
        label="Native"
        defaultMode={DspBeanNamespace.NativeDeviceTypesModeEnum[DspBeanNamespace.NativeDeviceTypesModeEnum.INCLUDEALL]}
        filterMode={
          DspBeanNamespace.NativeDeviceTypesModeEnum[bean.nativeDeviceTypesMode as DspBean.NativeDeviceTypesModeEnum]
        }
        updateFilterMode={(filterMode: string) => {
          bean.nativeDeviceTypesMode = DspBean.NativeDeviceTypesModeEnum[filterMode]
          updateBean(bean)
        }}
        deviceTypeList={
          bean.nativeDeviceTypes?.map(
            (item) => DspBean.NativeDeviceTypesEnum[item as DspBean.NativeDeviceTypesEnum]
          ) as string[]
        }
        updatedeviceTypeList={(deviceTypes: string[]) => {
          bean.nativeDeviceTypes = deviceTypes.map((item) => DspBean.NativeDeviceTypesEnum[item])
          updateBean(bean)
        }}
      />
    </Space>
  </Col>
)
