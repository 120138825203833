import React, { useEffect, useState } from 'react'
import { Table, TablePaginationConfig } from 'antd'
import { FilterOutlined } from '@ant-design/icons'

import { LIST_PAGE_SIZE } from '..'
import { ListFilter } from '../filters/ListFilter'

import styles from './list.module.scss'

interface FilterProp {
  name: string
  value: string
  onSearch: (value: string) => void
}

interface Props<Item> {
  headers: string[]
  list?: Item[]
  renderRowCells: (row: Item) => (string | number | JSX.Element | Element | undefined)[]
  onSelect?: (id: number) => void
  selectedRow?: Item
  page: number
  setPage: (page: number) => void
  totalPages: number
  filters?: FilterProp[]
}

export const List = <Item extends { id?: number }>({
  headers,
  list,
  selectedRow,
  renderRowCells,
  onSelect,
  page,
  setPage,
  totalPages,
  filters,
}: Props<Item>) => {
  const [columns, setColums] = useState([{}])

  useEffect(() => {
    const columnsInner = headers.map((header) => ({ title: header, dataIndex: header }))

    if (filters) {
      filters.forEach((item) => {
        const column = columnsInner.find((col) => col.title === item.name)

        if (column) {
          Object.assign(column, {
            filterDropdown: ({ confirm }) => (
              <ListFilter storedValue={item.value} onSearch={item.onSearch} confirm={confirm} />
            ),
            filterIcon: () => <FilterOutlined style={{ color: item.value ? '#1890ff' : undefined }} />,
          })
        }
      })
    }

    setColums(columnsInner)
  }, [filters])

  const dataSource = list?.map((item) => {
    const cells = renderRowCells(item)
    return cells.reduce((acc, cell, index) => ({ ...acc, [headers[index]]: cell }), { key: item.id })
  })

  const pagination: TablePaginationConfig = {
    current: page,
    onChange: setPage,
    total: totalPages * LIST_PAGE_SIZE,
    showSizeChanger: false,
    defaultPageSize: LIST_PAGE_SIZE,
    position: ['bottomCenter'],
  }

  const rowSelection = {
    selectedRowKeys: selectedRow?.id ? [selectedRow?.id] : [],
  }

  return (
    <div className={`table-responsiv ${styles.list}`}>
      <Table
        style={{ overflow: 'auto' }}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        className="ant-border-space"
        rowSelection={rowSelection}
        onRow={(_, rowIndex) => ({
          onClick: () => onSelect?.(list?.[rowIndex as number].id as number),
        })}
      />
    </div>
  )
}
