import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { AccountList } from './list/AccountList'
import { AccountFormContainer } from './form/AccountFormContainer'
import { getCreatePath, getEditPath, getListPath, routes } from '../../routes'

export const AccountRoot: React.FC = () => (
  <Switch>
    <Route
      path={`${getListPath(routes.account)}/:id?`}
      render={(rp) => <AccountList selectedId={+(rp.match.params.id ?? '')} />}
    />
    <Route path={`${getCreatePath(routes.account)}`} component={AccountFormContainer} />
    <Route
      path={`${getEditPath(routes.account)}/:id`}
      render={(rp) => <AccountFormContainer id={+rp.match.params.id} />}
    />
    <Redirect to={getListPath(routes.account)} />
  </Switch>
)
