import { AccountBean, FilterEntry, StatRequestBean, StatsFilter } from '../../../../api/base/api'
import { keysAvailable } from './KeysAvailable'
import { valuesAvailable } from './ValuesAvailable'
import { FilterType } from './FilterType'

export interface FilterElem {
  index: number
  key: string
  type: StatsFilter.TypeEnum
  params: any[]
}

export interface DictionaryElem {
  value: string
  label: string
  children?: Array<FilterEntry.KeysEnum>
  format: string
  filterType?: FilterType
  roles?: Array<AccountBean.RoleEnum>
}

export const RequestPeriods = {
  Today: 'Today',
  Yesterday: 'Yesterday',
  Past_3_days: 'Past 3 Days',
  Last_7_days: 'Last 7 Days',
  Last_30_days: 'Last 30 Days',
  This_month: 'This Month',
  Last_month: 'Last Month',
  Custom_range: 'Custom Range',
}

export function toDictionaryElems(values: string[], dictionary: DictionaryElem[]): DictionaryElem[] {
  const array = values.map((value) => dictionary.filter((elem) => elem.value === value)[0])
  return array
}

export function lableToDictionaryElems(values: string[], dictionary: DictionaryElem[]): DictionaryElem[] {
  const array = values.map((value) => dictionary.filter((elem) => elem.label === value)[0])
  return array
}

export const keysInitial: string[] = [
  StatRequestBean.KeysEnum.SspName.toString(),
  StatRequestBean.KeysEnum.DspName.toString(),
]

export const valuesInitial: string[] = [
  StatRequestBean.ValuesEnum.DspReq.toString(),
  StatRequestBean.ValuesEnum.DspBid.toString(),
  StatRequestBean.ValuesEnum.DspWin.toString(),
  StatRequestBean.ValuesEnum.DspImpr.toString(),
  StatRequestBean.ValuesEnum.SspReq.toString(),
  StatRequestBean.ValuesEnum.SspBid.toString(),
  StatRequestBean.ValuesEnum.SspWin.toString(),
  StatRequestBean.ValuesEnum.SspImpr.toString(),
  StatRequestBean.ValuesEnum.Revenue.toString(),
  StatRequestBean.ValuesEnum.Cost.toString(),
  StatRequestBean.ValuesEnum.Profit.toString(),
]

const initialKeys = toDictionaryElems(keysInitial, keysAvailable).filter((value) => value !== undefined)

const initialValues = toDictionaryElems(valuesInitial, valuesAvailable).filter((value) => value !== undefined)
export { initialKeys, initialValues }
