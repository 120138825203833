import React from 'react'
import { ColSize } from 'antd/lib/grid'
import { Col, Select } from 'antd'
import { DictionaryElem } from '../../../pages/statistics/components/utils/utils'

const { Option } = Select

interface Props {
  xs: ColSize | number | string
  value?: string[]
  options?: DictionaryElem[]
  onChange: (value: string[], h) => void
  placeholder?: string
}

export const FormFilterSelect: React.FC<Props> = ({ xs, value, options, onChange, placeholder }) => (
  <Col xs={xs}>
    <Select
      size="middle"
      style={{ width: '100%' }}
      value={value}
      onChange={(e, h) => onChange(e, h)}
      placeholder={placeholder}
    >
      {options?.map((val) => (
        <Option key={val.value} value={val.label}>
          {val.label}
        </Option>
      ))}
    </Select>
  </Col>
)
