import { SavedQueryBean, SavedQueryControllerApi } from './base/api'
import { configuration } from './utils/apiConfiguration'
import { customFetch } from './utils/customFetch'
import { LIST_PAGE_SIZE, ListFilters } from '../components/list'

class SavedQueryApi {
  private api = new SavedQueryControllerApi(configuration, configuration.basePath, customFetch)

  view(id: number) {
    return this.api.savedQuery(id)
  }

  list(filters: ListFilters) {
    return this.api.savedQueries(LIST_PAGE_SIZE, filters.page - 1)
  }

  favoriteList(pageSize: number) {
    return this.api.favoriteSavedQueries(pageSize, 0)
  }

  create(bean: SavedQueryBean) {
    return this.api.create1(bean)
  }

  edit(bean: SavedQueryBean) {
    return this.api.update1(bean, bean.id as number)
  }

  delete(id: number) {
    return this.api.delete1(id)
  }
}

export const savedQueryApi = new SavedQueryApi()
