import React, { useState } from 'react'
import { Card, Col, Descriptions, Row } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import InnerHTML from 'dangerously-set-html-content'
import { TestPageForm } from './TestPageForm'

export const TestPageFormContainer: React.FC = () => {
  const [status, setStatus] = useState('')
  const [body, setBody] = useState('')
  const [banner, setBanner] = useState('')

  return (
    <Row gutter={[8, 8]}>
      <Col xs={24}>
        <Card title="Test Request" bordered={false} headStyle={{ fontWeight: '600', fontSize: '20px', border: 'none' }}>
          <TestPageForm setStatus={setStatus} setBody={setBody} setBanner={setBanner} />
        </Card>
      </Col>
      <Col xs={24}>
        {status && (
          <Card title="Response" headStyle={{ fontWeight: '600', fontSize: '20px', border: 'none' }}>
            <Descriptions layout="vertical" column={1}>
              <Descriptions.Item label="Status">{status}</Descriptions.Item>
              {body && (
                <Descriptions.Item label="Body">
                  <TextArea autoSize value={body} style={{ color: 'black' }} />
                </Descriptions.Item>
              )}
              {banner && (
                <Descriptions.Item label="Banner">
                  <InnerHTML html={banner} />
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card>
        )}
      </Col>
    </Row>
  )
}
