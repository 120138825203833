import React from 'react'
import { Layout, Button, Row, Col, Typography, Form, Input } from 'antd'

import signInImage from '../../images/signin.jpg'
import { accountApi } from '../../api/account'

export const SignIn: React.FC = () => {
  const onFinish = (values: { username: string; password: string }) => {
    accountApi.signin(values.username, values.password)
  }

  return (
    <>
      <Layout className="layout-default layout-signin" style={{ minHeight: '100vh' }}>
        <Layout.Content className="signin">
          <Row gutter={[24, 0]} justify="space-around" align="middle">
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 2 }} md={{ span: 12 }}>
              <Typography.Title className="mb-15">Sign In</Typography.Title>
              <Typography.Title className="font-regular text-muted" level={5}>
                Enter your email and password to sign in
              </Typography.Title>

              <Form onFinish={onFinish} layout="vertical" className="row-col">
                <Form.Item
                  className="username"
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username!',
                    },
                  ]}
                >
                  <Input placeholder="Username" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input placeholder="Password" type="password" />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col className="sign-img" style={{ padding: 12 }} xs={{ span: 24 }} lg={{ span: 12 }} md={{ span: 12 }}>
              <img src={signInImage} alt="" />
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </>
  )
}
