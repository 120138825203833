import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { routes, getTestRequestPath } from '../../routes'
import { TestPageFormContainer } from './form/TestPageFormContainer'

export const TestPageRoot: React.FC = () => (
  <Switch>
    <Route path={`${getTestRequestPath(routes.testRequest)}`} render={() => <TestPageFormContainer />} />
  </Switch>
)
