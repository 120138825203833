import { Button } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { AccountBean } from '../../../api/base/api'
import { useAccountBean } from '../../../utils/useAccountBean'

interface Props {
  path: string
  page: string
  label?: string
}

export const ListEditButton: React.FC<Props> = ({ path, page, label }) => {
  const history = useHistory()
  const account: AccountBean = useAccountBean()

  function isShow(): boolean {
    if (account.role === AccountBean.RoleEnum.Admin) {
      return true
    }
    if (page != null && page === 'DspList' && !account.canEditDsp) {
      return false
    }
    if (page != null && page === 'SspList' && !account.canEditSsp) {
      return false
    }
    return true
  }

  return isShow() ? (
    <Button type="primary" onClick={() => history.push(path)}>
      {label || 'Edit'}
    </Button>
  ) : null
}
