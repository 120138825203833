import { DashboardControllerApi } from './base/api'
import { configuration } from './utils/apiConfiguration'
import { customFetch } from './utils/customFetch'

class DashboardApi {
  private api = new DashboardControllerApi(configuration, configuration.basePath, customFetch)

  dailyValues(startDate: string, endDate: string) {
    return this.api.getDailyValues({ startDate, endDate })
  }

  topLists(startDate: string, endDate: string) {
    return this.api.getTopListsByTotalRevenue({ startDate, endDate })
  }

  dailyStats(startDate: string, endDate: string) {
    return this.api.getDailyStats({ startDate, endDate })
  }

  deviceTypeChart(startDate: string, endDate: string) {
    return this.api.getDeviceTypeChart({ startDate, endDate })
  }

  osChart(startDate: string, endDate: string) {
    return this.api.getOsChart({ startDate, endDate })
  }

  countryChart(startDate: string, endDate: string) {
    return this.api.getCountryChart({ startDate, endDate })
  }

  browserChart(startDate: string, endDate: string) {
    return this.api.getBrowserChart({ startDate, endDate })
  }

  adTypeChart(startDate: string, endDate: string) {
    return this.api.getAdTypeChart({ startDate, endDate })
  }

  bannerIabSize(startDate: string, endDate: string) {
    return this.api.getBannerIabSizeChart({ startDate, endDate })
  }

  videoIabSize(startDate: string, endDate: string) {
    return this.api.getVideoIabSizeChart({ startDate, endDate })
  }
}

export const dashboardApi = new DashboardApi()
