import { Space } from 'antd'
import React from 'react'
import { DspBean, DspBean as DspBeanNamespace, OSTypeBean } from '../../../api/base/api'
import { CountryFilter } from '../../../components/traffic-filter/CountryFilter'
import { ListInputFilter } from '../../../components/traffic-filter/ListInputFilter'
import { DspTrafficTypeFilter } from '../../../components/traffic-filter/DspTrafficTypeFilter'
import { OsAndVersionFilter } from '../../../components/traffic-filter/OsAndVersionFilter'
import { iabCategoryApi } from '../../../api/iabCategory'
import { iabFixedSizeApi } from '../../../api/iabFixedSize'
import { useListFilters, useGetList } from '../../../components/list'
import { OptionsFilter } from '../../../components/traffic-filter/OptionsFilter'
import { browserApi } from '../../../api/browser'

interface Props {
  bean: Partial<DspBean>
  updateBean: (bean: Partial<DspBean>) => void
}

export const DspTrafficFilters: React.FC<Props> = ({ bean, updateBean }) => {
  const iabCategoryFilters = useListFilters('iabCategory').filters
  const iabCategoryList = useGetList(() => iabCategoryApi.list(iabCategoryFilters), iabCategoryFilters).list
  const iabFixedSizeFilters = useListFilters('iabFixedSize').filters
  const iabFixedSizeList = useGetList(() => iabFixedSizeApi.list(iabFixedSizeFilters), iabFixedSizeFilters).list
  const browserFilters = useListFilters('browser').filters
  const browserList = useGetList(() => browserApi.list(browserFilters), browserFilters).list

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <ListInputFilter
        label="Publisher list"
        values={(bean.publisherList as string[]) || []}
        updateValues={(values: string[]) => {
          bean.publisherList = values
          updateBean(bean)
        }}
        defaultMode={DspBeanNamespace.PublisherFilterModeEnum[DspBeanNamespace.PublisherFilterModeEnum.INCLUDEALL]}
        filterMode={DspBean.PublisherFilterModeEnum[bean.publisherFilterMode as DspBean.PublisherFilterModeEnum]}
        updateFilterMode={(filterMode: string) => {
          bean.publisherFilterMode = DspBean.PublisherFilterModeEnum[filterMode]
          updateBean(bean)
        }}
      />

      <br />

      <ListInputFilter
        label="Domain list"
        values={(bean.domainList as string[]) || []}
        updateValues={(values: string[]) => {
          bean.domainList = values
          updateBean(bean)
        }}
        defaultMode={DspBeanNamespace.DomainFilterModeEnum[DspBeanNamespace.DomainFilterModeEnum.INCLUDEALL]}
        filterMode={DspBean.DomainFilterModeEnum[bean.domainFilterMode as DspBean.DomainFilterModeEnum]}
        updateFilterMode={(filterMode: string) => {
          bean.domainFilterMode = DspBean.DomainFilterModeEnum[filterMode]
          updateBean(bean)
        }}
      />

      <br />

      <CountryFilter
        countries={(bean.countryIds as number[]) || []}
        updateCountries={(countryIds) => {
          bean.countryIds = countryIds.map((item) => Number(item))
          updateBean(bean)
        }}
        defaultMode={DspBeanNamespace.CountryFilterModeEnum[DspBeanNamespace.CountryFilterModeEnum.INCLUDEALL]}
        filterMode={DspBean.CountryFilterModeEnum[bean.countryFilterMode as DspBean.CountryFilterModeEnum]}
        updateFilterMode={(filterMode: string) => {
          bean.countryFilterMode = DspBean.CountryFilterModeEnum[filterMode]
          updateBean(bean)
        }}
      />

      <br />

      <ListInputFilter
        label="IP list"
        values={(bean.ipList as string[]) || []}
        updateValues={(values: string[]) => {
          bean.ipList = values
          updateBean(bean)
        }}
        defaultMode={DspBeanNamespace.IpFilterModeEnum[DspBeanNamespace.IpFilterModeEnum.INCLUDEALL]}
        filterMode={DspBeanNamespace.IpFilterModeEnum[bean.ipFilterMode as DspBeanNamespace.IpFilterModeEnum]}
        updateFilterMode={(filterMode: string) => {
          bean.ipFilterMode = DspBeanNamespace.IpFilterModeEnum[filterMode]
          updateBean(bean)
        }}
      />

      <br />

      <ListInputFilter
        label="App ID list"
        values={(bean.appIdList as string[]) || []}
        updateValues={(values: string[]) => {
          bean.appIdList = values
          updateBean(bean)
        }}
        defaultMode={DspBeanNamespace.AppIdFilterModeEnum[DspBeanNamespace.AppIdFilterModeEnum.INCLUDEALL]}
        filterMode={DspBean.AppIdFilterModeEnum[bean.appIdFilterMode as DspBean.AppIdFilterModeEnum]}
        updateFilterMode={(filterMode: string) => {
          bean.appIdFilterMode = DspBean.AppIdFilterModeEnum[filterMode]
          updateBean(bean)
        }}
      />

      <br />

      <OptionsFilter
        values={(bean.browserIds as number[]) || []}
        updateValues={(values: number[]) => {
          bean.browserIds = values
          updateBean(bean)
        }}
        label="Browser list"
        defaultMode={DspBeanNamespace.BrowserFilterModeEnum[DspBeanNamespace.BrowserFilterModeEnum.INCLUDEALL]}
        filterMode={DspBean.BrowserFilterModeEnum[bean.browserFilterMode as DspBeanNamespace.BrowserFilterModeEnum]}
        updateFilterMode={(filterMode: string) => {
          bean.browserFilterMode = DspBeanNamespace.BrowserFilterModeEnum[filterMode]
          updateBean(bean)
        }}
        optionList={browserList}
        formatter={(item) => `${item.name}`}
      />

      <br />

      <OptionsFilter
        values={(bean.iabCategoryIds as number[]) || []}
        updateValues={(values: number[]) => {
          bean.iabCategoryIds = values
          updateBean(bean)
        }}
        label="IAB categories"
        defaultMode={DspBeanNamespace.IabCategoryFilterModeEnum[DspBeanNamespace.IabCategoryFilterModeEnum.INCLUDEALL]}
        filterMode={
          DspBean.IabCategoryFilterModeEnum[bean.iabCategoryFilterMode as DspBeanNamespace.IabCategoryFilterModeEnum]
        }
        updateFilterMode={(filterMode) => {
          bean.iabCategoryFilterMode = DspBeanNamespace.IabCategoryFilterModeEnum[filterMode]
          updateBean(bean)
        }}
        optionList={iabCategoryList}
        formatter={(item) => `${item.name} (${item.code})`}
      />

      <br />

      <OptionsFilter
        values={(bean.iabFixedSizeIds as number[]) || []}
        updateValues={(values: number[]) => {
          bean.iabFixedSizeIds = values
          updateBean(bean)
        }}
        label="IAB fixed sizes"
        defaultMode={
          DspBeanNamespace.IabFixedSizeFilterModeEnum[DspBeanNamespace.IabFixedSizeFilterModeEnum.INCLUDEALL]
        }
        filterMode={
          DspBean.IabFixedSizeFilterModeEnum[bean.iabFixedSizeFilterMode as DspBeanNamespace.IabFixedSizeFilterModeEnum]
        }
        updateFilterMode={(filterMode) => {
          bean.iabFixedSizeFilterMode = DspBeanNamespace.IabFixedSizeFilterModeEnum[filterMode]
          updateBean(bean)
        }}
        optionList={iabFixedSizeList}
        formatter={(item) => `${item.adUnitName} (${item.width}x${item.height})`}
      />

      <br />

      <DspTrafficTypeFilter bean={bean as DspBean} updateBean={updateBean} />

      <br />

      <OsAndVersionFilter
        values={(bean.osTypes as OSTypeBean[]) || []}
        updateValues={(values) => {
          bean.osTypes = values
          updateBean(bean)
        }}
      />

      <br />
    </Space>
  )
}
