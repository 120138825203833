import { ColSize } from 'antd/lib/grid'
import React from 'react'
import { Col, Form, InputNumber } from 'antd'

interface Props {
  xs: ColSize | number | string
  value?: number
  max?: number
  min?: number
  onChange: (value: number) => void
  label?: string
  placeholder?: string
  disabled?: boolean
  precision?: number
}

export const FormNumberInput: React.FC<Props> = ({
  xs,
  value,
  max,
  min,
  onChange,
  label,
  placeholder,
  disabled,
  precision,
}) => (
  <Col xs={xs}>
    <div className="ant-form-vertical">
      <Form.Item label={label}>
        <InputNumber
          max={max !== undefined ? max : Number.MAX_SAFE_INTEGER}
          min={min !== undefined ? min : Number.MIN_SAFE_INTEGER}
          precision={precision}
          controls={false}
          value={value}
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
          disabled={disabled}
        />
      </Form.Item>
    </div>
  </Col>
)
