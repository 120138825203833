import { useEffect, useState } from 'react'
import { authorizationStore } from './authorizationStore'

export function useAccountBean() {
  const [accountBean, setAccountBean] = useState(authorizationStore.account)

  useEffect(() => {
    const cb = () => setAccountBean(authorizationStore.account)
    authorizationStore.subscribe(cb)
    return () => authorizationStore.unsubscribe(cb)
  }, [])

  return accountBean
}
