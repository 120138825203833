import { FilterOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Space } from 'antd'
import { FilterConfirmProps } from 'antd/lib/table/interface'
import * as React from 'react'
import { useEffect, useState } from 'react'

interface Props {
  storedValue?: string
  onSearch: (value: string) => void
  confirm: (param?: FilterConfirmProps) => void
}

export const ListFilter: React.FC<Props> = ({ confirm, storedValue = '', onSearch }) => {
  const [searchString, setSearchString] = useState(storedValue)

  useEffect(() => {
    setSearchString(storedValue)
  }, [storedValue])

  return (
    <div style={{ padding: 8 }}>
      <Space direction="vertical">
        <Input placeholder="Type here..." value={searchString} onChange={(e) => setSearchString(e.target.value)} />

        <Row gutter={10} justify="space-between">
          <Col xs={12}>
            <Button
              style={{ width: '100%' }}
              type="primary"
              onClick={() => {
                onSearch(searchString)
                confirm()
              }}
              icon={<FilterOutlined />}
            >
              Filter
            </Button>
          </Col>
          <Col xs={12}>
            <Button
              style={{ width: '100%' }}
              onClick={() => {
                setSearchString('')
                onSearch('')
                confirm()
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </Space>
    </div>
  )
}
