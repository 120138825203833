import React from 'react'
import { Card, Col, Descriptions, Row } from 'antd'
import { useHistory } from 'react-router-dom'
import {
  List,
  ListButtons,
  ListCreateButton,
  ListDeleteButton,
  ListEditButton,
  useGetList,
  useGetListItem,
  useListFilters,
} from '../../../components/list'
import { savedQueryApi } from '../../../api/savedQuery'
import { getEditPath, getFavoritePath, getListPath, getStatisticsPath, routes } from '../../../routes'
import { ListActionButton } from '../../../components/list/layout/ListActionButton'
import { globalMessageStore } from '../../../utils/globalMessageStore'
import { useSavedQueries } from '../../../utils/useSavedQueries'

interface Props {
  selectedId?: number
}

export const SavedQueryList: React.FC<Props> = ({ selectedId }) => {
  const history = useHistory()

  const { filters, setFilter } = useListFilters('savedQuery')
  const { list, refreshList, pagination } = useGetList(() => savedQueryApi.list(filters), filters)
  const selectedItem = useGetListItem((id) => savedQueryApi.view(id), selectedId)

  const { refreshSavedQueries } = useSavedQueries()

  const refreshListAndSavedQueries = () => {
    refreshList()
    refreshSavedQueries()
  }

  return (
    <Row gutter={[16, 16]}>
      <Col sm={24} md={24} lg={14}>
        <Card
          title="Saved Queries"
          bordered={false}
          className="criclebox tablespace"
          extra={<ListCreateButton lable="Create New" path={getStatisticsPath(routes.statistics)} page="SavedQuery" />}
        >
          <List
            list={list}
            headers={['Id', 'Name', 'Favorite']}
            renderRowCells={(bean) => [bean.id, bean.name, String(bean.favorite)]}
            selectedRow={selectedItem}
            onSelect={(id) => history.push(getListPath(routes.savedQuery, id))}
            page={filters.page}
            setPage={(page) => setFilter('page', page)}
            totalPages={pagination.totalPages ?? 0}
          />
        </Card>
      </Col>

      {selectedItem && (
        <Col sm={24} md={24} lg={10}>
          <Card
            headStyle={{ border: 'none' }}
            title="Query info"
            extra={
              <ListButtons>
                {selectedItem.favorite ? (
                  <ListActionButton
                    label="Remove from favorites"
                    refreshList={() => null}
                    action={() => {
                      selectedItem.favorite = !selectedItem.favorite
                      savedQueryApi.edit(selectedItem).then(() => {
                        globalMessageStore.addSuccess('Favorite property changed')
                        refreshListAndSavedQueries()
                      })
                    }}
                  />
                ) : (
                  <ListActionButton
                    label="add to favorites"
                    refreshList={() => null}
                    action={() => {
                      selectedItem.favorite = !selectedItem.favorite
                      savedQueryApi.edit(selectedItem).then(() => {
                        globalMessageStore.addSuccess('Favorite property changed')
                        refreshListAndSavedQueries()
                      })
                    }}
                  />
                )}
                <ListEditButton
                  label="Edit (to stats)"
                  path={
                    selectedItem.favorite
                      ? getFavoritePath(selectedItem.id)
                      : getEditPath(routes.statistics, selectedItem.id)
                  }
                  page="SavedQuery"
                />
                <ListDeleteButton
                  deleteItem={() => savedQueryApi.delete(selectedItem.id as number)}
                  refreshList={refreshListAndSavedQueries}
                  redirectAfter={() => history.push(getListPath(routes.savedQuery))}
                  page="SavedQuery"
                />
              </ListButtons>
            }
          >
            <Descriptions>
              <Descriptions.Item label="Id" span={3}>
                {selectedItem.id}
              </Descriptions.Item>
              <Descriptions.Item label="Name" span={3}>
                {selectedItem.name}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      )}
    </Row>
  )
}
