import React, { useEffect } from 'react'

import { accountApi } from '../../api/account'

export const SignOut: React.FC = () => {
  useEffect(() => {
    accountApi.signout()
  }, [])

  return null
}
