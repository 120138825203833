import React from 'react'
import { Row, Col } from 'antd'
import { useLocation } from 'react-router-dom'
import { MenuOutlined } from '@ant-design/icons'

interface Props {
  toggleSidebar: () => void
}

export const Header: React.FC<Props> = ({ toggleSidebar }) => {
  const { pathname } = useLocation()

  return (
    <Row gutter={[24, 0]}>
      <Col className="sidebar-toggler">
        <MenuOutlined onClick={toggleSidebar} style={{ cursor: 'pointer' }} />
      </Col>
      <Col>
        <div className="ant-page-header-heading">
          <span className="ant-page-header-heading-title" style={{ textTransform: 'capitalize' }}>
            {pathname.replace('/', '').replaceAll('/', ' / ')}
          </span>
        </div>
      </Col>
    </Row>
  )
}
