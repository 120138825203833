import { ColSize } from 'antd/lib/grid'
import React from 'react'
import { Button, Col, Form, Select } from 'antd'
import { DictionaryElem } from '../../../pages/statistics/components/utils/utils'

const { Option } = Select

interface Props {
  xs: ColSize | number | string
  value?: string[]
  options?: DictionaryElem[]
  onChange: (value: string[]) => void
  label?: string
  placeholder?: string
  disabled?: boolean
}

export const FormMultipleReportSelect: React.FC<Props> = ({
  xs,
  value,
  options,
  onChange,
  label,
  placeholder,
  disabled,
}) => {
  const addAll = () => {
    onChange(options?.map((el) => el.label) as string[])
  }
  return (
    <Col xs={xs}>
      <div className="ant-form-vertical">
        <Form.Item label={label} style={{ marginBottom: 10 }}>
          <Select
            mode="multiple"
            allowClear
            value={value}
            onChange={(e) => onChange(e)}
            placeholder={placeholder}
            disabled={disabled}
          >
            {options?.map((val) => (
              <Option key={val.value} value={val.label}>
                {val.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Button onClick={addAll}>Add all</Button>
      </div>
    </Col>
  )
}
