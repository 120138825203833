import { AccountBean } from '../../api/base/api'

// How make it better?

export const roleArray: string[] = ['Admin', 'Account Manager']

export const roleToStringMap = new Map([
  [AccountBean.RoleEnum.Admin, 'Admin'],
  [AccountBean.RoleEnum.AccountManager, 'Account Manager'],
])

export const stringToRoleMap = new Map([
  ['Admin', AccountBean.RoleEnum.Admin],
  ['Account Manager', AccountBean.RoleEnum.AccountManager],
])
