export const routes = {
  account: '/account',
  ssp: '/ssp',
  dsp: '/dsp',
  dashboard: '/dashboard',
  signIn: '/sign-in',
  signOut: '/sign-out',
  testRequest: '/bidding',
  statistics: '/statistics',
  favorite: '/favorite',
  savedQuery: '/saved-query',
} as const

type Pathname = typeof routes[keyof typeof routes]

export const getListPath = (path: Pathname, id?: number) => `${path}/list${id ? `/${id}` : ''}`
export const getCreatePath = (path: Pathname) => `${path}/create`
export const getEditPath = (path: Pathname, id?: number) => `${path}/edit${id ? `/${id}` : ''}`
export const getTestRequestPath = (path: Pathname) => `${path}`
export const getStatisticsPath = (path: Pathname) => `${path}`
export const getSaveReportPath = (path: Pathname) => `${path}`
export const getFavoritePath = (id?: number) => `${routes.favorite}/${id ? `${id}` : ''}`
