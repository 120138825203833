import { Row, Space } from 'antd'
import React from 'react'
import { DspBean, DspBean as DspBeanNamespace } from '../../../api/base/api'
import { FormTextInput, FormCheckbox } from '../../../components/form'
import { FormBidTypeSelect } from '../../../components/form/inputs/FormBidTypeSelect'
import { FormNumberInput } from '../../../components/form/inputs/FormNumberInput'

interface Props {
  bean: Partial<DspBean>
  updateBean: (bean: Partial<DspBean>) => void
}

export const DspCreateEditForm: React.FC<Props> = ({ bean, updateBean }) => (
  <Space direction="vertical" style={{ width: '100%' }}>
    <Row gutter={24}>
      <FormTextInput
        xs={12}
        value={bean.name}
        onChange={(name) => updateBean({ name })}
        label="Name"
        placeholder="Enter name"
      />
    </Row>

    <Row gutter={24}>
      <FormTextInput
        xs={12}
        value={bean.endpoint}
        onChange={(endpoint) => updateBean({ endpoint })}
        label="Endpoint"
        placeholder="Enter endpoint"
      />
    </Row>

    <Row gutter={24} align="bottom">
      <FormCheckbox xs={4} checked={bean.enable} onChange={(enable) => updateBean({ enable })} label="Enabled" />

      <FormCheckbox
        xs={5}
        checked={bean.vastUnwrapping}
        onChange={(vastUnwrapping) => updateBean({ vastUnwrapping })}
        label="VAST Unwrapping"
      />
    </Row>

    <Row gutter={24}>
      <FormNumberInput
        xs={12}
        value={bean.maxQps}
        min={0}
        onChange={(maxQps) => updateBean({ maxQps })}
        label="Max QPS"
      />
    </Row>

    <Row gutter={24}>
      <FormBidTypeSelect
        xs={12}
        value={bean.bidType}
        onChange={(bidType) => updateBean({ bidType })}
        options={[DspBeanNamespace.BidTypeEnum.NET, DspBeanNamespace.BidTypeEnum.GROSS]}
        label="Bid type"
      />
    </Row>

    <Row gutter={24}>
      <FormNumberInput
        xs={12}
        value={bean.revShare}
        max={1}
        min={0}
        precision={2}
        onChange={(revShare) => updateBean({ revShare })}
        label="Revenue share"
      />
    </Row>

    <Row gutter={24}>
      <FormNumberInput
        xs={12}
        value={bean.minBidFloor}
        min={0}
        precision={2}
        onChange={(minBidFloor) => updateBean({ minBidFloor })}
        label="Min bid floor"
      />
    </Row>

    <Row gutter={24}>
      <FormNumberInput
        xs={12}
        value={bean.maxBidFloor}
        min={0}
        precision={2}
        onChange={(maxBidFloor) => updateBean({ maxBidFloor })}
        label="Max bid floor"
      />
    </Row>

    <Row gutter={24}>
      <FormTextInput
        xs={12}
        value={bean.syncLink}
        onChange={(syncLink) => updateBean({ syncLink })}
        label="Sync pixel link"
        description="Sync pixel link(should contain {USER_SYNC} macro for redirection link)"
        placeholder="Enter sync pixel link"
      />
    </Row>

    <Row gutter={24}>
      <FormCheckbox
        xs={12}
        checked={bean.emptyMacro}
        onChange={(emptyMacro) => updateBean({ emptyMacro })}
        label="Empty macro"
        description="Choose if user ID will be placed at the end of the redirection link"
      />
    </Row>

    <Row gutter={24}>
      <FormTextInput
        xs={12}
        value={bean.syncMacro}
        onChange={(syncMacro) => updateBean({ syncMacro })}
        label="User ID macro"
        description="Dsp's sync pixel macro for user id"
        placeholder="Enter user ID macro"
      />
    </Row>
  </Space>
)
