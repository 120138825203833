import { useEffect, useState } from 'react'
import { ListPagination } from '../../../api/base/api'
import { ListFilters } from './useListFilters'

type ListResponse<Item> = Promise<{ data?: { data?: Item }[]; pagination?: ListPagination }>

export function useGetList<Item>(fetchList: () => ListResponse<Item>, filters: ListFilters) {
  const [list, setList] = useState<Item[]>([])
  const [pagination, setPagination] = useState<ListPagination>({})
  const [isLoading, setIsLoading] = useState(false)

  const refreshList = () => {
    setIsLoading(true)

    fetchList()
      .then((response) => {
        setList(response.data?.map(({ data }) => data as Item) || [])
        setPagination(response.pagination || {})
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(refreshList, [filters])

  return { list, refreshList, pagination, isLoading }
}
