import { useEffect } from 'react'
import moment from 'moment'
import { savedQueryApi } from '../../../../api/savedQuery'
import { QueryState } from '../../StatisticsFormContainer'
import { StatsFilter } from '../../../../api/base/api'
import { initialKeys, initialValues, RequestPeriods } from './utils'
import { useFormData } from '../../../../components/list'

export const initialQueryState = {
  query: {
    keys: initialKeys,
    values: initialValues,
    startDate: moment(),
    endDate: moment(),
    filters: [{ index: 0, type: StatsFilter.TypeEnum.Equal, key: 'Not selected', params: [] }],
    activeRequestPeriods: RequestPeriods.Today,
  },
  name: '',
  favorite: false,
}

export function useQueryState(savedId: number | undefined) {
  const [queryState, updateQueryState] = useFormData<Partial<QueryState>>({})

  useEffect(() => {
    if (savedId) {
      savedQueryApi.view(savedId).then((data) =>
        updateQueryState({
          id: data.data?.id,
          name: data.data?.name,
          favorite: data.data?.favorite,
          query: JSON.parse(data.data?.body as string),
        })
      )
    } else {
      updateQueryState(initialQueryState)
    }
  }, [savedId])

  return { queryState, updateQueryState }
}
