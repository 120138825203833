import { configuration } from './utils/apiConfiguration'
import { customFetch } from './utils/customFetch'
import { AccountBean, AccountControllerApi, SavedQueryBean } from './base/api'
import { authorizationStore } from '../utils/authorizationStore'
import { ListFilters, LIST_PAGE_SIZE } from '../components/list'
import { savedQueryApi } from './savedQuery'
import { savedQueriesStore } from '../utils/SavedQueriesStore'

class AccountApi {
  private api = new AccountControllerApi(configuration, configuration.basePath, customFetch)

  view(id: number) {
    return this.api.account(id)
  }

  list(filters: ListFilters) {
    return this.api.accounts(filters.id, filters.name, filters.email, LIST_PAGE_SIZE, filters.page - 1)
  }

  accountProps() {
    return this.api.accountProps()
  }

  create(bean: AccountBean) {
    return this.api.createAccount(bean)
  }

  edit(bean: AccountBean) {
    return this.api.update3(bean, bean.id as number)
  }

  delete(id: number) {
    return this.api.deleteAccount(id)
  }

  signin(username: string, password: string) {
    const form = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`

    const options = {
      method: 'POST',
      body: form,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    customFetch(`${configuration.basePath}/api/login`, options).then((resp) =>
      resp.text().then((token) => {
        authorizationStore.signIn(token)
        this.accountProps().then((response) => authorizationStore.saveAccountProps(response.data))
        savedQueryApi
          .favoriteList(10)
          .then((response) =>
            savedQueriesStore.saveSavedQueries(
              response.data ? response.data.map((item) => item.data as SavedQueryBean) : null
            )
          )
      })
    )
  }

  signout() {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    return customFetch(`${configuration.basePath}/api/logout`, options).then(authorizationStore.signOut)
  }
}

export const accountApi = new AccountApi()
