import { Col, Form, Button } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'

interface Props {
  path: string
}

export const FormCancelButton: React.FC<Props> = ({ path }) => {
  const history = useHistory()

  return (
    <Col>
      <Form.Item>
        <Button type="default" onClick={() => history.push(path)}>
          Cancel
        </Button>
      </Form.Item>
    </Col>
  )
}
