import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { getCreatePath, getEditPath, getListPath, routes } from '../../routes'
import { DspList } from './list/DspList'
import { DspFormContainer } from './form/DspFormContainer'

export const DspRoot: React.FC = () => (
  <Switch>
    <Route
      path={`${getListPath(routes.dsp)}/:id?`}
      render={(rp) => <DspList selectedId={+(rp.match.params.id ?? '')} />}
    />
    <Route path={`${getCreatePath(routes.dsp)}`} component={DspFormContainer} />
    <Route path={`${getEditPath(routes.dsp)}/:id`} render={(rp) => <DspFormContainer id={+rp.match.params.id} />} />
    <Redirect to={getListPath(routes.dsp)} />
  </Switch>
)
