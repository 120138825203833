import { Col, Row, Select, Space, Typography } from 'antd'
import React from 'react'
import { filterModeArray } from './utils/FilterModeEnum'

const { Text } = Typography
const { Option } = Select

interface Props {
  values: number[]
  updateValues: (Values: number[]) => void
  label: string
  defaultMode: string
  filterMode: string
  updateFilterMode: (filterMode: string) => void
  optionList: any[]
  formatter: (item: any) => string
}

export const OptionsFilter: React.FC<Props> = ({
  values,
  updateValues,
  label,
  defaultMode,
  filterMode,
  updateFilterMode,
  optionList,
  formatter,
}) => (
  <Col xs={12}>
    <Space direction="vertical" style={{ width: '100%' }}>
      <Text>{label}</Text>

      <Row justify="end">
        <Col xs={7}>
          <Select
            size="large"
            style={{ width: '100%' }}
            placeholder="Please select"
            defaultValue={defaultMode}
            value={filterMode}
            onChange={updateFilterMode}
          >
            {filterModeArray?.map((item) => (
              <Option key={item.key} value={item.key}>
                {item.value}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Select
        disabled={filterMode === defaultMode || filterMode == null}
        size="large"
        mode="multiple"
        allowClear
        style={{ width: '100%' }}
        placeholder="Please select"
        value={values}
        onChange={updateValues}
        filterOption={(inp, opt) => {
          if (opt !== undefined) {
            if (opt.children != null) return opt.children.toString().toLowerCase().includes(inp.toLowerCase())
          }
          return false
        }}
      >
        {optionList?.map((item) => (
          <Option key={item.id} value={item.id}>
            {formatter(item)}
          </Option>
        ))}
      </Select>
    </Space>
  </Col>
)
