import { ReportControllerApi, StatRequestBean, StatResponseBeanRawStatRow } from './base/api'
import { configuration } from './utils/apiConfiguration'
import { customFetch } from './utils/customFetch'

class ReportApi {
  private api = new ReportControllerApi(configuration, configuration.basePath, customFetch)

  statistics(bean: StatRequestBean): Promise<StatResponseBeanRawStatRow> {
    return this.api.statisticsRequests(bean)
  }
}

export const reportApi = new ReportApi()
