import { BrowserControllerApi } from './base/api'
import { configuration } from './utils/apiConfiguration'
import { customFetch } from './utils/customFetch'
import { BROWSER_LIST_SIZE, ListFilters } from '../components/list'

class BrowserApi {
  api = new BrowserControllerApi(configuration, configuration.basePath, customFetch)

  list(filters: ListFilters) {
    return this.api.browserList(BROWSER_LIST_SIZE, filters.page - 1)
  }
}

export const browserApi = new BrowserApi()
