import { Col, Button } from 'antd'
import React from 'react'

interface Props {
  onClick: () => void
  text?: string
}

export const FormSubmitButton: React.FC<Props> = ({ onClick, text }) => (
  <Col>
    <Button type="primary" htmlType="submit" onClick={onClick}>
      {text == null ? 'Submit' : text}
    </Button>
  </Col>
)
