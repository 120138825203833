import { BuildInfoControllerApi } from './base/api'
import { configuration } from './utils/apiConfiguration'
import { customFetch } from './utils/customFetch'
import { bidderConfiguration } from './utils/bidderConfiguration'

class BuildInfoApi {
  uiApi = new BuildInfoControllerApi(configuration, configuration.basePath, customFetch)

  uiBuildInfo() {
    return this.uiApi.buildInfo()
  }

  exchangeBuildInfo() {
    return fetch(`${bidderConfiguration.basePath}/build-info`, { method: 'GET' })
  }
}

export const buildInfoApi = new BuildInfoApi()
